import axios from "axios";
import STATUS_CODE from "../constants/statusCodes";
import { sendRefreshToken } from "./sendRefreshToken";
import StorageService from "./StorageService";
import { AUTH, LANGUAGE, SKIP_AUTH, UNAUTORIZED_STRING, ENGLISH } from "../constants/headers";
import toastr from "../components/toastr";
import { USER, SESSION } from "../constants/storageConstants";
import { HTTPS_PATTERN } from "../constants/patterns";
import * as _ from "lodash";

function redirectToLogout() {
  let location = window.location;
  location.href = location.origin + "/logout";
  StorageService.deleteValueByKey(USER);
  StorageService.deleteValueByKey(SESSION);
}

const interceptorsResponse = [
  response => response.data,
  error => {
    let dataObject = {},
      statusCode;
    const { response } = error;
    const { UNAUTHORIZED } = STATUS_CODE;
    const refreshToken = StorageService.getValueByKey(SESSION)?.refreshToken;
    if (response) {
      dataObject = response.data;
    }

    statusCode = dataObject.code || dataObject.statusCode || (response && response.status);
    const isUnauth = statusCode === UNAUTHORIZED || statusCode === UNAUTORIZED_STRING;

    if (isUnauth && refreshToken) {
      let isRefreshed = false;
      return sendRefreshToken({ refreshToken })
        .then(({ data }) => {
          const session = data.data;
          error.config.headers[AUTH] = `Bearer ${session?.accessToken}`;
          isRefreshed = true;
          // retry request
          return axios.request(error.config).then(res => res.data);
        })
        .catch(err => {
          if (!isRefreshed) {
            redirectToLogout();
          }
          throw err;
        });
    }
    if (isUnauth && !refreshToken) {
      redirectToLogout();
    }
    if (!response?.config?.disableToast) {
      toastr.error(_.get(dataObject, "errors[0].message", "Error"));
    }
    return Promise.reject(dataObject);
  },
];

const interceptorsRequest = [
  request => {
    const pattern = HTTPS_PATTERN;
    const skipAuthorization = request.headers[SKIP_AUTH] || false;
    if (!(pattern.test(request.url) || skipAuthorization)) {
      const accessToken = StorageService.getValueByKey(SESSION)?.accessToken;
      request.headers = {
        [AUTH]: accessToken ? `Bearer ${accessToken}` : "",
        [LANGUAGE]: ENGLISH,
      };
    }

    delete request.headers[SKIP_AUTH];
    return request;
  },
  err => Promise.reject(err),
];

class Http {
  constructor() {
    this.instance = Http.createInstance({
      baseURL: `${Http.api.common}/${Http.versions.v1}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });

    this.instance.interceptors.request.use(...interceptorsRequest);
    this.instance.interceptors.response.use(...interceptorsResponse);
  }

  static createInstance() {
    return axios.create.apply(axios, arguments);
  }

  get() {
    return this.instance.get.apply(this.instance, arguments);
  }

  patch() {
    return this.instance.patch.apply(this.instance, arguments);
  }

  put() {
    return this.instance.put.apply(this.instance, arguments);
  }

  post() {
    return this.instance.post.apply(this.instance, arguments);
  }

  delete() {
    return this.instance.delete.apply(this.instance, arguments);
  }
}

Http.api = {
  common: process.env.REACT_APP_BASE_URL,
};

Http.versions = {
  v1: process.env.REACT_APP_API_VERSION,
};

export default Http;

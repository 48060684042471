import React from "react";
import { Badge } from "reactstrap";
import Icon from "../../../components/Common/Icon";
import queryParser from "../../../helpers/queryParser";
import { ORDER_DESC, ORDER_TYPE_KEY } from "../../../constants/paginationConstants";
import getFirstLetter from "../../../helpers/getFirstLetter";
import formatDate from "../../../helpers/formatDate";
import StarRatings from "react-star-ratings";
import getNameValue from "../../../helpers/getNameValue";
import { STARS_COUNT } from "../../../constants/defaultConstants";
import { STAR_RATED_COLOR } from '../../../constants/commonConstants';

const STATUSES = ["Active", "Block", "Waiting", "Rejected"];
const STATUS_BADGES = [
  { label: "Active", color: "success" },
  { label: "Blocked", color: "danger" },
  { label: "Waiting", color: "warning" },
  { label: "Rejected", color: "danger" },
];

export default function trainerTableStructure(location) {
  const query = queryParser(location.search);

  return [
    {
      dataField: "avatar",
      text: "",
      headerFormatter: () => (
        <>
          Trainers name <Icon icon={query[ORDER_TYPE_KEY] === ORDER_DESC ? "sortDESC" : "sortASC"} />
        </>
      ),
      sort: true,
      formatter: (cellContent, user) => {
        return (
          <section className="d-flex flex-row align-items-center">
            {!cellContent ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">{getFirstLetter(user.firstName)}</span>
              </div>
            ) : (
              <div>
                <img className="rounded-circle avatar-xs" src={cellContent.compactPath} alt="" />
              </div>
            )}
            <span className="tutee-list--list__name">{getNameValue(user.firstName, user.lastName)}</span>
          </section>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      text: "",
      dataField: "dateOfJoining",
      formatter: cellContent => <>{formatDate(cellContent)}</>,
      headerFormatter: () => (
        <>
          Date of joining <Icon icon={query[ORDER_TYPE_KEY] === ORDER_DESC ? "sortDESC" : "sortASC"} />
        </>
      ),
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      formatter: cellContent => {
        const indexOfStatus = STATUSES.indexOf(cellContent);

        return (
          <Badge className={`badge-soft-${STATUS_BADGES[indexOfStatus].color}  tutee-list--list__badge`}>
            {STATUS_BADGES[indexOfStatus].label}
          </Badge>
        );
      },
    },
    {
      dataField: "availableOffers",
      text: "Available offers",
    },
    {
      dataField: "sessions",
      text: "Sessions",
    },
    {
      dataField: "rating",
      text: "Average rating",
      formatter: cellContent => {
        return (
          <StarRatings
            rating={cellContent}
            starRatedColor={STAR_RATED_COLOR}
            numberOfStars={STARS_COUNT}
            name="rating"
            starDimension="16px"
            starSpacing="1px"
          />
        );
      },
    },
    {
      dataField: "subscriptionStatus",
      text: "Subscription",
    },
    {
      dataField: "location",
      text: "Country",
      formatter: cellContent => <>{cellContent || '-'}</>,
    },
  ];
}

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory, useLocation } from 'react-router-dom';
import calculateCurrentPage from '../../helpers/calculateCurrentPage';
import Placeholder from './placeholder';
import overlayFactory from 'react-bootstrap-table2-overlay';
import queryParser from '../../helpers/queryParser';
import { spinnerStyles } from './spinnerStyles';
import { DEFAULT_REDIRECT, BOARDS_REDIRECT, BOARDS_COLOR_STYLE, DEFAULT_COLOR_STYLE, TRAINER_BOARDS_COLOR_STYLE } from '../../constants/commonConstants';

const trainerBoardClasses = [
  { position: 1, className: 'first-trainer-board' },
  { position: 2, className: 'second-trainer-board' },
  { position: 3, className: 'third-trainer-board' },
];

export default function Table({
  dataStructure,
  data,
  FiltersComponent,
  handleTableChange,
  totalCount,
  limit,
  offset,
  isRowClick = true,
  loading,
  rowClickPath,
  searchField = '',
  withLocation = true,
  customRow = '',
  customRowElement = '',
  customRowElementId = '',
  isInRowId = true,
  boardRedirectType = DEFAULT_REDIRECT,
  boardStyleType = DEFAULT_COLOR_STYLE,
}) {
  const location = useLocation();
  const history = useHistory();

  const query = queryParser(location.search);

  const pagination = useMemo(
    () =>
      paginationFactory({
        sizePerPage: limit,
        totalSize: totalCount,
        custom: true,
        page: calculateCurrentPage(offset, limit),
      }),
    [limit, totalCount, offset]
  );

  const rowEvents = {
    onClick: (event, row) => {
      var path = '';
      switch (boardRedirectType) {
        case DEFAULT_REDIRECT: {
          const firstPathPart = (withLocation ? location.pathname : '') + (rowClickPath ? rowClickPath : customRow ? customRow : '');
          const secondPathPart = (isInRowId ? '/' : '') + (customRowElement ? row[customRowElement].id : customRowElementId ? row[customRowElementId] : row.id);
          path = firstPathPart + secondPathPart;
          break;
        }
        case BOARDS_REDIRECT: {
          path = `/boards/${row.boardLocation}?BoardId=${row.boardId}&BoardType=${row.boardType}`;
          break;
        }
        default:
          break;
      }
      history.push(path);
    },
  };

  const rowClasses = (row, rowIndex) => {
    var classes = "";
    if (boardStyleType === TRAINER_BOARDS_COLOR_STYLE) {
      classes += trainerBoardClasses.find(({ position }) => position === row?.place)?.className || '';
    }
    return classes;
  };

  return (
    <Row className="m-0">
      <Col lg="12" className="p-0">
        <Card className="tutee-list">
          <CardBody>
            <PaginationProvider pagination={pagination}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={data || []}
                  columns={dataStructure(location)}
                  bootstrap4
                  search={{
                    defaultSearch: query[searchField] || '',
                  }}
                >
                  {toolkitProps => (
                    <>
                      <FiltersComponent toolkitProps={toolkitProps} />
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              responsive
                              remote
                              rowEvents={isRowClick && rowEvents}
                              bordered={false}
                              striped={false}
                              loading={loading}
                              overlay={overlayFactory({
                                spinner: true,
                                styles: spinnerStyles,
                              })}
                              noDataIndication={() => <Placeholder />}
                              classes={`table align-middle table-nowrap table-hover ${!isRowClick ? 'no-row-click-table' : ''}${boardStyleType === BOARDS_COLOR_STYLE && offset <= 3 && totalCount > 0 ? 'board-with-place' : ''}`}
                              headerWrapperClasses={'table-light'}
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChange}
                              {...paginationTableProps}
                              rowClasses={rowClasses}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-4">
                        <Col className="pagination pagination-rounded justify-content-center mb-0 inner-custom-pagination">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  getData: PropTypes.func,
  dataStructure: PropTypes.any,
  isDataLoading: PropTypes.bool,
};

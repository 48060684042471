import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Switch, BrowserRouter as Router, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// Import Routes all
import { userRoutes, authRoutes } from './routes/allRoutes';

import { LINKS } from './components/VerticalLayout/sidebarConfig';
import { UK, AUSTRALIA} from './constants/commonConstants';

// Import all middleware
import Authmiddleware from './routes/middleware/Authmiddleware';

// layouts Format
import VerticalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './assets/scss/theme.scss';

const App = ({ match }) => {
  const schema = useMemo(() => [
    {
      label: "Tutee management",
      linkTo: LINKS.LINK_TO_TUTEE,
    },
    {
      label: "Trainers management",
      linkTo: LINKS.LINK_TO_TRAINER,
    },
    {
      label: "Reports management",
      linkTo: "/reports-management",
    },
    {
      label: "Payments management",
      linkTo: LINKS.LINK_TO_PAYMENTS,
    },
    {
      label: "Settings",
      linkTo: LINKS.LINK_TO_SETTINGS,
    },
    {
      label: "Notifications",
      linkTo: LINKS.LINK_TO_NOTIFICATIONS,
    },
    {
      label: "Boards",
      linkTo: `${LINKS.LINK_TO_BOARDS}`,
      children: [
        {
          label: UK,
          linkTo: `${LINKS.LINK_TO_BOARDS}/${UK}`,
        },
        {
          label: AUSTRALIA,
          linkTo: `${LINKS.LINK_TO_BOARDS}/${AUSTRALIA}`,
        },
      ],
    },
  ], []);

  console.log(match);
  return (
    <>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          <VerticalLayout schema={schema}>
            <Switch>
              {userRoutes.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={VerticalLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact={route.exact}
                />
              ))}
              <Redirect from="*" to="/tutee-management" />
            </Switch>
          </VerticalLayout>

          <Redirect from="*" to="/login" />
        </Switch>
      </Router>
    </>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(withRouter(App));

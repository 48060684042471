import React, { useState, useRef } from 'react';
import { Row, Col, Card, CardBody, Button, Modal } from 'reactstrap';
import profile1 from '../../../../../assets/images/profile-img.png';
import getFirstLetter from '../../../../../helpers/getFirstLetter';
import TrainerManagementService from '../../../../../services/trainerManagement';
import ModalHeader from '../../../../../components/Modal/modalHeader';
import TwoModalButtons from '../../../../../components/Modal/twoModalButtons';
import SocialIcon from './socialIcon';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useHistory } from 'react-router-dom';
import { MD } from '../../../../../constants/commonConstants'

export default function ProfileCard({ trainer, updateTrainer }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showConfirmModalApprove, setShowConfirmModalApprove] = useState(false);
  const [showConfirmModalReject, setShowConfirmModalReject] = useState(false);
  const [showConfirmModalDelete, setShowConfirmModalDelete] = useState(false);

  const { isBlocked, isDeleted } = trainer;

  const formRef = useRef();

  const history = useHistory();

  async function blockUnblockTrainer() {
    try {
      await TrainerManagementService.blockUnblockTrainer(trainer.id);
      await updateTrainer();
    } finally {
      setShowConfirmModal(false);
    }
  }

  async function handleApproveTrainer() {
    try {
      await TrainerManagementService.approveTrainer(trainer.id);
      await updateTrainer();
    } finally {
      setShowConfirmModalApprove(false);
    }
  }

  async function handleRejectTrainer(values) {
    try {
      const value = {
        messageText: values.rejectReason,
      };

      await TrainerManagementService.rejectTrainer(trainer.id, value);
      await updateTrainer();
    } finally {
      setShowConfirmModalReject(false);
    }
  }

  async function handleDeleteTrainer() {
    try {
      await TrainerManagementService.deleteTrainer(trainer.id);
    } finally {
      setShowConfirmModalDelete(false);
      history.goBack();
    }
  }

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs="6" />
          <Col xs="5" className="align-self-end">
            <img src={profile1} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col sm="6">
            <div className="tutee-details--details__avatar">
              {trainer.avatar?.compactPath ? (
                <div className="avatar-lg">
                  <img src={trainer.avatar?.compactPath} alt="" className="img-thumbnail rounded-circle" />
                </div>
              ) : (
                <span className="avatar-title rounded-circle font-size-20">{getFirstLetter(trainer.firstName)}</span>
              )}
            </div>
            <h5 className="font-size-15 text-truncate">
              {trainer.firstName} {trainer.lastName}
            </h5>
            <div className="mt-4">
              {!trainer.isApproved && (
                <>
                  <Button
                    onClick={() => setShowConfirmModalReject(true)}
                    className="btn btn-danger waves-effect waves-light tutee-details--details__button btn-sm font-size-13 me-4"
                    disabled={trainer.isRejected}
                  >
                    Reject
                  </Button>
                  <Button
                    onClick={() => setShowConfirmModalApprove(true)}
                    className="btn btn-success waves-effect waves-light tutee-details--details__button btn-sm font-size-13"
                    disabled={trainer.isRejected}
                  >
                    Approve
                  </Button>
                </>
              )}
            </div>
          </Col>
          <Col lg="6">
            <div className="d-flex flex-column justify-content-between h-100">
              <section className="d-flex flex-row justify-content-between w-60 mt-3 ms-auto">
                {trainer.facebookLink && trainer.facebookLink.includes('http') && (
                  <a className="avatar-xs" target="_blank" rel="noopener noreferrer" href={trainer.facebookLink}>
                    <SocialIcon icon="mdi mdi-facebook" background="bg-primary" />
                  </a>
                )}

                {trainer.youtubeLink && trainer.youtubeLink.includes('http') && (
                  <a className="avatar-xs" target="_blank" rel="noopener noreferrer" href={trainer.youtubeLink}>
                    <SocialIcon icon="mdi mdi-youtube" background="bg-danger" />
                  </a>
                )}

                {trainer.instagramLink && trainer.instagramLink.includes('http') && (
                  <a className="avatar-xs" target="_blank" rel="noopener noreferrer" href={trainer.instagramLink}>
                    <SocialIcon icon="mdi mdi-instagram" background="bg-pink" />
                  </a>
                )}
              </section>
              <div className="d-flex align-items-end justify-content-lg-end">
                {trainer.isApproved && <Button
                  onClick={() => setShowConfirmModal(true)}
                  className={`btn btn-${!isBlocked ? 'danger' : 'success'} waves-effect waves-light tutee-details--details__button-${!isBlocked ? 'block' : 'unblock'} btn-sm font-size-13`}
                  color={!isBlocked ? '#f46a6a' : '#34c38f'}
                >
                  {!isBlocked ? 'Block' : 'Unblock'}
                </Button>}
                <Button
                  color="danger"
                  size="sm"
                  className="tutee-details--details__button--big font-size-13"
                  onClick={() => setShowConfirmModalDelete(true)}
                  disabled={isDeleted}
                >
                  Delete trainer
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </CardBody>
      <Modal isOpen={showConfirmModal} centered size={MD} toggle={() => setShowConfirmModal(false)}>
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader
            text={isBlocked ? 'Unblock Trainer' : 'Block Trainer'}
            handleClose={() => setShowConfirmModal(false)}
          />

          <span className="confirm-modal__modal-text">
            {isBlocked ? 'Trainer will be unblocked' : 'Are you sure you want to block this Trainer?'}
          </span>

          <TwoModalButtons
            firstText={isBlocked ? 'Cancel' : 'No, cancel'}
            secondText={isBlocked ? 'Confirm' : 'Block'}
            firstAction={() => setShowConfirmModal(false)}
            secondAction={blockUnblockTrainer}
            firstColor={isBlocked ? 'danger' : 'primary'}
            secondColor={isBlocked ? 'primary' : 'danger'}
          />
        </section>
      </Modal>
      <Modal
        isOpen={showConfirmModalApprove}
        centered
        size={MD}
        toggle={() => setShowConfirmModalApprove(false)}
      >
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader text="Approve Trainer" handleClose={() => setShowConfirmModalApprove(false)} />

          <span className="confirm-modal__modal-text">Are you sure you want to approve this Trainer?</span>

          <TwoModalButtons
            firstText="No"
            secondText="Yes"
            firstAction={() => setShowConfirmModalApprove(false)}
            secondAction={handleApproveTrainer}
            firstColor="danger"
            secondColor="success"
          />
        </section>
      </Modal>
      <Modal isOpen={showConfirmModalReject} centered size={MD} toggle={() => setShowConfirmModalReject(false)}>
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader text="Reject Trainer" handleClose={() => setShowConfirmModalReject(false)} />

          <AvForm
            ref={formRef}
            className="form-horizontal"
            onValidSubmit={(event, values) => { handleRejectTrainer(values) }}
            errorMessage="Reason for rejection is required"
          >
            <AvField
              name="rejectReason"
              label="Reason to reject"
              type="textarea"
              required
              placeholder="Enter reason for rejection"
              maxLength={500}
              className="reject-input-margin"
            />

            <TwoModalButtons
              firstText="Send reject"
              secondText="No, cancel"
              firstAction={() => { }}
              secondAction={() => { setShowConfirmModalReject(false) }}
              firstColor="danger"
              secondColor="primary"
            />
          </AvForm>

        </section>
      </Modal>
      <Modal isOpen={showConfirmModalDelete} centered size={MD} toggle={() => setShowConfirmModalDelete(false)}>
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader text="Account will be deleted" handleClose={() => setShowConfirmModalDelete(false)} />

          <span className="confirm-modal__modal-text">Are you sure you want to delete trainer account?</span>

          <TwoModalButtons
            firstText="Yes, delete"
            secondText="No, cancel"
            firstAction={handleDeleteTrainer}
            secondAction={() => setShowConfirmModalDelete(false)}
            firstColor="danger"
            secondColor="primary"
          />
        </section>
      </Modal>
    </Card>
  );
}

import React from "react";
import Reporter from "./reporter";
import Reported from "./reported";

export default function ReportInvolved({ report }) {
  return (
    <section className="report-card--report-involved d-flex flex-row justify-content-between">
      <Reporter report={report} />
      <Reported reported={report.reported} />
    </section>
  );
}

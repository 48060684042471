import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS } from "./actionTypes";

export const loginUser = (values, history, setIsLoading) => {
  return {
    type: LOGIN_USER,
    payload: { values, history, setIsLoading },
  };
};

export const loginSuccess = values => {
  return {
    type: LOGIN_SUCCESS,
    payload: values,
  };
};

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

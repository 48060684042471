import React, { useRef, useState} from "react";
import { Card } from "reactstrap";
import {AvCheckbox, AvCheckboxGroup, AvForm} from "availity-reactstrap-validation";

import toastr from "../../../../../components/toastr";
import TrainerManagementService from "../../../../../services/trainerManagement";

export default function Specializations({ specializations, specializationsList, id }) {
    const [initSpecialization, setSpecializationList] = useState(specializations)
    const formRef = useRef();

    const handleValidSubmit = async values => {
        try {
            const { data }  = await TrainerManagementService.patchTrainerSpecialization(id,  values);

            toastr.success("Success updated");
            setSpecializationList(data)
        } catch (e) {
        }
    }

    const model = {
        specializationIds: initSpecialization
    }

  return (
    <Card className="specializations-card">
      <span className="specializations-card__header">Specialization</span>
        <AvForm
            ref={formRef}
            onValidSubmit={(event, values) => {
                handleValidSubmit(values);
            }}
            model={model}
        >
            <AvCheckboxGroup
                name="specializationIds"
                className="form-cols mt-2"
                errorMessage="Choose one or more specialization"
                required
            >
                {specializationsList.map(({id, type}, index) => (
                    <AvCheckbox value={id} label={type} key={index} />
                ))}
            </AvCheckboxGroup>

            <button
                className="btn btn-primary btn-block waves-effect waves-light mt-4"
                type="submit"
            >
                Save
            </button>
        </AvForm>
    </Card>
  );
}

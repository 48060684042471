import React from "react";
import { Button } from "reactstrap";

export default function TwoModalButtons({
  firstText,
  secondText,
  firstAction,
  secondAction,
  firstColor = "primary",
  secondColor = "danger",
  firstDisabled = false,
  secondDisabled = false,
}) {
  return (
    <section className="modal--buttons">
      <Button disabled={firstDisabled} color={firstColor} onClick={firstAction} className="modal--buttons__button" type="submit">
        {firstText}
      </Button>
      <Button disabled={secondDisabled} color={secondColor} onClick={secondAction} className="modal--buttons__button">
        {secondText}
      </Button>
    </section>
  );
}

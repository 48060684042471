import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import stripeIcons from '../../constants/stripeConstants';

const STARS_COUNT = 4;

const BankCardStars = () => (
  <p>
    {Array.from({ length: STARS_COUNT }).map((item, index) => (
      <i className="fas fa-star-of-life m-1" key={index} />
    ))}
  </p>
);

const getLastTwoNumbersOfCard = number => number.toString().substr(-2);

const BankCard = ({ card }) => (
  <Card>
    <CardBody>
      <CardTitle className="mb-4">Card details</CardTitle>

      <div className="card bg-primary text-white visa-card mb-0 bank-card">
        <CardBody>
          <div className="">
            <div className="float-end">{stripeIcons[card.brand]}</div>
          </div>

          <section className="mt-5 pt-5 d-flex flex-row justify-content-between w-100">
            <BankCardStars />
            <BankCardStars />
            <BankCardStars />
            <span className="bank-card__numbers">{card.cardNumber}</span>
          </section>

          <div className="mt-5">
            <h5 className="text-white float-end mb-0">
              {card.expMonth}/{getLastTwoNumbersOfCard(card.expYear)}
            </h5>
          </div>
        </CardBody>
      </div>
    </CardBody>
  </Card>
);

export default BankCard;

import React from 'react'
import { Pie } from 'react-chartjs-2'

export default function PieChart({ labels, data, backgroundColors }) {

    var options = {
        tooltips: {
            callbacks: {
                label: (ttItem, data) => (`${data.labels[ttItem.index]}: ${data.datasets[ttItem.datasetIndex].data[ttItem.index]}%`)
            }
        },
    };

    return <Pie
        type={'pie'}
        data={{
            labels: labels,
            datasets: [
                {
                    data: data,
                    backgroundColor: backgroundColors,
                }
            ]
        }}
        legend={{
            display: false
        }}
        options={options}
    />
}
import queryParser from '../../../helpers/queryParser';
import getFirstLetter from "../../../helpers/getFirstLetter";
import getNameValue from "../../../helpers/getNameValue";
import StarRatings from "react-star-ratings";
import { STAR_RATED_COLOR } from '../../../constants/commonConstants';
import { STARS_COUNT } from "../../../constants/defaultConstants";

export default function NotFinishTableStructure(location) {
  const query = queryParser(location.search);

  return [
    {
      dataField: 'place',
      text: 'Position'
    },
    {
      dataField: "user.avatar",
      text: "",
      headerFormatter: () => (
        <>
          Trainers name
        </>
      ),
      formatter: (cellContent, boardRow) => {
        return (
          <section className="d-flex flex-row align-items-center">
            {!cellContent ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">{getFirstLetter(boardRow.user.firstName)}</span>
              </div>
            ) : (
              <div>
                <img className="rounded-circle avatar-xs" src={cellContent.compactPath} alt="" />
              </div>
            )}
            <span className="tutee-list--list__name">{getNameValue(boardRow.user.firstName, boardRow.user.lastName)}</span>
          </section>
        );
      },
    },
    {
      dataField: 'user.rating',
      text: "Average rating",
      formatter: cellContent => {
        return (
          <StarRatings
            rating={cellContent}
            starRatedColor={STAR_RATED_COLOR}
            numberOfStars={STARS_COUNT}
            name="rating"
            starDimension="16px"
            starSpacing="1px"
          />
        );
      },
    },
    {
      dataField: 'sessionsCount',
      text: 'Conducted sessions',
    },
    {
      dataField: 'points',
      text: 'Activity percentage',
      formatter: cellContent => {
        return (
          <span>
            {cellContent}%
          </span>
        );
      },
    },
  ];
}

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { connect, useSelector } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';

// Import menuDropdown
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';

import bigLogo from '../../assets/images/icons/big-logo-SS.svg';

//i18n
import { withTranslation } from 'react-i18next';

// Redux Store
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType, logoutUser } from '../../store/actions';
import { Modal } from 'reactstrap';
import TwoModalButtons from '../../components/Modal/twoModalButtons';
import ModalHeader from '../../components/Modal/modalHeader';

const Header = ({ logoutUser }) => {
  const [isVisibleLogoutModal, setIsvisibleLogoutModal] = useState(false);

  const { user } = useSelector(store => store.Login);

  const history = useHistory();

  function handleLogout() {
    logoutUser(history);
  }

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-light">
                <span className="logo-lg">
                  <img src={bigLogo} alt="" className="navbar-brand-box__logo" />
                </span>
              </Link>
            </div>
          </div>

          <section className="d-flex flex-row align-items-center">
            <section className="navbar-header--admin-name" onClick={() => history.push('/settings')}>
              <span className="navbar-header--admin-name__first-name">{user?.firstName}</span>
              <span className="navbar-header--admin-name__last-name">{user?.lastName}</span>
            </section>

            <NotificationDropdown />

            <i className="bx bx-log-out logout-icon cursor-pointer" onClick={() => setIsvisibleLogoutModal(true)} />
          </section>
        </div>
      </header>
      <Modal isOpen={isVisibleLogoutModal} centered={true} size="md" toggle={() => setIsvisibleLogoutModal(false)}>
        <section className="logout-modal">
          <ModalHeader text="Log out" handleClose={() => setIsvisibleLogoutModal(false)} />

          <span className="modal__description">Are you sure you want to log out?</span>

          <TwoModalButtons
            firstText="No, cancel"
            secondText="Log out"
            firstAction={() => setIsvisibleLogoutModal(false)}
            secondAction={handleLogout}
          />
        </section>
      </Modal>
    </>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  logoutUser,
})(withTranslation()(Header));

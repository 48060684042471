import React, { useState, useMemo } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import Comment from './comment';
import StarRating from '../../../../components/Common/StarRating';
import Placeholder from './commentsPlaceholder';

const FIRST_TAB = 'Reviews';
const SECOND_TAB = 'Reports';

export default function SessionTabs({ session }) {
  const [activeTab, setActiveTab] = useState(FIRST_TAB);

  function toggleCustom(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const reportsData = useMemo(
    () =>
      session.reports.map(report => ({
        title: `${report?.reporter?.role} report`,
        description: report.reportText,
        date: report.reportTimestamp,
      })),
    [session]
  );

  const reviewsData = useMemo(
    () =>
      session.reviews.map(review => ({
        title: (
          <section className="d-flex flex-row align-items-center">
            <span className="me-2 mt-2">{review.creator?.role} comment</span>
            <StarRating rating={review.rating} className="" />
          </section>
        ),
        description: review.text,
        date: review.reportTimestamp,
      })),
    [session]
  );

  return (
    <>
      <Nav tabs className="nav-tabs-custom nav-justified mt-4">
        <NavItem className="w-50 font-size-15 font-weight-semibold">
          <NavLink
            className={classnames({
              active: activeTab === FIRST_TAB,
            })}
            onClick={() => {
              toggleCustom(FIRST_TAB);
            }}
          >
            <span className="">Reviews</span>
          </NavLink>
        </NavItem>
        <NavItem className="w-50 font-size-15 font-weight-semibold">
          <NavLink
            className={classnames({
              active: activeTab === SECOND_TAB,
            })}
            onClick={() => {
              toggleCustom(SECOND_TAB);
            }}
          >
            <span className="">Reports</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={FIRST_TAB}>
          {session.reviews.length ? (
            <>
              {reviewsData.map((review, index) => (
                <Comment comment={review} key={index} />
              ))}
              {/* <section className="session-comment__second-section">
                                <Comment comment={reviewsTrainerData} />
                            </section> */}
            </>
          ) : (
            <Placeholder title="NO REVIEWS" />
          )}
        </TabPane>
        <TabPane tabId={SECOND_TAB}>
          {session.reports.length ? (
            <>
              {reportsData.map((report, index) => (
                <Comment comment={report} key={index} />
              ))}
              {/* <section className="session-comment__second-section">
                                <Comment comment={reportsTrainerData}/>
                            </section> */}
            </>
          ) : (
            <Placeholder title="NO REPORTS" />
          )}
        </TabPane>
      </TabContent>
    </>
  );
}

import React, { useState, useEffect, useMemo, useCallback } from "react";
import Table from "../../../../components/Table";
import ReviewsTableStructure from "../components/reviewsComponents/reviewsTableStructure";
import TrainerManagementService from "../../../../services/trainerManagement";
import { useHistory, useLocation } from "react-router-dom";
import qs from "querystring";
import {
  REPORTS_LIMIT,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  REVIEW_SORT_TYPES,
} from "../../../../constants/paginationConstants";
import queryParser from "../../../../helpers/queryParser";
import { SORT_ACTION_TYPE } from "../../../../constants/tableConstants";

function TableHeader() {
  return (
    <section className="trainer-reviews-list--header">
      <span className="trainer-reviews-list--header__title">List of reviews</span>
    </section>
  );
}
export default function Reviews({ trainer }) {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [reviewsPagination, setReviewsPagination] = useState({});

  const history = useHistory();

  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getReviews = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...query,
        Limit: REPORTS_LIMIT,
      };
      const { data, pagination } = await TrainerManagementService.getTrainerRewies(trainer.id, newQuery);
      setReviews(data);
      setReviewsPagination(pagination);
    } finally {
      setIsDataLoading(false);
    }
  }, [query]);

  const handleTableChange = async (type, { page, sortField, sortOrder }) => {
    const params = {
      ...query,
      offset: REPORTS_LIMIT * (page - 1),
    };
    if (type === SORT_ACTION_TYPE) {
      params[ORDER_TYPE_KEY] = sortOrder;
      params[ORDER_FIELD_KEY] = REVIEW_SORT_TYPES[sortField];
    }
    history.push({
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  return (
    <Table
      dataStructure={ReviewsTableStructure}
      data={reviews}
      FiltersComponent={TableHeader}
      handleTableChange={handleTableChange}
      limit={REPORTS_LIMIT}
      totalCount={reviewsPagination.totalCount}
      offset={query.offset || 0}
      loading={isDataLoading}
      isRowClick={false}
    />
  );
}

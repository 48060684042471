import StorageService from "../../services/StorageService";
import { SESSION } from "../../constants/storageConstants";
import { useRef, useEffect } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";

export default function useSocket(handleNewMessage) {
  const socket = useRef();
  const token = StorageService.getValueByKey(SESSION)?.accessToken;

  if (!token) {
    return;
  }
  const uri = process.env.REACT_APP_SOCKET_URL;

  function getSocket() {
    socket.current = new ReconnectingWebSocket(uri, token, {
      reconnectInterval: 5000,
    });
  }

  useEffect(() => {
    getSocket();

    socket.current.onopen = function (event) {
      console.log("opened connection");
    };

    socket.current.onclose = function (event) {
      console.log("closed connection");
    };

    socket.current.onmessage = function (event) {
      handleNewMessage(JSON.parse(event.data));
    };

    socket.current.onerror = function (event) {
      console.error("error: " + event);
    };

    return () => {
      socket.current.close();
    };
  }, [handleNewMessage]);

  return socket.current;
}

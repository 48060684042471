import Http from "./axios-config";

class SessionManagementService {
  constructor() {
    this.http = new Http();
  }

  async getTuteeSessionDetails(tuteeId, sessionId) {
    return await this.http.get(`/admin-tutee-management/${tuteeId}/sessions/${sessionId}`);
  }

  async getTuteeSessionReports(tuteeId, sessionId) {
    return await this.http.get(`/admin-tutee-management/${tuteeId}/sessions/${sessionId}/reports`);
  }

  async getTuteeSessionReview(tuteeId, sessionId) {
    return await this.http.get(`/admin-tutee-management/${tuteeId}/sessions/${sessionId}/review`);
  }
  
  async getTrainerSessionDetails(trainerId, sessionId) {
    return await this.http.get(`/admin-trainer-management/${trainerId}/training-session/${sessionId}`);
  }
  
  async getTrainerOfferDetails(trainerId, sessionId) {
    return await this.http.get(`/admin-trainer-management/${trainerId}/training-timeslot/${sessionId}`);
  }
}

export default new SessionManagementService();

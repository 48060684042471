import React from "react";

import joinClassNames from "../../helpers/joinClassNames";
/* SVG icons */
import { ReactComponent as ascSort } from "../../assets/images/icons/sort-asc.svg";
import { ReactComponent as descSort } from "../../assets/images/icons/sort-desc.svg";
import { ReactComponent as ok } from "../../assets/images/icons/ok.svg";
import { ReactComponent as reviewPlaceholder } from "../../assets/images/icons/im_message@2x.svg";
import { ReactComponent as cancelledIcon } from "../../assets/images/icons/ic_cancelled sessions@2x.svg";
import { ReactComponent as recieveReport } from "../../assets/images/icons/ic_recieve report@2x.svg";
import { ReactComponent as manyReports } from "../../assets/images/icons/receive more than 5 reports@2x.svg";
import { ReactComponent as updateProfile } from "../../assets/images/icons/ic_updated profile@2x.svg";
import { ReactComponent as arrowLeft } from "../../assets/images/icons/ic_arrow@2x.svg";
import { ReactComponent as pdfIcon } from "../../assets/images/icons/bxs-file-doc@2x.svg";
import { ReactComponent as imageIcon } from "../../assets/images/icons/im_image@2x.svg";
import { ReactComponent as masterCard } from "../../assets/images/icons/MasterCard_Logo@2x.svg";
import { ReactComponent as americanAxpress } from "../../assets/images/icons/American_Express_logo@2x.svg";
import { ReactComponent as board } from "../../assets/images/icons/board.svg";

const iconsMap = {
  sortASC: ascSort,
  sortDESC: descSort,
  ok: ok,
  arrowLeft: arrowLeft,
  reviewPlaceholder: reviewPlaceholder,
  cancelledIcon: cancelledIcon,
  recieveReport: recieveReport,
  manyReports: manyReports,
  updateProfile: updateProfile,
  pdf: pdfIcon,
  image: imageIcon,
  masterCard: masterCard,
  americanAxpress: americanAxpress,
  board: board
};

const Icon = ({ className, icon, ...props }) => {
  let Svg = iconsMap[icon];
  const classes = joinClassNames("icon", className);

  return <Svg className={classes} {...props} />;
};

Icon.defaultProps = {
  className: "",
};

export default Icon;

import React from "react";
import placeholder from "../../assets/images/icons/undraw_No_data_re_kwbl.svg";

export default function Placeholder({ text = "NO DATA" }) {
  return (
    <section className="table-placeholder">
      <span className="table-placeholder__title">{text}</span>
      <img src={placeholder} alt="" className="table-placeholder__image" />
    </section>
  );
}

import ERRORS from "./errorMessages";
import {
  facebookProfileLinkRegExp,
  instagramProfileLinkRegExp,
  PASSWORD_PATTERN,
  youtubeChannelLinkRegExp
} from "./patterns";
import {
  BIO_TEXT_MAX_LENGTH,
  EMAIL_MAX_LENGTH, EXP_DESC_MAX_LENGTH, LINK_MAX_LENGTH, LOCATION_TEXT_MAX_LENGTH,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
  REJECT_TEXT_MAX_LENGTH,
  REJECT_TEXT_MIN_LENGTH
} from "./lengthValidity";

export const oldPasswordValidationRules = {
  required: { value: true, errorMessage: ERRORS.currentPasswordRequired },
};

export const passwordValidationRules = {
  required: { value: true, errorMessage: ERRORS.passwordRequired },
  maxLength: { value: PASSWORD_MAX_LENGTH, errorMessage: ERRORS.passwordLength },
  minLength: { value: PASSWORD_MIN_LENGTH, errorMessage: ERRORS.passwordLength },
  pattern: { value: PASSWORD_PATTERN, errorMessage: ERRORS.passwordFormat },
};

export const passwordConfirmValidationRules = {
  required: { value: true, errorMessage: ERRORS.passwordNoMatch },
  match: { value: "password", errorMessage: ERRORS.passwordNoMatch },
};

export const rejectTextValidationRules = {
  required: { value: true, errorMessage: ERRORS.rejectTextRequired },
  maxLength: { value: REJECT_TEXT_MAX_LENGTH, errorMessage: ERRORS.rejectTextLength },
};

export const emailValidationRules = {
  required: { value: true, errorMessage: ERRORS.emailRequired },
  email: { value: true, errorMessage: ERRORS.emailInvalid },
  maxLength: { value: EMAIL_MAX_LENGTH, errorMessage: ERRORS.emailInvalid },
};

export const firstNameValidationRules = {
  required: { value: true, errorMessage: ERRORS.firstNameRequired },
  maxLength: { value: PASSWORD_MAX_LENGTH, errorMessage: ERRORS.firstName },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.firstName },
};

export const lastNameValidationRules = {
  required: { value: true, errorMessage: ERRORS.lastNameRequired },
  maxLength: { value: PASSWORD_MAX_LENGTH, errorMessage: ERRORS.lastName },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.lastName },
};

export const bioValidationRules = {
  maxLength: { value: BIO_TEXT_MAX_LENGTH, errorMessage: ERRORS.bio },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.bio },
};

export const locationValidationRules = {
  required: { value: true, errorMessage: ERRORS.locationNameRequired },
  maxLength: { value: LOCATION_TEXT_MAX_LENGTH, errorMessage: ERRORS.location },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.location },
};

export const facebookValidationRules = {
  maxLength: { value: LINK_MAX_LENGTH, errorMessage: ERRORS.facebook },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.facebook },
  pattern: { value: facebookProfileLinkRegExp, errorMessage: ERRORS.facebook },
};

export const instagramValidationRules = {
  maxLength: { value: LINK_MAX_LENGTH, errorMessage: ERRORS.instagram },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.instagram },
  pattern: { value: instagramProfileLinkRegExp, errorMessage: ERRORS.instagram },
};

export const youtubeValidationRules = {
  maxLength: { value: LINK_MAX_LENGTH, errorMessage: ERRORS.youtube },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.youtube },
  pattern: { value: youtubeChannelLinkRegExp, errorMessage: ERRORS.youtube },
};

export const experienceJobTitleValidationRules = {
  required: { value: true, errorMessage: ERRORS.experienceJobTitleRequired },
  maxLength: { value: LINK_MAX_LENGTH, errorMessage: ERRORS.experienceJobTitle },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.experienceJobTitle },
};

export const experienceEmployerValidationRules = {
  required: { value: true, errorMessage: ERRORS.experienceEmployerRequired },
  maxLength: { value: LINK_MAX_LENGTH, errorMessage: ERRORS.experienceEmployer },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.experienceEmployer },
};

export const experienceDescriptionValidationRules = {
  required: { value: true, errorMessage: ERRORS.experienceDescriptionRequired },
  maxLength: { value: EXP_DESC_MAX_LENGTH, errorMessage: ERRORS.experienceDescription },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.experienceDescription },
};

export const experienceCityValidationRules = {
  required: { value: true, errorMessage: ERRORS.experienceCityRequired },
  minLength: { value: REJECT_TEXT_MIN_LENGTH, errorMessage: ERRORS.experienceCity },
};
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import Table from '../../../components/Table';
import trainerTableStructure from './tableStructure';
import TrainerManagementService from '../../../services/trainerManagement';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';
import TrainerFilters from './trainersFilters';
import {
  TUTEE_LIMIT,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  ORDER_BY_NAME,
  ORDER_BY_REGISTRED_DATE,
  ORDER_ASC,
  ORDER_DESC,
  DATE_OF_JOINING,
  AVATAR,
} from '../../../constants/paginationConstants';
import queryParser from '../../../helpers/queryParser';
import { SORT_ACTION_TYPE } from '../../../constants/tableConstants';

const orderFields = {
  [AVATAR]: ORDER_BY_NAME,
  [DATE_OF_JOINING]: ORDER_BY_REGISTRED_DATE,
};

const TrainersManagement = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [trainers, setTrainers] = useState([]);
  const [trainersPagination, setTrainersPagination] = useState({});
  const [oldSearch, setOldSearch] = useState('');

  const history = useHistory();

  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getTrainers = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...query,
        Limit: TUTEE_LIMIT,
        Search: query.Search || '',
      };
      const { data, pagination } = await TrainerManagementService.getTrainersList(newQuery);
      setTrainers(data);
      setTrainersPagination(pagination);
    } finally {
      setIsDataLoading(false);
    }
  }, [query]);

  const handleTableChange = async (type, { page, searchText = '', ...other }) => {
    const params = {
      ...query,
      offset: TUTEE_LIMIT * (page - 1),
      Search: searchText.trim(),
    };

    if (oldSearch != params.Search) {
      setOldSearch(params.Search);
      params.offset = 0;
    }

    if (type === SORT_ACTION_TYPE) {
      params[ORDER_TYPE_KEY] = query[ORDER_TYPE_KEY] === ORDER_DESC ? ORDER_ASC : ORDER_DESC;
      params[ORDER_FIELD_KEY] = orderFields[other.sortField];
    }
    history.push({
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    getTrainers();
  }, [getTrainers]);

  return (
    <>
      <MetaTags>
        <title>Trainers management | SweatSearch</title>
      </MetaTags>
      <section className="page-content">
        <Container fluid>
          <Table
            dataStructure={trainerTableStructure}
            data={trainers}
            FiltersComponent={TrainerFilters}
            handleTableChange={handleTableChange}
            limit={TUTEE_LIMIT}
            totalCount={trainersPagination.totalCount}
            offset={query.offset || 0}
            loading={isDataLoading}
            searchField="Search"
          />
        </Container>
      </section>
    </>
  );
};
export default TrainersManagement;

import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { useMemo, useState } from 'react';
import { Row, Col, Alert, Card, CardBody, Container, Modal, Button } from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// action
import { userForgetPassword } from '../../store/actions';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/icons/logo_swearsearch.svg';
import restore from '../../assets/images/icons/restore.svg';

import ERRORS from '../../constants/errorMessages';
import { EMAIL_MAX_LENGTH } from '../../constants/lengthValidity';
import ModalHeader from '../../components/Modal/modalHeader';

const ForgetPasswordPage = ({ userForgetPassword }) => {
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const emailValidationRules = useMemo(
    () => ({
      required: { value: true, errorMessage: ERRORS.emailRequired },
      email: { value: true, errorMessage: ERRORS.emailInvalid },
      maxLength: { value: EMAIL_MAX_LENGTH, errorMessage: ERRORS.emailInvalid },
    }),
    []
  );

  function handleValidSubmit(values) {
    userForgetPassword(values, setShowModal);
  }

  function handleSumbitModal() {
    history.push('/login');
  }

  return (
    <>
      <MetaTags>
        <title>Restore password | SweatSearch</title>
      </MetaTags>
      <div className="account-pages restore-password">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden restore-password--card">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4 pe-0">
                        <h5 className="text-primary">Restore password</h5>
                        <p>SweatSearch</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 pb-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} alt="" height="34" />
                      </span>
                    </div>
                  </div>
                  <div className="p-2 pb-0">
                    <Alert color="success" className="restore-password__alert">
                      Enter your Email and instructions will be sent to you!
                    </Alert>

                    <AvForm className="form-horizontal" onValidSubmit={(event, values) => handleValidSubmit(values)}>
                      <div className="login-page--main-card__form-element">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          validate={emailValidationRules}
                        />
                      </div>
                      <section className="w-100 d-flex flex-row justify-content-end">
                        <button
                          className="btn btn-primary waves-effect waves-light restore-password__reset-button"
                          type="submit"
                        >
                          Send link
                        </button>
                      </section>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={showModal} centered={true} size="md" toggle={() => setShowModal(false)}>
        <section className="restore-modal d-flex flex-column align-items-center">
          <ModalHeader text="" handleClose={() => setShowModal(false)} />

          <img src={restore} className="restore-modal__image" alt="Restore" />

          <span className="restore-modal__text">Check your email to restore password</span>

          <Button color="primary" onClick={handleSumbitModal} className="w-100 align-self-end">
            Okay, thanks
          </Button>
        </section>
      </Modal>
    </>
  );
};

ForgetPasswordPage.propTypes = {
  userForgetPassword: PropTypes.func,
};

export default connect(null, { userForgetPassword })(ForgetPasswordPage);

import React, { useState, useMemo } from 'react';
import { Row, Col, Card, CardBody, Button, Modal } from 'reactstrap';
import profile1 from '../../../assets/images/profile-img.png';
import getOutputValue from '../../../helpers/getOutputValue';
import getFirstLetter from '../../../helpers/getFirstLetter';
import TuteeManagementService from '../../../services/tuteeManagement';
import ModalHeader from '../../../components/Modal/modalHeader';
import TwoModalButtons from '../../../components/Modal/twoModalButtons';
import { MD } from '../../../constants/commonConstants'
import { useHistory } from 'react-router-dom';

export default function ProfileCard({ tutee, updateTutee }) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showConfirmModalDelete, setShowConfirmModalDelete] = useState(false);

  const isBlocked = useMemo(() => tutee.isBlocked, [tutee]);
  const isDeleted = useMemo(() => tutee.isDeleted, [tutee]);

  const history = useHistory();

  async function blockUnblockTutee() {
    try {
      await TuteeManagementService.blockUnblockTutee(tutee.id);
      await updateTutee();
    } finally {
      setShowConfirmModal(false);
    }
  }

  async function handleDeleteTutee() {
    try {
      await TuteeManagementService.deleteTutee(tutee.id);
    } finally {
      setShowConfirmModalDelete(false);
      history.goBack();
    }
  }

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs="6" />
          <Col xs="5" className="align-self-end">
            <img src={profile1} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col sm="4">
            <div className="tutee-details--details__avatar">
              {tutee.avatar?.compactPath ? (
                <div className="avatar-lg">
                  <img src={tutee.avatar?.compactPath} alt="" className="img-thumbnail rounded-circle" />
                </div>
              ) : (
                <span className="avatar-title rounded-circle font-size-20">{getFirstLetter(tutee.firstName)}</span>
              )}
            </div>
            <h5 className="font-size-15 text-truncate">
              {tutee.firstName} {tutee.lastName}
            </h5>
          </Col>

          <Col sm={8}>
            <div className="pt-4">
              <Row>
                <Col xs="6">
                  <h5 className="font-size-15">{getOutputValue(tutee?.healthDetails?.weight)} kg</h5>
                  <p className="text-muted mb-0">Weight</p>
                </Col>
                <Col xs="6">
                  <h5 className="font-size-15">{getOutputValue(tutee?.healthDetails?.height)} cm</h5>
                  <p className="text-muted mb-0">Height</p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-muted mb-0 text-truncate">{isBlocked ? 'Blocked' : 'Active'}</p>
          </Col>
          <Col className="d-flex justify-content-end">
            <Button
              onClick={() => setShowConfirmModal(true)}
              className={`btn btn-${!isBlocked ? 'danger' : 'success'
                } waves-effect waves-light tutee-details--details__button-${!isBlocked ? 'block' : 'unblock'} btn-sm font-size-13`}
              color={!isBlocked ? '#f46a6a' : '#34c38f'}
            >
              {!isBlocked ? 'Block' : 'Unblock'}
            </Button>
            <Button
              color="danger"
              size="sm"
              className="tutee-details--details__button--big font-size-13 deleteTutee"
              onClick={() => setShowConfirmModalDelete(true)}
              disabled={isDeleted}
              id="deleteTutee"
            >
              Delete tutee
            </Button>
          </Col>
        </Row>
      </CardBody>
      <Modal isOpen={showConfirmModal} centered={true} size="md" toggle={() => setShowConfirmModal(false)}>
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader
            text={isBlocked ? 'Unblock Tutee' : 'Block Tutee'}
            handleClose={() => setShowConfirmModal(false)}
          />

          <span className="confirm-modal__modal-text">
            {isBlocked ? 'Tutee will be unblocked' : 'Are you sure you want to block this Tutee?'}
          </span>

          <TwoModalButtons
            firstText={isBlocked ? 'Cancel' : 'No, cancel'}
            secondText={isBlocked ? 'Confirm' : 'Block'}
            firstAction={() => setShowConfirmModal(false)}
            secondAction={blockUnblockTutee}
            firstColor={isBlocked ? 'danger' : 'primary'}
            secondColor={isBlocked ? 'primary' : 'danger'}
          />
        </section>
      </Modal>
      <Modal isOpen={showConfirmModalDelete} centered size={MD} toggle={() => setShowConfirmModalDelete(false)}>
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader text="Account will be deleted" handleClose={() => setShowConfirmModalDelete(false)} />

          <span className="confirm-modal__modal-text">Are you sure you want to delete this tutee account?</span>

          <TwoModalButtons
            firstText="Yes, delete"
            secondText="No, cancel"
            firstAction={handleDeleteTutee}
            secondAction={() => setShowConfirmModalDelete(false)}
            firstColor="danger"
            secondColor="primary"
          />
        </section>
      </Modal>
    </Card>
  );
}

import { POUND } from './defaultConstants';

export function earningStatisticOptions(currency){
  return {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 3,
    },
    colors: ['#556ee6', '#34c38f'],
    xaxis: {
      type: 'datetime',
      tickPlacement: 'on',
      tooltip: {
        enabled: false,
      },
      labels: {
        rotate: 0,
        rotateAlways: false,
        hideOverlappingLabels: true,
        datetimeFormatter: {
          year: 'yyyy',
          month: 'MMM yyyy',
          day: 'dd MMM',
          hour: 'HH:mm',
        },
      },
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy',
      },
      y: {
        formatter: function (value) {
          return `${currency.currency || POUND}${value}`;
        },
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    legend: {
      show: true,
      position: 'bottom',
      horizontalAlign: 'right',
      fontSize: '13px',
      fontFamily: 'Poppins',
      fontWeight: 500,
      itemMargin: {
        horizontal: 15,
        vertical: 25,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    yaxis: {
      show: true,
      showAlways: true,
      showForNullSeries: true,
      labels: {
        show: true,
        maxWidth: 160,
        formatter: value => {
          return `${currency.currency || POUND}${value}`;
        },
      },
    },
    grid: {
      show: true,
      borderColor: '#90A4AE',
      position: 'back',
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}

export const APEX_HEIGHT = '380';
export const APEX_TYPE = 'area';

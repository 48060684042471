import React from 'react';
import formatDate from '../../../../../helpers/formatDate';
import { FULL_DAY_FORMAT, GB_TIME_FORMAT } from '../../../../../constants/dateFormats';
import { Badge } from "reactstrap";
import { POUND } from '../../../../../constants/defaultConstants';
import getSessionDuration from '../../../../../helpers/getSessionDuration'

const STATUSES = ["Pending", "Accepted", "Rejected", "Done", "Expired", "Upcoming", "Cancelled", "PaymentRejected"];
const STATUS_BADGES = [
    { label: "Pending", color: "warning" },
    { label: "Accepted", color: "success" },
    { label: "Rejected", color: "danger" },
    { label: "Done", color: "success" },
    { label: "Expired", color: "danger" },
    { label: "Upcoming", color: "warning" },
    { label: "Cancelled", color: "danger" },
    { label: "PaymentRejected", color: "danger" },
];

export default function SessionInfoStructure() {

    return [
        {
            text: 'Date',
            dataField: 'sessionTime.startDate',
            formatter: cellContent => <>{formatDate(cellContent, FULL_DAY_FORMAT)}</>,
        },
        {
            text: 'Start & End time',
            dataField: 'sessionTime',
            formatter: cellContent => <>{formatDate(cellContent?.startDate, GB_TIME_FORMAT)} - {formatDate(cellContent?.endDate, GB_TIME_FORMAT)}</>,
        },
        {
            text: 'Duration',
            dataField: 'sessionTime',
            formatter: cellContent => {
                return (<>{getSessionDuration(cellContent?.startDate, cellContent?.endDate)}</>)
            },
        },
        {
            text: 'Session statuses',
            dataField: 'sessionStatus',
            formatter: cellContent => {
                const indexOfStatus = STATUSES.indexOf(cellContent);

                return (
                    <Badge className={`badge-soft-${STATUS_BADGES[indexOfStatus].color}  tutee-list--list__badge`}>
                        {STATUS_BADGES[indexOfStatus].label}
                    </Badge>
                );
            },
        },
        {
            text: 'Session label',
            dataField: 'sessionType',
        },
        {
            text: 'Training type',
            dataField: 'specialisation',
            formatter: cellContent => {
                return (<>{cellContent?.type || "-"}</>);
            }
        },
        {
            text: 'Session price',
            dataField: 'price',
            formatter: (cellContent, params) => {
                return <>{`${params?.currencyModel?.symbol || POUND}${cellContent}`}</>
            },
        },
    ];
}

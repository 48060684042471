import Http from "./axios-config";

class PaymentsManagementService {
  constructor() {
    this.http = new Http();
  }

  async getPayments(params) {
    return await this.http.get("/admin-transaction-management", {
      params,
    });
  }

  async getPaymentDetails(id) {
    return await this.http.get(`/admin-transaction-management/${id}`);
  }

  async getSubscriptions(params) {
    return await this.http.get(`/admin-subscriptions-management`, {
      params,
    });
  }
}

export default new PaymentsManagementService();

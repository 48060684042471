import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess } from "./actions";
import { USER, SESSION } from "../../../constants/storageConstants";
import StorageService from "../../../services/StorageService";
import AuthentificationService from "../../../services/authentification";

function* loginUser({ payload: { values, history, setIsLoading } }) {
  try {
    const {
      data: { token, user },
    } = yield call([AuthentificationService, AuthentificationService.adminLogin], values);

    yield put(loginSuccess({ user, token }));

    StorageService.addValueByKey(USER, user);
    StorageService.addValueByKey(SESSION, token);

    history.push("/tutee-management");
  } catch {
    //empty
  } finally {
    setIsLoading(false);
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const sessionToken = StorageService.getValueByKey(SESSION);
    if (!sessionToken) {
      history.push("/login");
      return;
    }
    yield call([AuthentificationService, AuthentificationService.adminLogout]);
    StorageService.deleteValueByKey(USER);
    StorageService.deleteValueByKey(SESSION);
  } finally {
    history.push("/login");
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;

import React, { useState, useCallback, useEffect, useMemo } from 'react';
import TuteeManagementService from '../../../../services/tuteeManagement';
import { SESSIONS_LIMIT } from '../../../../constants/paginationConstants';
import { useHistory, useLocation } from 'react-router-dom';
import queryParser from '../../../../helpers/queryParser';
import sessionsListStructure from './tableStructure';
import Table from '../../../../components/Table';
import qs from 'query-string';

export default function SessionInfo({ tutee }) {
  const [sessions, setSessions] = useState([]);
  const [sessionPagination, setSessionPagination] = useState({});

  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getSessions = useCallback(async () => {
    const params = {
      Limit: SESSIONS_LIMIT,
      Offset: query.sessionOffset,
    };
    const { data, pagination } = await TuteeManagementService.getTuteeSessions(tutee.id, params);
    setSessions(data);
    setSessionPagination(pagination);
  }, [query.sessionOffset]);

  function handleTableChange(type, { page }) {
    const params = {
      ...query,
      sessionOffset: SESSIONS_LIMIT * (page - 1),
    };
    history.push({
      search: qs.stringify(params),
    });
  }

  useEffect(() => {
    getSessions();
  }, [getSessions]);

  return (
    <Table
      dataStructure={sessionsListStructure}
      data={sessions}
      FiltersComponent={() => null}
      handleTableChange={handleTableChange}
      limit={SESSIONS_LIMIT}
      totalCount={sessionPagination.totalCount}
      offset={query.sessionOffset || 0}
      rowClickPath="/session"
    />
  );
}

import React, { useState, useCallback, useEffect, useMemo } from "react";
import TrainerManagementService from "../../../../services/trainerManagement";
import { Spinner, Row, Col } from "reactstrap";
import SpecializationCard from "../components/docsComponents/specializationCard";
import WorkingHistory from "../components/docsComponents/workingHistory";
import GovernmentId from "../components/docsComponents/governmentId";
import Qualifications from "../components/docsComponents/qualifications";

export default function TrainerDocsTab({ trainer }) {
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [trainerData, setTrainerData] = useState({
    government: null,
    specializations: null,
    specializationsList: null,
    history: null,
    quelifications: null,
  });

  const { id } = useMemo(() => trainer, [trainer]);

  const getAllTrainerInfoAndDocs = useCallback(() => {
    setIsDataLoading(true);
    Promise.all([
      TrainerManagementService.getGovernmentID(id),
      TrainerManagementService.getQualifications(id),
      TrainerManagementService.getSpecializations(id),
      TrainerManagementService.getTrainerSpecialization(),
      TrainerManagementService.getWorkingHistory(id),
    ])
      .then(([government, qualifications, specializations,specializationsList, workingHistory]) => {
        setTrainerData({
          government: government.data,
          specializations: specializations.data,
          specializationsList: specializationsList.data?.enums,
          history: workingHistory.data,
          quelifications: qualifications.data,
        });
      })
      .finally(() => setIsDataLoading(false));
  }, [trainer]);

  useEffect(() => {
    getAllTrainerInfoAndDocs();
  }, [getAllTrainerInfoAndDocs]);

  return (
    <>
      {isDataLoading ? (
        <section className="tutee-details__spinner">
          <Spinner color="info" />
        </section>
      ) : (
        <Row>
          <Col lg={8}>
            {trainerData.specializations && <SpecializationCard specializations={trainerData.specializations.map(({id})=>id)} specializationsList={trainerData.specializationsList} id={id} />}
            {trainerData.history && <WorkingHistory history={trainerData.history} />}
          </Col>
          <Col lg={4}>
            {trainerData.government && <GovernmentId governmentID={trainerData.government} id={id} />}
            {trainerData.quelifications && <Qualifications qualifications={trainerData.quelifications} id={id} />}
          </Col>
        </Row>
      )}
    </>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import qs from 'query-string';

import TuteeManagementService from '../../../../services/tuteeManagement';
import Table from '../../../../components/Table';
import bookingListStructure from './tableStructure';
import queryParser from '../../../../helpers/queryParser';
import { BOOKING_LIMIT } from '../../../../constants/paginationConstants';

const BookingRequest = ({ tutee }) => {
  const [booking, setBooking] = useState([]);
  const [bookingPagination, setBookingPagination] = useState({});
  const [count, setCount] = useState(0);

  const location = useLocation();
  const history = useHistory();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getBooking = useCallback(async () => {
    const params = {
      Limit: BOOKING_LIMIT,
      Offset: query.bookingOffset,
    };

    const { data, pagination } = await TuteeManagementService.getTuteeBooking(tutee.id, params);

    setBooking(data);
    setBookingPagination(pagination);
  }, [query.bookingOffset]);

  function handleTableChange(type, { page }) {
    const params = {
      ...query,
      bookingOffset: BOOKING_LIMIT * (page - 1),
    };
    history.push({
      search: qs.stringify(params),
    });
  }

  useEffect(() => {
    getBooking();
  }, [getBooking]);

  useEffect(async () => {
    const {
      data: { count },
    } = await TuteeManagementService.getTuteeBookingCount(tutee.id);
    setCount(count);
  }, []);

  return (
    <Card>
      <CardBody className="pb-2">
        <p className="mb-2 booking-request__title">Total amount of booking requests</p>
        <span className="mb-0 booking-request__count">{count}</span>
      </CardBody>
      <Table
        dataStructure={bookingListStructure}
        data={booking}
        FiltersComponent={() => null}
        handleTableChange={handleTableChange}
        limit={BOOKING_LIMIT}
        totalCount={bookingPagination?.totalCount}
        offset={query.bookingOffset || 0}
        rowClickPath="/booking"
      />
    </Card>
  );
};

export default BookingRequest;

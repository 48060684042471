import React, { useMemo } from "react";
import { Card, Row, Col } from "reactstrap";
import formatDate from "../../../helpers/formatDate";
import getOutputValue from "../../../helpers/getOutputValue";

export default function PersonalInfo({ tutee }) {
  const infoArray = useMemo(
    () => [
      { label: "Date of birth :", value: formatDate(tutee.dateOfBirth) },
      { label: "E-mail :", value: getOutputValue(tutee.email) },
      {
        label: "Level of physical fit :",
        value: getOutputValue(tutee.physicalLevel?.type),
      },
      {
        label: "Date of joining the platform :",
        value: formatDate(tutee.dateOfJoining),
        className: "tutee-details--info--row__no-border",
      }
    ],
    [tutee]
  );

  return (
    <Card className="tutee-details--info">
      {infoArray.map((item, index) => (
        <Row className={item.className || "d-flex flex-row tutee-details--info--row"} key={index}>
          <Col lg="4">
            <span className="tutee-details--info--row__label">{item.label}</span>
          </Col>
          <Col lg="8">
            <span className="tutee-details--info--row__value">{item.value}</span>
          </Col>
        </Row>
      ))}
    </Card>
  );
}

import React, { useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryParser from "../../../../../helpers/queryParser";
import qs from "query-string";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { TRAINER, TUTEE } from "../../../../../constants/roles";

export default function ReportFilters() {
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const [activeTab, setActiveTab] = useState(query.ReportsViewType || TRAINER);

  function handleChangeType(type) {
    setActiveTab(type);
    history.push({
      search: qs.stringify({
        ReportsViewType: type,
      }),
    });
  }

  return (
    <section className="d-flex flex-row justify-content-between">
      <span className="tutee-list__header">List of reports</span>
      <section className="d-flex flex-row">
        <Nav tabs className="nav-tabs-custom nav-justified trainer-reports-tab--tabs">
          <NavItem className="trainer-reports-tab--tabs__tab">
            <NavLink
              className={classnames({
                active: activeTab === TRAINER,
              })}
              onClick={() => {
                handleChangeType(TRAINER);
              }}
            >
              <span className="d-none d-sm-block trainer-reports-tab--tabs__tab__span">Trainer</span>
            </NavLink>
          </NavItem>
          <NavItem className="trainer-reports-tab--tabs__tab">
            <NavLink
              className={classnames({
                active: activeTab === TUTEE,
              })}
              onClick={() => {
                handleChangeType(TUTEE);
              }}
            >
              <span className="d-none d-sm-block trainer-reports-tab--tabs__tab__span">Tutee</span>
            </NavLink>
          </NavItem>
        </Nav>
      </section>
    </section>
  );
}

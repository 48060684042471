import React from 'react';
import formatDate from '../../../../../helpers/formatDate';
import { FULL_DAY_FORMAT } from '../../../../../constants/dateFormats';
import { POUND } from '../../../../../constants/defaultConstants';
import StarRatings from '../../../../../components/Common/StarRating';

export default function BoardsTableStructure() {

    return [
        {
            text: 'Position',
            dataField: 'place',
        },
        {
            text: 'Board name',
            dataField: 'boardType',
        },
        {
            text: 'Reward',
            dataField: 'reward',
            formatter: cellContent => <>{cellContent ? `${POUND}${cellContent}` : "-"}</>,
        },
        {
            text: 'Average rating',
            dataField: 'averageRating',
            formatter: cellContent => {
                return (StarRatings(cellContent))
            },
        },
        {
            text: 'Activity percentage',
            dataField: 'activityIncrease',
            formatter: cellContent => {
                return (<span>{cellContent ? `${cellContent}%` : '-'}</span>)
            },
        },
        {
            text: 'Conducted sessions',
            dataField: 'sessionsCount',
        },
        {
            text: 'Roll end',
            dataField: 'endDate',
            formatter: cellContent => {
                return (<>{formatDate(cellContent, FULL_DAY_FORMAT)}</>);
            }
        },
    ];
}

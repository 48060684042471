import React from 'react';
import Icon from '../../../components/Common/Icon';
import queryParser from '../../../helpers/queryParser';
import {
  ORDER_DESC_v2,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  SORT_BY_DATE,
  SORT_BY_NAME,
  SORT_BY_AMOUNT,
  ASC_SORT_ICON,
  DESC_SORT_ICON,
} from '../../../constants/paginationConstants';
import formatDate from '../../../helpers/formatDate';
import getNameValue from '../../../helpers/getNameValue';
import { GB_FULL_FORMAT } from '../../../constants/dateFormats';
import getHighlightSearch from '../../../helpers/getHighlightSearch';
import { DASH, POUND } from '../../../constants/defaultConstants';

export default function ReportsStructure(location) {
  const query = queryParser(location.search);

  const field = query[ORDER_FIELD_KEY];
  const order = query[ORDER_TYPE_KEY];

  const getSortIcon = cellName => (field === cellName && order === ORDER_DESC_v2 ? DESC_SORT_ICON : ASC_SORT_ICON);

  return [
    {
      dataField: 'user',
      text: '',
      headerFormatter: () => (
        <>
          Tutee name <Icon icon={getSortIcon(SORT_BY_NAME)} />
        </>
      ),
      sort: true,
      formatter: cellContent => {
        const nameString = getNameValue(cellContent?.firstName, cellContent?.lastName);
        return <>{getHighlightSearch(nameString, query.TuteeName) || DASH}</>;
      },
    },
    {
      dataField: 'transactionId',
      text: 'Payment ID',
    },
    {
      text: 'Purchase type',
      dataField: 'purchaseType',
    },
    {
      dataField: 'createdAt',
      sort: true,
      text: '',
      headerFormatter: () => (
        <>
          Payment date <Icon icon={getSortIcon(SORT_BY_DATE)} />
        </>
      ),
      formatter: cellContent => <>{formatDate(cellContent, GB_FULL_FORMAT)}</>,
    },
    {
      dataField: 'amount',
      text: '',
      headerFormatter: () => (
        <>
          Total <Icon icon={getSortIcon(SORT_BY_AMOUNT)} />
        </>
      ),
      sort: true,
      formatter: cellContent => <span>{`${POUND}${cellContent}`}</span>,
    },
  ];
}

export const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
export const FULL_DATE_FORMAT = "MM/DD/YYYY HH:mm";
export const GB_FULL_FORMAT = "MM/DD/YYYY hh:mm A";
export const GB_TIME_FORMAT = "hh:mm A";
export const FULL_DAY_FORMAT = "DD MMM, YYYY";
export const ONLY_YEAR_FORMAT = "YYYY";
export const FULL_DATE_WITH_TIME = "DD MMM, YYYY HH:mm";
export const NOTIFICATION_DATE_FORMAT = "DD MMM, YY";
export const WEEKDAY_NAME_FORMAT = "dddd";
export const BOARDS_DATE_FORMAT = "MMMM, YYYY";
export const STATISTICS_YEAR_FORMAT = "YYYY";

import { FORGET_PASSWORD, FORGET_PASSWORD_SUCCESS, FORGET_PASSWORD_ERROR, RESET_PASSWORD } from "./actionTypes";

export const userForgetPassword = (user, setShowModal) => {
  return {
    type: FORGET_PASSWORD,
    payload: { user, setShowModal },
  };
};

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  };
};

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  };
};

export const userResetPassword = (user, setShowModal) => {
  return {
    type: RESET_PASSWORD,
    payload: { user, setShowModal },
  };
};

import Icon from "../../../../components/Common/Icon";
import React from "react";

export default function Placeholder({ title }) {
  return (
    <section className="session-comment--placeholder">
      <span className="session-comment--placeholder__title">{title}</span>
      <Icon icon="reviewPlaceholder" className="session-comment--placeholder__image" />
    </section>
  );
}

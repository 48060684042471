class StorageService {
  getValueByKey(key) {
    try {
      return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : null;
    } catch {
      //empty
    }
  }

  deleteValueByKey(key) {
    localStorage.removeItem(key);
  }

  addValueByKey(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  onChangeDataInSiblingTab(key, func) {
    const listener = () => {
      func(this.getValueByKey(key));
    };

    window.addEventListener("storage", listener);
    return () => window.removeEventListener("storage", listener);
  }
}

export default new StorageService();

import moment from "moment";
import { SEC_IN_MIN, MS_IN_SEC, MIN_IN_HOURS } from "../constants/timeConstants";
import parseInteger from "./parseInt";

export default function getSessionDuration(start, end) {
  const duration = moment(end).diff(start) / (MS_IN_SEC * SEC_IN_MIN);

  const hours_in_duration = duration / MIN_IN_HOURS;

  if (hours_in_duration >= 1) {
    const hoursString = parseInteger(hours_in_duration) === 1 ? " hour " : " hours ";

    const minutes = duration - parseInteger(hours_in_duration) * MIN_IN_HOURS;

    return parseInteger(hours_in_duration) + hoursString + (minutes > 0 ? minutes + " min " : "");
  }

  return Math.floor(duration) + " min";
}

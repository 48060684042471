import React, { useState, useMemo, useCallback, useEffect } from "react";
import { Spinner, Container } from "reactstrap";
import MetaTags from "react-meta-tags";

import TrainerManagementService from "../../../services/trainerManagement";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Tabs from "./tabs";
import StorageService from "../../../services/StorageService";
import {USER_ID, USER_STRIPE} from "../../../constants/storageConstants";

export default function TrainerDetails({ match }) {
  const id = useMemo(() => match.params.id, [match]);

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [trainer, setTrainer] = useState(null);

  const getTrainer = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const { data } = await TrainerManagementService.getTrainer(id);
      setTrainer(data);
    } finally {
      setIsDataLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getTrainer();

    StorageService.addValueByKey(USER_ID, id);

    return () => {
      StorageService.deleteValueByKey(USER_ID)
      StorageService.deleteValueByKey(USER_STRIPE)
    }
  }, [getTrainer]);


  const onGrantAccess = useCallback(() => {
    const data = StorageService.getValueByKey(USER_STRIPE);

    if (data) {
      getTrainer();
    }
  }, [StorageService]);

  useEffect(() => {
    StorageService.onChangeDataInSiblingTab(USER_STRIPE, onGrantAccess);
  }, [StorageService, onGrantAccess]);

  return (
    <section className="page-content">
      <MetaTags>
        <title>Trainers management | SweatSearch</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="Trainers management" breadcrumbItem="Trainer Details" titleLink="/trainers-management" />
        {isDataLoading && (
          <section className="tutee-details__spinner">
            <Spinner color="info" />
          </section>
        )}
        {trainer && <Tabs trainer={trainer} updateTrainer={getTrainer} />}
      </Container>
    </section>
  );
}

import MetaTags from 'react-meta-tags';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { Row, Col, CardBody, Card, Container, Modal, Button } from 'reactstrap';
// Redux
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { userResetPassword } from '../../store/actions';
import ERRORS from '../../constants/errorMessages';

// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/icons/logo_swearsearch.svg';
import ModalHeader from '../../components/Modal/modalHeader';
import { PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH } from '../../constants/lengthValidity';
import { PASSWORD_PATTERN } from '../../constants/patterns';
import queryParser from '../../helpers/queryParser';
import AuthentificationService from '../../services/authentification';

const ResetPassword = ({ userResetPassword }) => {
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const passwordValidationRules = useMemo(
    () => ({
      required: { value: true, errorMessage: ERRORS.passwordRequired },
      maxLength: { value: PASSWORD_MAX_LENGTH, errorMessage: ERRORS.passwordLength },
      minLength: { value: PASSWORD_MIN_LENGTH, errorMessage: ERRORS.passwordLength },
      pattern: { value: PASSWORD_PATTERN, errorMessage: ERRORS.passwordFormat },
    }),
    []
  );

  const passwordConfirmValidationRules = useMemo(
    () => ({
      required: { value: true, errorMessage: ERRORS.passwordNoMatch },
      match: { value: 'password', errorMessage: ERRORS.passwordNoMatch },
    }),
    []
  );

  const handleValidSubmit = values => {
    const newValues = {
      ...values,
      token: query.token,
      id: query.id,
      requestId: query.requestId,
    };
    userResetPassword(newValues, setShowModal);
  };

  function handleSubmitModal() {
    history.push('/login');
  }

  const checkLinkRelevanse = useCallback(async () => {
    try {
      const { data } = await AuthentificationService.checkLinkRelevance({
        token: query.token,
        id: query.id,
        requestId: query.requestId,
      });
      if (!data.isValid) {
        setShowErrorModal(true);
      }
    } finally {
      //empty
    }
  }, [location]);

  useEffect(() => {
    checkLinkRelevanse();
  }, [checkLinkRelevanse]);

  return (
    <>
      <MetaTags>
        <title>Reset password | SweatSearch</title>
      </MetaTags>
      <div className="account-pages reset-page">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden reset-page--main-card">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4 pe-0">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Change password</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} alt="" height="34" />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(event, values) => {
                        handleValidSubmit(values);
                      }}
                    >
                      <div className="reset-page--main-card__form-element mt-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          placeholder="Enter password"
                          validate={passwordValidationRules}
                        />
                      </div>

                      <div className="reset-page--main-card__form-element">
                        <AvField
                          name="confirmPassword"
                          label="Confirm password"
                          type="password"
                          placeholder="Enter confirm password"
                          validate={passwordConfirmValidationRules}
                        />
                      </div>

                      <div className="d-grid reset-page--main-card__login-button">
                        <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">
                          Save
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal isOpen={showModal} centered={true} size="md" toggle={() => setShowModal(false)}>
        <section className="reset-modal d-flex flex-column">
          <ModalHeader text="Change password" handleClose={() => setShowModal(false)} />

          <span className="reset-modal__text">Password has been changed</span>

          <Button color="primary" onClick={handleSubmitModal} className="w-100 align-self-end">
            OK
          </Button>
        </section>
      </Modal>
      <Modal isOpen={showErrorModal} centered={true} size="md" toggle={handleSubmitModal}>
        <section className="reset-modal d-flex flex-column">
          <ModalHeader text="Expired link" handleClose={handleSubmitModal} />

          <span className="reset-modal__text">Your link has expired</span>

          <Button color="primary" onClick={handleSubmitModal} className="w-100 align-self-end">
            OK
          </Button>
        </section>
      </Modal>
    </>
  );
};

export default connect(null, { userResetPassword })(ResetPassword);

import Http from './axios-config';

class TrainerManagementService {
  constructor() {
    this.http = new Http();
  }

  async getTrainersList(params) {
    return await this.http.get('/admin-trainer-management', {
      params,
    });
  }

  async getTrainer(id) {
    return await this.http.get(`/admin-trainer-management/${id}`);
  }

  async blockUnblockTrainer(id) {
    return await this.http.patch(`/admin-trainer-management/${id}`);
  }

  async approveTrainer(id) {
    return await this.http.post(`/admin-trainer-management/${id}`);
  }

  async getQualifications(id) {
    return await this.http.get(`/admin-trainer-management/${id}/qualifications`);
  }

  async getWorkingHistory(id) {
    return await this.http.get(`/admin-trainer-management/${id}/workinghistory`);
  }

  async getSpecializations(id) {
    return await this.http.get(`/admin-trainer-management/${id}/specializations`);
  }

  async getGovernmentID(id) {
    return await this.http.get(`/admin-trainer-management/${id}/governmentid`);
  }

  async getEarningStatistic(id, params) {
    return await this.http.get(`/admin-trainer-management/${id}/earning-statistics`, {
      params,
    });
  }

  async getTotalAmout(id, params) {
    return await this.http.get(`/admin-trainer-management/${id}/earning-statistics-amount-of-money`, {
      params,
    });
  }

  async rejectTrainer(id, values) {
    return await this.http.delete(`/admin-trainer-management/${id}/message`, {data:values} );
  }

  deleteTrainer(id) {
    return this.http.delete(`/admin-trainer-management/${id}/delete`);
  }
  
  hideTrainer(id) {
    return this.http.delete(`/admin-trainer-management/${id}/hide`);
  }

  async getTrainerRewies(id, params) {
    return await this.http.get(`/admin-trainer-management/${id}/reviews`, { params });
  }

  async getTrainerReports(id, params) {
    return await this.http.get(`/admin-trainer-management/${id}/reports`, { params });
  }

  async getTrainerOffers(id, params) {
    return await this.http.get(`/admin-trainer-management/${id}/offers`, { params });
  }
  
  async deleteOffer(trainerId, offerId) {
    return await this.http.delete(`/admin-trainer-management/${trainerId}/offers/${offerId}`);
  }

  async getTrainerTrainings(id, params) {
    return await this.http.get(`/admin-trainer-management/${id}/training`, { params });
  }
  
  async getTrainerBoards(id, params) {
    return await this.http.get(`/admin-boards-management/trainer/${id}/statistics`, { params });
  }

  async getTrainerTrial() {
    return await this.http.get(`/admin-trainer-management/trial`);
  }

  async patchTrainerTrial(date) {
    return await this.http.patch(`/admin-trainer-management/trial`, { date });
  }

  async patchTrainerByIdTrial(id,date) {
    return await this.http.patch(`/admin-trainer-management/${id}/trial`, { date });
  }

  async addStripeAccountToTrainer (id, account) {
    return await this.http.post(`/admin-stripe/${id}`, { account })
  }

  async getTrainerSpecialization() {
    return await this.http.get(`/system/specialization`);
  }

  async getTrainerGender() {
    return await this.http.get(`/system/genders`);
  }

  async createTrainer(value) {
    return await this.http.post('/admin-trainer-management', value);
  }

  async patchTrainerSpecialization(id, body) {
    return await this.http.patch(`/admin-trainer-management/${id}/Specializations`, body );
  }

  async addTrainerGovernmentId(id, body) {
    return await this.http.post(`/admin-trainer-management/${id}/governmentid`, body );
  }

  async addTrainerQualification(id, body, params) {
    return await this.http.post(`/admin-trainer-management/${id}/qualifications`, body, { params } );
  }

  async deleteTrainerQualification(id, qid) {
    return await this.http.delete(`/admin-trainer-management/${id}/qualifications/${qid}` );
  }
}

export default new TrainerManagementService();

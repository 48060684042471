import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import Table from '../../../components/Table';
import tuteeListStructure from './tableStructure';
import TuteeManagementService from '../../../services/tuteeManagement';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';
import TuteeFilters from './tuteeFilters';
import {
  TUTEE_LIMIT,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  ORDER_BY_NAME,
  ORDER_ASC,
  ORDER_DESC,
  ORDER_BY_REGISTRED_DATE,
  AVATAR,
  DATE_OF_JOINING,
} from '../../../constants/paginationConstants';
import queryParser from '../../../helpers/queryParser';
import { SORT_ACTION_TYPE } from '../../../constants/tableConstants';

const orderFields = {
  [AVATAR]: ORDER_BY_NAME,
  [DATE_OF_JOINING]: ORDER_BY_REGISTRED_DATE,
};

const TuteeManagement = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [tutee, setTutee] = useState([]);
  const [tuteePagination, setTuteePagination] = useState({});
  const [oldSearch, setOldSearch] = useState('');

  const history = useHistory();

  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getTutee = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...query,
        Limit: TUTEE_LIMIT,
        Search: query.Search || '',
      };
      const { data, pagination } = await TuteeManagementService.getTuteeList(newQuery);
      setTutee(data);
      setTuteePagination(pagination);
    } finally {
      setIsDataLoading(false);
    }
  }, [query]);

  const handleTableChange = async (type, { page, searchText = '', sortField }) => {
    const params = {
      ...query,
      offset: TUTEE_LIMIT * (page - 1),
      Search: searchText.trim(),
    };

    if (oldSearch != params.Search) {
      setOldSearch(params.Search);
      params.offset = 0;
    }

    if (type === SORT_ACTION_TYPE) {
      params[ORDER_TYPE_KEY] = query[ORDER_TYPE_KEY] === ORDER_DESC ? ORDER_ASC : ORDER_DESC;
      params[ORDER_FIELD_KEY] = orderFields[sortField];
    }
    history.push({
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    getTutee();
  }, [getTutee]);

  return (
    <>
      <MetaTags>
        <title>Tutee management | SweatSearch</title>
      </MetaTags>
      <section className="page-content">
        <Container fluid>
          <Table
            dataStructure={tuteeListStructure}
            data={tutee}
            FiltersComponent={TuteeFilters}
            handleTableChange={handleTableChange}
            limit={TUTEE_LIMIT}
            totalCount={tuteePagination.totalCount}
            offset={query.offset || 0}
            loading={isDataLoading}
            searchField="Search"
          />
        </Container>
      </section>
    </>
  );
};
export default TuteeManagement;

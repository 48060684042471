import React from 'react';
import Icon from '../../../../../components/Common/Icon';
import queryParser from '../../../../../helpers/queryParser';
import {
  ORDER_DESC_v2,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  ORDER_BY_NAME,
  ORDER_BY_TIMESTAMP,
  ASC_SORT_ICON,
  DESC_SORT_ICON,
} from '../../../../../constants/paginationConstants';
import formatDate from '../../../../../helpers/formatDate';
import getNameValue from '../../../../../helpers/getNameValue';
import { GB_FULL_FORMAT } from '../../../../../constants/dateFormats';
import maxCellWidth from '../../../../../constants/maxCellWidth';
import getFirstLetter from '../../../../../helpers/getFirstLetter';
import { TUTEE } from '../../../../../constants/roles';

export default function ReviewsStructure(location) {
  const query = queryParser(location.search);

  const field = query[ORDER_FIELD_KEY];
  const order = query[ORDER_TYPE_KEY];

  function getTuteeObject(cellValue) {
    const typeOfFilter = query.ReportsViewType;

    if (typeOfFilter === TUTEE) {
      return cellValue.reporter;
    }
    return cellValue.reported;
  }

  const getSortIcon = cellName => (field === cellName && order === ORDER_DESC_v2 ? DESC_SORT_ICON : ASC_SORT_ICON);

  return [
    {
      dataField: 'user',
      headerFormatter: () => (
        <>
          Tutee name <Icon icon={getSortIcon(ORDER_BY_NAME)} />
        </>
      ),
      sort: true,
      formatter: (cellContent, report) => {
        const tuteeObject = getTuteeObject(report);
        return (
          <section className="d-flex flex-row align-items-center">
            {!tuteeObject?.avatar ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">{getFirstLetter(tuteeObject?.firstName)}</span>
              </div>
            ) : (
              <div>
                <img className="rounded-circle avatar-xs" src={tuteeObject.avatar.compactPath} alt="" />
              </div>
            )}
            <span className="tutee-list--list__name">
              {getNameValue(tuteeObject?.firstName, tuteeObject?.lastName)}
            </span>
          </section>
        );
      },
    },
    {
      headerFormatter: () => (
        <>
          Report timestamp <Icon icon={getSortIcon(ORDER_BY_TIMESTAMP)} />
        </>
      ),
      dataField: 'reportTimestamp',
      sort: true,
      formatter: cellContent => <>{formatDate(cellContent, GB_FULL_FORMAT)}</>,
    },
    {
      dataField: 'reportText',
      text: 'Report text',
      style: maxCellWidth,
      formatter: cellContent => (
        <span className="trainer-reviews-list__review-text text-truncate">{cellContent || '-'}</span>
      ),
    },
    {
      dataField: 'reportCase',
      text: 'Report case',
      formatter: cellContent => {
        return <span>{cellContent?.type || '-'}</span>;
      },
    },
  ];
}

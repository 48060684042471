import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Table from '../../../components/Table';
import ReportsStructure from './tableStructure';
import PaymentsManagementService from '../../../services/paymentsManagement';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';
import PaymentFilters from './paymentFilters';
import {
  REPORTS_LIMIT,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  PAYMENT_SORT_TYPES,
} from '../../../constants/paginationConstants';
import queryParser from '../../../helpers/queryParser';
import { SORT_ACTION_TYPE } from '../../../constants/tableConstants';

export default function PaymentsList() {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [paymentsPagination, setPaymentsPagination] = useState({});
  const [isFirstRequest, setIsFirstRequest] = useState(true);

  const history = useHistory();

  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getPayments = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...query,
        Limit: REPORTS_LIMIT,
      };
      const { data, pagination } = await PaymentsManagementService.getPayments(newQuery);
      setPayments(data);
      setPaymentsPagination(pagination);
    } finally {
      setIsDataLoading(false);
    }
  }, [query]);

  const handleTableChange = async (type, { page, searchText, sortField, sortOrder, ...other }) => {
    const search = searchText.trim();
    const params = {
      ...query,
      offset: REPORTS_LIMIT * (page - 1),
      TuteeName: search,
    };
    if (isFirstRequest && !!search) {
      params.offset = 0;
      setIsFirstRequest(false);
    }
    if (!search) {
      setIsFirstRequest(true);
    }
    if (type === SORT_ACTION_TYPE) {
      params[ORDER_TYPE_KEY] = sortOrder;
      params[ORDER_FIELD_KEY] = PAYMENT_SORT_TYPES[sortField];
    }
    history.push({
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  return (
    <Table
      dataStructure={ReportsStructure}
      data={payments}
      FiltersComponent={PaymentFilters}
      handleTableChange={handleTableChange}
      limit={REPORTS_LIMIT}
      totalCount={paymentsPagination.totalCount}
      offset={query.offset || 0}
      loading={isDataLoading}
      searchField="TuteeName"
    />
  );
}

import React from "react";
import getFirstLetter from "../../../../helpers/getFirstLetter";
import getNameValue from "../../../../helpers/getNameValue";
import useGoToUsersDetails from "../../../../helpers/hooks/useGoToUsersDetails";

export default function Reported({ reported }) {
  const goToDetails = useGoToUsersDetails(reported);

  return (
    <section className="report-card--report-involved--reported d-flex flex-row">
      {reported.avatar?.compactPath ? (
        <img
          alt="avatar"
          className="report-card--report-involved--reported__avatar"
          src={reported.avatar?.compactPath}
          onClick={goToDetails}
        />
      ) : (
        <span
          className="avatar-title rounded-circle report-card--report-involved--reported__avatar"
          onClick={goToDetails}
        >
          {getFirstLetter(reported.firstName)}
        </span>
      )}

      <section className="d-flex flex-column">
        <section className="d-flex flex-row align-items-center mt-2 justify-content-between">
          <span className="report-card--report-involved--reporter__name" onClick={goToDetails}>
            {getNameValue(reported.firstName, reported.lastName)}
          </span>
          <span className="report-card--report-involved--reporter__reporter me-0">Reported</span>
        </section>

        <span className="report-card--report-involved--reporter__role">{reported.role}</span>
      </section>
    </section>
  );
}

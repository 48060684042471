import React, { useMemo } from "react";

const OTHER = 5;

export default function ReportCase({ report }) {
  const isOtherType = useMemo(() => report.reportCase.id === OTHER, [report]);

  const headerText = useMemo(() => (!isOtherType ? "Report case" : "Report text"), [report]);

  const description = useMemo(() => (!isOtherType ? report.reportCase.type : report.reportText), [report]);

  return (
    <section className="report-card--report-case">
      <span className="report-card--report-case__header">{headerText}</span>
      <p className="report-card--report-case__text">{description}</p>
    </section>
  );
}

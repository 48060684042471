import React from "react";
import StarRatings from "react-star-ratings";
import { STAR_RATED_COLOR } from '../../constants/commonConstants';
import { STARS_COUNT } from "../../constants/defaultConstants";

export default function StarRating({ rating }) {
  return (
    <StarRatings
      rating={rating}
      starRatedColor={STAR_RATED_COLOR}
      numberOfStars={STARS_COUNT}
      name="rating"
      starDimension="16px"
      starSpacing="1px"
    />
  );
}

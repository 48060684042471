import React, { useMemo } from "react";
import ProfileCard from "../components/InfoComponents/profileCard";
import { Row, Col } from "reactstrap";
import TrainerBiography from "../components/InfoComponents/biography";
import PersonalInfo from "../components/InfoComponents/personalInfo";
import SubscriptionInfo from "../components/InfoComponents/subscriptionInfo";
import formatDate from "../../../../helpers/formatDate";
import getOutputValue from "../../../../helpers/getOutputValue";
import StarRatings from "react-star-ratings";
import { STARS_COUNT } from "../../../../constants/defaultConstants";
import { STAR_RATED_COLOR } from '../../../../constants/commonConstants'

export default function DetailsInfoTab({ trainer, updateTrainer }) {
  const infoArray = useMemo(
    () => [
      { label: "Date of birth :", value: formatDate(trainer.dateOfBirth) },
      { label: "E-mail :", value: getOutputValue(trainer.email) },
      { label: "Languages :", value: getOutputValue(trainer.language) },
      { label: "Location :", value: getOutputValue(trainer.location?.address) },
      {
        label: "Rating :",
        value: (
          <StarRatings
            rating={trainer.rating}
            starRatedColor={STAR_RATED_COLOR}
            numberOfStars={STARS_COUNT}
            name="rating"
            starDimension="16px"
            starSpacing="1px"
          />
        ),
        className: "tutee-details--info--row__no-border",
      },
    ],
    [trainer]
  );

  return (
    <>
      <Row>
        <Col lg={6}>
          <ProfileCard trainer={trainer} updateTrainer={updateTrainer} />
          <PersonalInfo infoArray={infoArray} />
        </Col>
        <Col lg={6}>
          <TrainerBiography trainer={trainer} />
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <SubscriptionInfo trainer={trainer} />
        </Col>
      </Row>
    </>
  );
}

import {AvField, AvForm, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox} from "availity-reactstrap-validation";
import {
    bioValidationRules,
    emailValidationRules,
    experienceCityValidationRules,
    experienceDescriptionValidationRules,
    experienceEmployerValidationRules,
    experienceJobTitleValidationRules,
    facebookValidationRules,
    firstNameValidationRules,
    instagramValidationRules,
    lastNameValidationRules,
    locationValidationRules,
    passwordValidationRules,
    youtubeValidationRules
} from "../../../constants/validationRules";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {Card, CardBody, Container} from "reactstrap";
import TrainerManagementService from "../../../services/trainerManagement";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import toastr from "../../../components/toastr";
import {useHistory} from "react-router-dom";

export default function CreateTrainer({ }) {
    const formRef = useRef();
    const history = useHistory();
    const [specialization, setSpecialization] = useState([])
    const [gender, setGender] = useState([])
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleValidSubmit = async values => {
        try {
            setIsButtonDisabled(true);
            values.dateOfBirth = new Date(values.dateOfBirth).toISOString();
            values.experienceStartdate = new Date(values.experienceStartdate).toISOString();
            values.experienceEnddate = new Date(values.experienceEnddate).toISOString();

            values.bio = values.bio.trim() === "" ? null : values.bio;
            values.facebookLink = values.facebookLink.trim() === "" ? null : values.facebookLink;
            values.instagramLink = values.instagramLink.trim() === "" ? null : values.instagramLink;
            values.youtubeLink = values.youtubeLink.trim() === "" ? null : values.youtubeLink;

            const { data } = await TrainerManagementService.createTrainer(values);

            toastr.success(data.message);

            formRef.current.reset();

            setIsButtonDisabled(false);

            history.push(`/trainers-management/`);
        } catch (e) {
           //empty
            setIsButtonDisabled(false);

        }
    };


    const getTrainerSpecialization = useCallback(async () => {
        const { data } = await TrainerManagementService.getTrainerSpecialization();
        setSpecialization(data.enums);
    },[]);

    const getTrainerGender = useCallback(async () => {
        const { data } = await TrainerManagementService.getTrainerGender();
        setGender(data.enums);
    },[]);


    useEffect(()=> {
        getTrainerSpecialization()
        getTrainerGender()
    },[getTrainerSpecialization, getTrainerGender])

    return(
        <section className="page-content">
            <Container fluid>
                <Breadcrumbs title="Trainers management" breadcrumbItem="Create trainer" titleLink="/trainers-management" />

                <span className="trainers-list__header">Create trainer</span>
                <Card className="overflow-hidden settings-card--main-card mt-2">
                    <CardBody className="pt-0">
                        <div className="p-2">
                            <AvForm
                                ref={formRef}
                                className="form-horizontal"
                                onValidSubmit={(event, values) => {
                                    handleValidSubmit(values);
                                }}
                            >
                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="email"
                                        label="Email"
                                        type="email"
                                        placeholder="Enter email"
                                        validate={emailValidationRules}
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="password"
                                        label="Password"
                                        type="password"
                                        placeholder="Enter password"
                                        validate={passwordValidationRules}
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="firstName"
                                        label="First name"
                                        type="text"
                                        placeholder="Enter first name"
                                        validate={firstNameValidationRules}
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="lastName"
                                        label="Last name"
                                        type="text"
                                        placeholder="Enter last name"
                                        validate={lastNameValidationRules}
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvRadioGroup inline name="genderId" label="Gender" required errorMessage="Choose gender">
                                        {gender.map(({ id, type }, index) => (
                                            <AvRadio label={type} value={id} key={index} />
                                        ))}
                                    </AvRadioGroup>
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="bio"
                                        label="Bio"
                                        type="textarea"
                                        placeholder="Enter bio"
                                        validate={bioValidationRules}
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="dateOfBirth"
                                        label="Date of birth"
                                        type="text"
                                        validate={{date: {format: 'MM/DD/YYYY'}}}
                                        required
                                        title="Use MM/DD/YYYY"
                                        placeholder="MM/DD/YYYY"
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="location"
                                        label="Location"
                                        type="text"
                                        placeholder="Enter location"
                                        validate={locationValidationRules}
                                    />
                                </div>
                                <hr className="my-5" />


                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="facebookLink"
                                        label="Facebook link"
                                        type="text"
                                        placeholder="Enter facebook link"
                                        validate={facebookValidationRules}
                                    />
                                </div>
                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="instagramLink"
                                        label="Instagram link"
                                        type="text"
                                        placeholder="Enter Instagram link"
                                        validate={instagramValidationRules}
                                    />
                                </div>
                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="youtubeLink"
                                        label="Youtube link"
                                        type="text"
                                        placeholder="Enter Youtube link"
                                        validate={youtubeValidationRules}
                                    />
                                </div>

                                <hr className="my-5" />

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="experienceJobTitle"
                                        label="Experience job title"
                                        type="text"
                                        placeholder="Enter Experience job title"
                                        validate={experienceJobTitleValidationRules}
                                    />
                                </div>
                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="experienceEmployer"
                                        label="Experience employer"
                                        type="text"
                                        placeholder="Enter experience employer"
                                        validate={experienceEmployerValidationRules}
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="experienceStartdate"
                                        label="Experience start date"
                                        type="text"
                                        validate={{date: {format: 'MM/DD/YYYY'}}}
                                        required
                                        title="Use MM/DD/YYYY"
                                        placeholder="MM/DD/YYYY"
                                    />
                                </div>
                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="experienceEnddate"
                                        label="Experience end date"
                                        type="text"
                                        validate={{date: {format: 'MM/DD/YYYY'}}}
                                        required
                                        title="Use MM/DD/YYYY"
                                        placeholder="MM/DD/YYYY"
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="experienceCity"
                                        label="Experience city"
                                        type="text"
                                        placeholder="Enter experience city"
                                        validate={experienceCityValidationRules}
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvField
                                        name="experienceDescription"
                                        label="Experience description"
                                        type="text"
                                        placeholder="Enter experience description"
                                        validate={experienceDescriptionValidationRules}
                                    />
                                </div>

                                <div className="settings-card--main-card__form-create">
                                    <AvCheckboxGroup
                                        inline
                                        name="specializationIds"
                                        label="Specialization"
                                        required
                                        errorMessage="Choose one or more specialization"
                                    >
                                        {specialization.map(({id, type}, index) => (
                                            <AvCheckbox value={id} label={type} key={index} />
                                        ))}
                                    </AvCheckboxGroup>
                                </div>

                                <div className="d-grid settings-card--main-card__login-button">
                                    <button
                                        className="btn btn-primary btn-block waves-effect waves-light"
                                        type="submit"
                                        disabled={isButtonDisabled}
                                    >
                                        Create trainer
                                    </button>
                                </div>
                            </AvForm>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </section>
    )
}
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Container, Card, Spinner } from "reactstrap";
import SessionManagementService from "../../../../services/sessionService";
import UsersCard from "./usersCard";
import getSessionDuration from "../../../../helpers/getSessionDuration";
import SessionTabs from "./sessionTabs";
import Breadcrumbs from "../../../../components/Common/Breadcrumb2";
import MetaTags from "react-meta-tags";

export default function SessionDetailsCard({ match }) {
  const [session, setSession] = useState({
    details: null,
    reports: [],
    reviews: [],
  });
  const [isDataLoading, setIsDataLoading] = useState(false);

  const {
    params: { id, sessionId },
  } = match;

  const breadcrumbItems = useMemo(
    () => [
      { title: "Tutee management", link: `/tutee-management` },
      { title: "Tutee details", link: `/tutee-management/${id}` },
      { title: "Session details", link: "#" },
    ],
    [id]
  );

  const sessionDetails = useMemo(
    () => [
      { label: "Session type  ", value: session.details?.sessionType },
      { label: "Location ", value: session.details?.location?.address },
      {
        label: "Duration ",
        value: getSessionDuration(session.details?.sessionTime.startDate, session.details?.sessionTime.endDate),
      },
      { label: "Session status", value: session.details?.status },
    ],
    [session]
  );

  const getSessionInfo = useCallback(() => {
    setIsDataLoading(true);
    Promise.all([
      SessionManagementService.getTuteeSessionDetails(id, sessionId),
      SessionManagementService.getTuteeSessionReports(id, sessionId),
      SessionManagementService.getTuteeSessionReview(id, sessionId),
    ])
      .then(([detailsData, reportsData, reviewsData]) => {
        setSession({
          details: detailsData.data,
          reports: reportsData.data,
          reviews: reviewsData.data,
        });
      })
      .finally(() => setIsDataLoading(false));
  }, [id, sessionId]);

  useEffect(() => {
    getSessionInfo();
  }, [getSessionInfo]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Tutee management | SweatSearch</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="Session details" breadcrumbItems={breadcrumbItems} />
        <Card className="report-card">
          {isDataLoading && (
            <section className="report-card__spinner">
              <Spinner color="info" />
            </section>
          )}
          {session.details && !isDataLoading && (
            <>
              <UsersCard session={session.details} />
              <section className="report-card--session">
                {sessionDetails.map((item, index) => (
                  <section className="report-card--session--row row mx-0" key={index}>
                    <span className="report-card--session--row__label col-sm-2 my-auto">{item.label}</span>

                    <span className="report-card--session--row__delimiter col" />

                    <span className="report-card--session--row__value col-sm-9 my-auto">{item.value}</span>
                  </section>
                ))}
              </section>

              <SessionTabs session={session} />
            </>
          )}
        </Card>
      </Container>
    </div>
  );
}

import React, { useState, useMemo } from "react";
import {
  FILTER_BY_ALL,
  FILTER_BY_RECURRING,
  FILTER_BY_SINGULAR,
  DATE_FILTERS,
  DATE_VALUES,
} from "../../../constants/paginationConstants";
import { Dropdown, DropdownMenu, DropdownToggle, Button } from "reactstrap";
import { useLocation, useHistory } from "react-router-dom";
import queryParser from "../../../helpers/queryParser";
import qs from "query-string";
import Checkbox from "../../../components/Common/checkbox";
import { Search } from "react-bootstrap-table2-toolkit";
import { SEARCH_DELAY } from "../../../constants/tableConstants";

const { SearchBar } = Search;

const MAX_FILTERS = [FILTER_BY_ALL, FILTER_BY_RECURRING, FILTER_BY_SINGULAR];
const MAX_FILTERS_COUNT = 2;

const ALL_DATE_FILTERS = Object.values(DATE_VALUES);

export default function PaymentsFilters({ toolkitProps }) {
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const savedFilter = Object.values(DATE_FILTERS).indexOf(query.Period) + 1;

  const [dateFilters, setDateFilters] = useState(savedFilter === DATE_VALUES.all ? ALL_DATE_FILTERS : [savedFilter]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterOption, setFilterOption] = useState(query.PurchaseType ? [query.PurchaseType] : []);

  function handleChangeFilters(filter) {
    setFilterOption(prevState => {
      if (prevState.includes(filter)) {
        return prevState.filter(option => option !== filter && option !== FILTER_BY_ALL);
      }
      const newFilters = [...prevState, filter];

      if (newFilters.length === MAX_FILTERS_COUNT) {
        return MAX_FILTERS;
      }
      return [filter];
    });
  }

  function handleChangeAll() {
    setFilterOption(prevState => {
      if (prevState.includes(FILTER_BY_ALL)) {
        return [];
      }
      return MAX_FILTERS;
    });
  }

  function selectAllDates() {
    setDateFilters(prevState => {
      if (prevState.length === ALL_DATE_FILTERS.length) {
        return [];
      }
      return ALL_DATE_FILTERS;
    });
  }

  function selectDateFilter(filter) {
    setDateFilters(prevState => {
      if (prevState.includes(filter)) {
        return prevState.filter(item => item !== filter && item !== DATE_VALUES.all);
      }

      const newFilters = [...prevState, filter];

      if (newFilters.length >= ALL_DATE_FILTERS.length - 1) {
        return ALL_DATE_FILTERS;
      }
      return newFilters;
    });
  }

  function handleSubmitFilters() {
    setIsFilterOpen(prevState => !prevState);
    const appliedfilter = filterOption.length >= MAX_FILTERS_COUNT ? undefined : filterOption[0];

    let appliedDateFilter = undefined;

    const maxFilter = Math.max.apply(null, dateFilters);

    if (maxFilter > 0) {
      appliedDateFilter = DATE_FILTERS[maxFilter];
    }

    history.push({
      search: qs.stringify({
        PurchaseType: appliedfilter,
        Period: appliedDateFilter,
      }),
    });
  }

  return (
    <section className="d-flex flex-row justify-content-between">
      <span className="trainers-list__header">Transactions for trainings</span>
      <section className="d-flex flex-row">
        <div className="search-box ms-2 mb-2 d-inline-block">
          <div className="position-relative">
            <SearchBar
              {...toolkitProps.searchProps}
              className="reports-management--filters__input"
              delay={SEARCH_DELAY}
              placeholder="Search..."
            />
            <i className="bx bx-search-alt search-icon" />
          </div>
        </div>
        <Dropdown isOpen={isFilterOpen} toggle={() => setIsFilterOpen(prevState => !prevState)}>
          <DropdownToggle tag="button" className="btn trainers-list--dropdown__toggle">
            Filter by <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-right trainers-list--dropdown">
            <span className="trainers-list--dropdown__title ms-2">Purchase type:</span>

            <Checkbox
              value={"all"}
              onChange={handleChangeAll}
              checked={filterOption.includes(FILTER_BY_ALL)}
              label={"All"}
            />
            <Checkbox
              value={"singular"}
              onChange={() => {
                handleChangeFilters(FILTER_BY_SINGULAR);
              }}
              checked={filterOption.includes(FILTER_BY_SINGULAR)}
              label={"Singular"}
            />
            <Checkbox
              value={"recurring"}
              onChange={() => {
                handleChangeFilters(FILTER_BY_RECURRING);
              }}
              checked={filterOption.includes(FILTER_BY_RECURRING)}
              label={"Recurring"}
            />

            <section className="trainers-list--dropdown__next-section">
              <span className="trainers-list--dropdown__title ms-2 mb-2">Period:</span>

              <Checkbox
                value={"5"}
                onChange={selectAllDates}
                checked={dateFilters.includes(DATE_VALUES.all)}
                label={"All"}
              />

              <Checkbox
                value={"1"}
                onChange={() => selectDateFilter(DATE_VALUES.week)}
                checked={dateFilters.includes(DATE_VALUES.week)}
                label={"1 week"}
              />

              <Checkbox
                value={"2"}
                onChange={() => selectDateFilter(DATE_VALUES.month)}
                checked={dateFilters.includes(DATE_VALUES.month)}
                label={"1 month"}
              />

              <Checkbox
                value={"3"}
                onChange={() => selectDateFilter(DATE_VALUES.threeMonth)}
                checked={dateFilters.includes(DATE_VALUES.threeMonth)}
                label={"3 months"}
              />

              <Checkbox
                value={"4"}
                onChange={() => selectDateFilter(DATE_VALUES.year)}
                checked={dateFilters.includes(DATE_VALUES.year)}
                label={"1 year"}
              />
            </section>
            <Button color="success" className="w-100 mt-4" onClick={handleSubmitFilters}>
              Apply
            </Button>
          </DropdownMenu>
        </Dropdown>
      </section>
    </section>
  );
}

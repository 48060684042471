import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import Table from '../../../components/Table';
import ReportsStructure from './tableStructure';
import ReportsManagementService from '../../../services/reportsManagement';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';
import ReportFilters from './reportFilters';
import {
  REPORTS_LIMIT,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  SORT_BY_TIMESTAMP,
  ORDER_ASC,
  ORDER_DESC,
} from '../../../constants/paginationConstants';
import queryParser from '../../../helpers/queryParser';
import { SORT_ACTION_TYPE } from '../../../constants/tableConstants';

const ReportsManagement = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportsPagination, setReportsPagination] = useState({});

  const history = useHistory();

  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getReports = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...query,
        Limit: REPORTS_LIMIT,
        offset: query.offset || 0,
        Search: query.Search || '',
      };
      const { data, pagination } = await ReportsManagementService.getReports(newQuery);
      setReports(data);
      setReportsPagination(pagination);
    } finally {
      setIsDataLoading(false);
    }
  }, [query]);

  const handleTableChange = async (type, { page, searchText }) => {
    const params = {
      ...query,
      offset: REPORTS_LIMIT * (page - 1),
      Search: searchText.trim(),
    };
    if (type === SORT_ACTION_TYPE) {
      params[ORDER_TYPE_KEY] = query[ORDER_TYPE_KEY] === ORDER_DESC ? ORDER_ASC : ORDER_DESC;
      params[ORDER_FIELD_KEY] = SORT_BY_TIMESTAMP;
    }
    history.push({
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    getReports();
  }, [getReports]);

  return (
    <>
      <MetaTags>
        <title>Reports management | SweatSearch</title>
      </MetaTags>
      <section className="page-content">
        <Container fluid>
          <Table
            dataStructure={ReportsStructure}
            data={reports}
            FiltersComponent={ReportFilters}
            handleTableChange={handleTableChange}
            limit={REPORTS_LIMIT}
            totalCount={reportsPagination.totalCount}
            offset={query.offset || 0}
            loading={isDataLoading}
            searchField="Search"
          />
        </Container>
      </section>
    </>
  );
};
export default ReportsManagement;

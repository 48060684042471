import React, {useCallback, useState} from "react";
import { Button, Card, Modal } from "reactstrap";
import DatePicker from "react-datepicker";

import formatDate from "../../../../../helpers/formatDate";
import getOutputValue from "../../../../../helpers/getOutputValue";
import { StripeService } from "../../../../../services/StripeService";
import TrainerManagementService from "../../../../../services/trainerManagement";
import { DARK_COLOR, MD } from "../../../../../constants/commonConstants";
import ModalHeader from "../../../../../components/Modal/modalHeader";
import TwoModalButtons from "../../../../../components/Modal/twoModalButtons";

export default function SubscriptionInfo({trainer}) {
    const [startDate, setStartDate] = useState(new Date(trainer?.subscription?.endDate || null));
    const [prepareDate, setPrepareDate] = useState("");
    const [showConfirmTrial, setShowConfirmTrial] = useState(false);

    const handleClick = () => {
        const {baseUrl, clientId, redirectUri} = StripeService.api;

        window.open(`${baseUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=read_write`, "popup", "width=600,height=600")
    }

    const patchTrainersTrial = useCallback(async (date) => {
        const { data } = await TrainerManagementService.patchTrainerByIdTrial(trainer?.id, date);

        setStartDate( new Date(data?.subscription?.endDate));
    },[]);

    const handleToggleModal = (date) => {
        setPrepareDate(date);
        setShowConfirmTrial(true);
    }

    const handleChangeDate = () => {
        patchTrainersTrial(prepareDate);
        setShowConfirmTrial(false);
    }

  return (
    <Card className="tutee-details--issues">
      <span className="tutee-details--issues__title">Subscription’s info</span>
      <section className="d-flex flex-column w-100 trainer-details--subcription">
        <span className="trainer-details--subcription__type">
          <span className="trainer-details--subcription__title">Subscription type:</span>

          <span className="trainer-details--subcription__value">
            {getOutputValue(trainer?.subscription?.subscriptionType)}
          </span>
        </span>

        <span className="trainer-details--subcription__date">
          <span className="trainer-details--subcription__title">Subscription expiry date:</span>
            <>
            {startDate.toISOString() !== new Date(null).toISOString() ?
                <>
                    <span className="trainer-details--subcription__value">
                        <DatePicker
                            selected={startDate}
                            onChange={(date)=>handleToggleModal(date)}
                            popperPlacement="top-end"
                            popperModifiers={[
                                {
                                    name: "offset",
                                    options: {
                                        offset: [5, 10],
                                    },
                                },
                            ]}
                        />
                    </span>
                </> : <>-</>
            }
            </>
        </span>

        <span className="trainer-details--subcription__date">
          <span className="trainer-details--subcription__title">Date of joining the platform:</span>

          <span className="trainer-details--subcription__value">
            {formatDate(trainer?.dateOfJoining)}
          </span>
        </span>
        <span className="trainer-details--subcription__date">
          <span className="trainer-details--subcription__title">Stripe connected</span>

          <span className="trainer-details--subcription__value">
            {trainer?.isStripeLinked ?
                'connected' :
                <Button
                    color="primary"
                    onClick={handleClick}
                >
                    Connect with <span className="font-weight-semibold">Stripe</span>
                </Button>
            }
          </span>
        </span>
      </section>
        <Modal isOpen={showConfirmTrial} centered size={MD} toggle={() => setShowConfirmTrial(false)}>
            <section className="confirm-modal d-flex flex-column">
                <ModalHeader text="Change trial end date" handleClose={() => setShowConfirmTrial(false)} />
                <div className="my-4">Are you sure you want to set the new PT trial end date?</div>
                <TwoModalButtons
                    firstText="Confirm"
                    secondText="Cancel"
                    firstAction={handleChangeDate}
                    secondAction={() => { setShowConfirmTrial(false) }}
                    secondColor={DARK_COLOR}
                />
            </section>
        </Modal>
    </Card>
  );
}

import React from "react";
import Icon from "../../../../../components/Common/Icon";

export default function DocumentLink({ link, title, description, icon, children }) {
  return (
  <div className="d-flex w-100 align-items-center">
    <a target="_blank" rel="noopener noreferrer" href={link} className="government-id-card__link">
      <section className="avatar-sm">
        <span className="avatar-title rounded-circle bg-primary bg-soft font-size-24">
          <Icon icon={icon} />
        </span>
      </section>
      <section className="w-60 d-flex flex-column">
        <span>{title}</span>
        {description && <span className="government-id-card__description">{description}</span>}
      </section>
      <i className="bx bx-link-external" />
    </a>
      {children}
  </div>
  );
}

import React, { useEffect, useState, useCallback, useMemo } from "react";
import OfferCard from "../components/offersComponents/offerCard";
import { Row, Col } from "reactstrap";
import calculateCurrentPage from "../../../../helpers/calculateCurrentPage";
import ReactPaginate from "react-paginate";
import qs from 'querystring';
import { useHistory, useLocation } from 'react-router-dom';
import queryParser from '../../../../helpers/queryParser';
import trainerManagementService from '../../../../services/trainerManagement';
import Placeholder from "../../../../components/Table/placeholder";
import { OFFERS_LIMIT } from '../../../../constants/paginationConstants';

export default function Offers({ trainer }) {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [offers, setOffers] = useState([]);
  const [offersPagination, setOffersPagination] = useState({});

  const history = useHistory();
  const location = useLocation();
  const { offset = '0', ...params } = useMemo(() => queryParser(location.search), [location]);

  const getOffersInfo = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...params,
        Limit: OFFERS_LIMIT,
        Offset: offset,
      };
      const { data, pagination } = await trainerManagementService.getTrainerOffers(trainer?.id, newQuery);
      setOffers(data);
      setOffersPagination(pagination);
    } finally {
      setIsDataLoading(false);
    }
  }, [trainer?.id, offset]);

  const handleTableChange = async (selectedItem) => {
    const changeParams = {
      ...params,
      offset: OFFERS_LIMIT * (selectedItem.selected),
    };
    history.push({
      search: qs.stringify(changeParams),
    });
  };

  useEffect(() => {
    getOffersInfo();
  }, [getOffersInfo]);

  return (
    <>
      <Row className="m-0">
        {!!offers.length ? offers.map((item) => (
          <Col lg={6} className={"ps-0"}>
            <OfferCard
              offer={item}
              trainerId={trainer?.id}
              getOffersInfo={getOffersInfo} />
          </Col>
        )) :
          <Placeholder />
        }
      </Row>
      {!!offers.length && <Row className="align-items-md-center mt-4">
        <div className="pagination pagination-rounded justify-content-center">
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            pageCount={offersPagination.totalCount / OFFERS_LIMIT}
            onPageChange={handleTableChange}
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="custom-shevron"
            previousLinkClassName="page-link"
            nextClassName="custom-shevron"
            nextLinkClassName="page-link"
            activeClassName="active"
            initialPage={calculateCurrentPage(offset - OFFERS_LIMIT, OFFERS_LIMIT)}
            isDataLoading={isDataLoading}
          />
        </div>
      </Row>}
    </>
  );
}

import React from "react";
import formatDate from "../../../../helpers/formatDate";
import { FULL_DATE_WITH_TIME } from "../../../../constants/dateFormats";

export default function Comment({ comment }) {
  return (
    <section className="session-comment">
      <span className="session-comment__title">{comment?.title}</span>
      <p className="session-comment__description">{comment?.description}</p>
      <section className="session-comment--date-section">
        <i className="bx bx-calendar report-card--report-involved--reporter__price__icon" />
        {formatDate(comment?.date, FULL_DATE_WITH_TIME)}
      </section>
    </section>
  );
}

import React from "react";

export const createContextStore = initialState => {
  const StateContext = React.createContext(initialState);
  const DispatchContext = React.createContext(() => {});
  const Provider = ({ children, value = initialState }) => {
    const [state, update] = React.useState(value);
    return (
      <DispatchContext.Provider value={update}>
        <StateContext.Provider value={state}>{children}</StateContext.Provider>
      </DispatchContext.Provider>
    );
  };
  return {
    State: StateContext,
    DispatchContext,
    Provider,
  };
};
// Output
export const NotificationsContext = createContextStore(0);
export const useNotificationsContext = () => React.useContext(NotificationsContext.State);
export const useUpdateNotificationsContext = () => React.useContext(NotificationsContext.DispatchContext);

import React, { useEffect, useState } from 'react';
import { Card, Collapse, Container, Spinner } from 'reactstrap';

import getNameValue from '../../../../helpers/getNameValue';
import ValueList from '../../../../components/Common/valueList';
import Icon from '../../../../components/Common/Icon';
import getFirstLetter from '../../../../helpers/getFirstLetter';
import getScheduleDates from '../../../../helpers/getScheduleDates';
import { POUND } from '../../../../constants/defaultConstants';
import TuteeManagementService from '../../.././../services/tuteeManagement';
import getBookingDuration from '../../../../helpers/getBookingDuration';
import Breadcrumb from '../../../../components/Common/Breadcrumb2';

const BookingDetails = ({ match }) => {
  const { id, bookingId } = match.params;

  const [booking, setBooking] = useState({});
  const [loading, setLoading] = useState(true);
  const [showSchedule, setShowSchedule] = useState(false);

  const breadcrumbItems = [
    { title: 'Tutee management', link: `/tutee-management` },
    { title: 'Booking', link: `/tutee-management/${id}` },
    { title: 'Booking details', link: '#' },
  ];

  const tableData = [
    { label: 'Session type ', value: booking?.trainingType },
    { label: 'Location ', value: booking?.location?.address },
    {
      label: 'Duration ',
      value: getBookingDuration({ hours: booking?.durationHours, minutes: booking?.durationMinutes }),
    },
    { label: 'Session status ', value: booking?.status },
  ];

  useEffect(async () => {
    const { data } = await TuteeManagementService.getTuteeBookingDetails(id, bookingId);
    setBooking(data);
    setLoading(false);
  }, []);

  return (
    <section className="page-content">
      <Container fluid>
        <Breadcrumb title="Booking details" breadcrumbItems={breadcrumbItems} />
        {loading ? (
          <Spinner />
        ) : (
          <Card className="booking-details p-4">
            <div className="d-flex flex-row justify-content-between mb-5">
              <div className="report-card--report-involved--reporter d-flex flex-row w-100 me-5">
                {booking.trainer?.avatar?.compactPath ? (
                  <img
                    alt="avatar"
                    className="report-card--report-involved--reporter__avatar"
                    src={booking.trainer.avatar.compactPath}
                  />
                ) : (
                  <span className="avatar-title rounded-circle report-card--report-involved--reporter__avatar">
                    {getFirstLetter(booking.trainer.firstName, booking.trainer.lastName)}
                  </span>
                )}

                <div className="d-flex flex-column w-100">
                  <div className="d-flex flex-row justify-content-between align-items-center w-100">
                    <div className="d-flex flex-row align-items-center">
                      <span className="report-card--report-involved--reporter__name">
                        {getNameValue(booking.trainer.firstName, booking.trainer.lastName)}
                      </span>
                    </div>
                    <div className="report-card--report-involved--reporter__badge">{booking.sessionType}</div>
                  </div>

                  <div className="mt-2">
                    <span className="report-card--report-involved--reporter__type">
                      {booking.offerSpecialization.type}
                    </span>
                  </div>

                  <div className="mt-3">
                    <span className="report-card--report-involved--reporter__price">
                      <i className="bx bx-money report-card--report-involved--reporter__price__icon" />
                      {`${booking?.currencyModel?.symbol || POUND}${booking.price}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <ValueList data={tableData} />

            <div className="offer-card--description mt-5">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <span className="offer-card--description__title">Schedule</span>
                {showSchedule ? (
                  <Icon
                    icon="arrowLeft"
                    className="offer-card--description__icon-bottom"
                    onClick={() => setShowSchedule(false)}
                  />
                ) : (
                  <Icon
                    icon="arrowLeft"
                    className="offer-card--description__icon-left"
                    onClick={() => setShowSchedule(true)}
                  />
                )}
              </div>
              <Collapse isOpen={showSchedule}>
                <ul className="verti-timeline list-unstyled mt-4">
                  {booking.schedules?.map(({ date: { startDate }, timeSlotId }, index) => {
                    const { weekday, time, date } = getScheduleDates(startDate);

                    const isActive = timeSlotId === +bookingId;

                    return (
                      <li className={`event-list d-flex${isActive ? ' active' : ''}`} key={index}>
                        <i className="bx bx-right-arrow-circle font-size-18 me-2" />
                        <div className="d-flex flex-row booking-details--row">
                          <div className="pe-0 align-items-center booking-details--row__weekday">
                            <div className="font-size-14">{weekday}</div>
                          </div>
                          <div className="d-flex flex-row align-items-center booking-details--row__time">
                            <i className="bx bx-time report-card--report-involved--reporter__price__icon" />
                            {time}
                          </div>
                          <div className="d-flex flex-row align-items-center booking-details--row__date">
                            <i className="bx bx-calendar report-card--report-involved--reporter__price__icon" />
                            {date}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </Collapse>
            </div>
          </Card>
        )}
      </Container>
    </section>
  );
};

export default BookingDetails;

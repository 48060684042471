export const TUTEE_LIMIT = 11;
export const SESSIONS_LIMIT = 15;
export const BOOKING_LIMIT = 8;
export const REPORTS_LIMIT = 10;
export const OFFERS_LIMIT = 4;

export const ORDER_TYPE_KEY = 'Order.Direction';
export const ORDER_FIELD_KEY = 'Order.Key';
export const ORDER_BY_NAME = 'Name';
export const ORDER_BY_REGISTRED_DATE = 'RegisteredAt';
export const ORDER_ASC = 'Asc';
export const ORDER_DESC = 'Desc';
export const ORDER_BY_TIMESTAMP = 'Timestamp';
export const ORDER_BY_RATING = 'Rating';

export const ORDER_ASC_v2 = 'asc';
export const ORDER_DESC_v2 = 'desc';

export const FILTER_BY_ACTIVE = 'Active';
export const FILTER_BY_BLOCKED = 'Block';
export const FILTER_BY_ALL = 'All';
export const FILTER_BY_PAID = 'Paid';
export const FILTER_BY_UNPAID = 'Unpaid';
export const FILTER_BY_WAITING = 'Waiting';
export const FILTER_BY_REJECTED = 'Rejected';
export const FILTER_BY_ACTIONED = 'Actioned';
export const FILTER_BY_UN_ACTIONED = 'UnActioned';
export const FILTER_BY_ALL_LOCATION = 'AllLocations';
export const FILTER_BY_UK_LOCATION = 'UkLocation';
export const FILTER_BY_AUSTRALIA_LOCATION = 'AustraliaLocation';
export const FILTER_BY_OTHER_LOCATION = 'OtherLocations';
export const FILTER_BY_UK = 'GB';
export const FILTER_BY_AUSTRALIA = 'AU';
export const FILTER_BY_OTHER = 'Other';

export const SORT_BY_TIMESTAMP = 'ReportTimestamp';
export const SORT_BY_DATE = 'Date';
export const SORT_BY_PAYMENT_DATE = 'PaymentDate';
export const SORT_BY_NEXT_BILLING_DATE = 'NextBillingDate';
export const SORT_BY_NAME = 'Name';
export const SORT_BY_AMOUNT = 'Amount';

export const ASC_SORT_ICON = 'sortASC';
export const DESC_SORT_ICON = 'sortDESC';

export const NOTIFICATIONS_LIMIT = 30;

export const PAYMENT_SORT_TYPES = {
  user: 'Name',
  amount: 'Amount',
  createdAt: 'Date',
};

export const SUBSCRIPTION_SORT_TYPES = {
  startDate: 'PaymentDate',
  endDate: 'NextBillingDate',
  amount: 'Amount',
  user: 'Name',
};

export const REVIEW_SORT_TYPES = {
  rating: 'Rating',
  reportTimestamp: 'Timestamp',
  creator: 'Name',
};

export const REPORT_SORT_TYPES = {
  reportTimestamp: 'Timestamp',
  user: 'Name',
};

export const DATE_VALUES = {
  all: 5,
  year: 4,
  threeMonth: 3,
  month: 2,
  week: 1,
};

export const DATE_FILTERS = {
  [DATE_VALUES.week]: 'Week',
  [DATE_VALUES.month]: 'Month',
  [DATE_VALUES.threeMonth]: 'ThreeMonth',
  [DATE_VALUES.year]: 'Year',
  [DATE_VALUES.all]: 'All',
};

export const DATE_FILTERS_REVERSE = {
  [DATE_VALUES.week]: 'Week',
  [DATE_VALUES.month]: 'Month',
  [DATE_VALUES.threeMonth]: 'ThreeMonth',
  [DATE_VALUES.year]: 'Year',
  [DATE_VALUES.all]: 'All',
};

export const DATE_VALUES_LIST = [
  DATE_VALUES.all,
  DATE_VALUES.week,
  DATE_VALUES.month,
  DATE_VALUES.threeMonth,
  DATE_VALUES.year,
]

export const DATE_VALUES_LABELS = {
  [DATE_VALUES.all]: 'All',
  [DATE_VALUES.week]: '1 week',
  [DATE_VALUES.month]: '1 month',
  [DATE_VALUES.threeMonth]: '3 months',
  [DATE_VALUES.year]: '1 year',
}

export const PURCHASE_TYPE_LABELS = {
  All: 'All',
  Trial: 'Free trial',
  InAppSubscription: 'Subscription',
  CreditsSubscription: 'Credits',
}

export const FILTER_BY_RECURRING = 'Recurring';
export const FILTER_BY_SINGULAR = 'Singular';
export const FILTER_BY_FREE_TRIAL = 'Trial';
export const FILTER_BY_SUBSCRIPTION = 'InAppSubscription';
export const FILTER_BY_CREDITS = 'CreditsSubscription';

export const AVATAR = 'avatar';
export const DATE_OF_JOINING = 'dateOfJoining';
import React from 'react';
import { Search } from 'react-bootstrap-table2-toolkit';
import { SEARCH_DELAY } from '../../constants/tableConstants';

const { SearchBar } = Search;

export default function SearchBox({ toolkitProps }) {
  return (
    <div className="search-box ms-2 mb-2 d-inline-block">
      <div className="position-relative">
        <SearchBar
          {...toolkitProps.searchProps}
          className="searh--filters__input"
          delay={SEARCH_DELAY}
          placeholder="Search..."
        />
        <i className="bx bx-search-alt search-icon" />
      </div>
    </div>
  );
}

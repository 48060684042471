import React, { useState, useMemo } from 'react';
import { Card, Modal } from 'reactstrap';
import { POUND } from '../../../../../constants/defaultConstants';
import TwoModalButtons from '../../../../../components/Modal/twoModalButtons';
import ModalHeader from '../../../../../components/Modal/modalHeader';
import { MD } from '../../../../../constants/commonConstants';
import trainerManagementService from '../../../../../services/trainerManagement';
import getBookingDuration from '../../../../../helpers/getBookingDuration';

export default function OfferCard({ offer, trainerId, getOffersInfo }) {
  const [showConfirmModalDelete, setShowConfirmModalDelete] = useState(false);

  async function handleDeleteOffer() {
    try {
      await trainerManagementService.deleteOffer(trainerId, offer?.id);
    } finally {
      setShowConfirmModalDelete(false);
      await getOffersInfo();
    }
  }

  return (
    <Card className="offer-card">
      <section className="offer-card--header">
        <section className="d-flex flex-row align-items-center">
          <span className="offer-card--header__title">{offer?.name}</span>
        </section>
        <i className="bx bx-trash offer-card--header__icon" onClick={() => setShowConfirmModalDelete(true)} />
      </section>
      <section className="d-flex flex-row align-items-center">
        <span className="offer-card__type">{offer?.offerSpecialization?.type}</span>
      </section>
      <section className="d-flex flex-row align-items-center offer-card__price">
        <i className="bx bx-money " />
        {`${offer?.currencyModel?.symbol || POUND}${offer?.price}`}
      </section>

      <section className="mt-4">
        <section className="offer-card--row row mx-0">
          <span className="offer-card--row__label col-sm-3">Session type : </span>

          <span className={"offer-card--row__value col-sm-9 " + 'valueClassname'}>{offer?.trainingType}</span>
        </section>
        <section className="offer-card--row row mx-0">
          <span className="offer-card--row__label col-sm-3">Location : </span>

          <span className={"offer-card--row__value col-sm-9 " + 'valueClassname'}>{offer?.location?.address}</span>
        </section>
        <section className="offer-card--row row mx-0">
          <span className="offer-card--row__label col-sm-3">Duration : </span>

          <span className={"offer-card--row__value col-sm-9 " + 'valueClassname'}>{getBookingDuration({ hours: offer?.durationHours, minutes: offer?.durationMinutes})}</span>
        </section>
      </section>
      <Modal isOpen={showConfirmModalDelete} centered size={MD} toggle={() => setShowConfirmModalDelete(false)}>
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader text="Delete Offer" handleClose={() => setShowConfirmModalDelete(false)} />

          <span className="confirm-modal__modal-text">Are you sure you want to delete this offer?</span>

          <TwoModalButtons
            firstText="Yes, delete"
            secondText="No, cancel"
            firstAction={handleDeleteOffer}
            secondAction={() => setShowConfirmModalDelete(false)}
            firstColor="danger"
            secondColor="primary"
          />
        </section>
      </Modal>
    </Card>
  );
}

import moment from 'moment';
import { GB_TIME_FORMAT, FULL_DAY_FORMAT, WEEKDAY_NAME_FORMAT } from '../constants/dateFormats';

export default function getScheduleDates(timestamp) {
  const momentObject = moment(timestamp);

  return {
    weekday: momentObject.format(WEEKDAY_NAME_FORMAT),
    time: momentObject.format(GB_TIME_FORMAT),
    date: momentObject.format(FULL_DAY_FORMAT),
  };
}

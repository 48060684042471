import React from "react";

export default function ValueList({ data, valueClassname }) {
  return (
    <>
      {data.map((item, index) => (
        <section className="offer-card--row row mx-0" key={index}>
          <span className="offer-card--row__label col-sm-3">{item.label}</span>

          <span className={"offer-card--row__value col-sm-9 " + valueClassname}>{item.value}</span>
        </section>
      ))}
    </>
  );
}

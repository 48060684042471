import Http from "./axios-config";

export class StripeService {
    constructor() {
        this.user = null;
        this.http = new Http();
    }
    static $displayName = "StripeService";
}

StripeService.api = {
    baseUrl: process.env.REACT_APP_STRIPE_AUTH_URL,
    redirectUri: process.env.REACT_APP_STRIPE_REDIRECT_URI,
    clientId: process.env.REACT_APP_STRIPE_CLIENT_ID,
    clientSecret: process.env.REACT_APP_STRIPE_CLIENT_SECRET,
};

export default new StripeService();
export default {
  emailRequired: "Email is required",
  emailInvalid: "Email is invalid",
  passwordRequired: "Password is required",
  firstNameRequired: "First name is required",
  lastNameRequired: "Last name is required",
  locationNameRequired: "Location name is required",
  bioRequired: "Bio is required",
  experienceJobTitleRequired: "Experience job title is required",
  experienceEmployerRequired: "Experience employer is required",
  experienceDescriptionRequired: "Experience description is required",
  experienceCityRequired: "Experience city is required",
  specializationRequired: "Specialization is required",
  passwordNoMatch: "Passwords don’t match",
  passwordLength: "Password must be from 8 to 50 symbols",
  firstName: "First name must be from 1 to 50 symbols",
  lastName: "Last name must be from 1 to 50 symbols",
  experienceJobTitle: "Experience job title must be from 1 to 50 symbols",
  experienceEmployer: "Experience employer must be from 1 to 50 symbols",
  bio: "Bio must be from 1 to 1000 symbols",
  location: "Location must be from 1 to 5000 symbols",
  experienceDescription: "Experience description must be from 1 to 200 symbols",
  experienceCity: "Experience city must be from 1 symbols",
  passwordFormat:
    "Password should contain at least one letter and one digit and one capital letter, can’t contain spaces only",
  currentPasswordRequired: "Old password is required",
  rejectTextRequired: "Reject text is required",
  rejectTextLength: "Reject text must be from 1 to 500 symbols",
  facebook: "Please, provide a valid Facebook account link",
  instagram: "Please, provide a valid Instagram account link",
  youtube: "Please, provide a valid Youtube channel link",
};

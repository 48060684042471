export default function getNameValue(firstName, lastName) {
  if (firstName && lastName) {
    let response = firstName + " " + lastName;
    if (response.length >= 30) {
      response = response.substring(0, 30) + "...";
    }
    return response;
  }
  return firstName || lastName || "-";
}

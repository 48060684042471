import React from "react";
import { Card, Row, Col } from "reactstrap";

export default function PersonalInfo({ infoArray }) {
  return (
    <Card className="tutee-details--info">
      {infoArray.map((item, index) => (
        <Row className={item.className || "d-flex flex-row tutee-details--info--row"} key={index}>
          <Col lg="4">
            <span className="tutee-details--info--row__label">{item.label}</span>
          </Col>
          <Col lg="8">
            <span className="tutee-details--info--row__value">{item.value}</span>
          </Col>
        </Row>
      ))}
    </Card>
  );
}

import React, { useState } from "react";
import {Card, Spinner} from "reactstrap";

import DocumentLink from "./documentLink";
import TrainerManagementService from "../../../../../services/trainerManagement";

export default function GovernmentId({ governmentID, id }) {
    const [initGovernmentID, setGovernmentID] = useState(governmentID?.governmentId?.originalPath || "");
    const [isDataLoading, setIsDataLoading] = useState(false);

    const handleChange = async (event) => {
        try {
            setIsDataLoading(true);
            event.preventDefault();

            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('fileName', event.target.files[0].name);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            const { data } = await TrainerManagementService.addTrainerGovernmentId(id, formData, config);

            setGovernmentID(data?.originalPath);
            setIsDataLoading(false);
        }catch (e){
            setIsDataLoading(false);
            console.error(e);
        }
    }

  return (
      <Card className="government-id-card">
            <span className="government-id-card__title">Government ID</span>
            {isDataLoading ?
                <div className="government-id-card__spinner">
                    <Spinner color="info"/>
                </div> :
                <>
                    {initGovernmentID ? (
                        <DocumentLink link={initGovernmentID} title="Government ID" icon="image"/>
                    ) : (
                        <>-</>
                    )}

                </>
            }
            <div className="custom-uploader">
                <input type="file" id="actual-btn" hidden onChange={handleChange}/>
                <label htmlFor="actual-btn" className="">+</label>
            </div>
      < /Card>
  )
}
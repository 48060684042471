import React from "react";
import leftArrow from "../../../assets/images/icons/left.svg";
import rightArrow from "../../../assets/images/icons/right.svg";
import leftArrowGrey from "../../../assets/images/icons/left-grey.svg";
import rightArrowGrey from "../../../assets/images/icons/right-grey.svg";
import { ONE, NEGATIVE_ONE } from "../../../constants/commonConstants"
import statisticTypes from '../../../constants/statisticTypes';

function getActiveBtnClass(current, expected) {
  return `btn ${current === expected
    ? 'btn-primary earning-card__filter-button'
    : 'btn-outline-primary earning-card__filter-button-secondary'
    } w-sm`;
}

const FILTER_BUTTONS = [
  { label: 'Month', filter: statisticTypes.month },
  { label: 'Year', filter: statisticTypes.year },
];

export default function StatisticsHeader({ date, isRightDisabled, isLeftDisabled, changeDateFilter, changeFilter, activeFilter = statisticTypes.month }) {

  return (
    <section className="d-flex flex-row justify-content-between align-items-center statistics-header">
      <span className="statistics">Statistics</span>

      <section className="align-items-center">
        {!isLeftDisabled ? (
          <img className="arrow" src={leftArrow} alt="left-arrow" onClick={() => changeDateFilter(NEGATIVE_ONE, activeFilter)} />
        ) : (
          <img className="arrow disable" src={leftArrowGrey} alt="left-arrow" />
        )}
        <span className="date">
          {date}
        </span>
        {!isRightDisabled ? (
          <img className="arrow" src={rightArrow} alt="right-arrow" onClick={() => changeDateFilter(ONE, activeFilter)} />
        ) : (
          <img className="arrow disable" src={rightArrowGrey} alt="right-arrow" />
        )}
      </section>

      <div className="btn-group mb-2" role="group">
        {FILTER_BUTTONS.map((btn, index) => (
          <button
            type="button"
            key={index}
            className={getActiveBtnClass(activeFilter, btn.filter)}
            onClick={() => changeFilter(btn.filter)}
          >
            {btn.label}
          </button>
        ))}
      </div>
    </section>
  );
}

import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { Dropdown, DropdownMenu, DropdownToggle, Button, Modal } from "reactstrap";
import SearchBox from "../../../components/Common/Search";
import qs from "query-string";

import {
  FILTER_BY_ALL,
  FILTER_BY_PAID,
  FILTER_BY_UNPAID,
  FILTER_BY_WAITING,
  FILTER_BY_ACTIVE,
  FILTER_BY_BLOCKED,
  FILTER_BY_ALL_LOCATION,
  FILTER_BY_UK_LOCATION,
  FILTER_BY_AUSTRALIA_LOCATION,
  FILTER_BY_OTHER_LOCATION,
  FILTER_BY_OTHER,
  FILTER_BY_AUSTRALIA,
  FILTER_BY_UK,
  FILTER_BY_REJECTED,
} from "../../../constants/paginationConstants";
import queryParser from "../../../helpers/queryParser";
import Checkbox from "../../../components/Common/checkbox";
import TrainerManagementService from "../../../services/trainerManagement";
import { DARK_COLOR, MD } from "../../../constants/commonConstants";
import ModalHeader from "../../../components/Modal/modalHeader";
import TwoModalButtons from "../../../components/Modal/twoModalButtons";

const MAX_SUBSCRIPTION_FILTERS = [FILTER_BY_ALL, FILTER_BY_PAID, FILTER_BY_UNPAID];
const MAX_SUBSCRIPTION_FILTERS_COUNT = 2;
const FILTER_QUERY = "Filter";
const MAX_STATUS_FILTERS_COUNT = 4;
const ALL_APLIED_STATUS_FILTERS = {
  All: true,
  Active: true,
  Block: true,
  Waiting: true,
  Rejected: true,
};
const MAX_COUNTRY_FILTERS_COUNT = 3;
const ALL_APLIED_COUNTRY_FILTERS = {
  AllLocations: true,
  UkLocation: true,
  AustraliaLocation: true,
  OtherLocations: true,
};

const statusFilterTypes = [
  { type: FILTER_BY_ALL },
  { type: FILTER_BY_ACTIVE },
  { type: FILTER_BY_BLOCKED },
  { type: FILTER_BY_WAITING },
  { type: FILTER_BY_REJECTED },
]

const countyFilterTypes = [
  { type: FILTER_BY_ALL_LOCATION, text: FILTER_BY_ALL },
  { type: FILTER_BY_UK_LOCATION, text: FILTER_BY_UK },
  { type: FILTER_BY_AUSTRALIA_LOCATION, text: FILTER_BY_AUSTRALIA },
  { type: FILTER_BY_OTHER_LOCATION, text: FILTER_BY_OTHER },
]

export default function TrainerFilters({ toolkitProps }) {
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const [separateStatusFilters, setSeparateStatusFilters] = useState({
    All: query.All,
    Active: query.Active,
    Block: query.Block,
    Waiting: query.Waiting,
    Rejected: query.Rejected,
  });
  const [separateLocationFilters, setSeparateLocationFilters] = useState({
    All: query.AllLocations,
    UK: query.UkLocation,
    Block: query.AustraliaLocation,
    Waiting: query.OtherLocations,
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterOptionSubscription, setFilterOptionSubscription] = useState(query[FILTER_QUERY] ? [query[FILTER_QUERY]] : []);

  function handleChangeSubscriptionFilters(filter) {
    setFilterOptionSubscription(prevState => {
      if (prevState.includes(filter)) {
        return prevState.filter(option => option !== filter && option !== FILTER_BY_ALL);
      }
      const newFilters = [...prevState, filter];

      if (newFilters.length === MAX_SUBSCRIPTION_FILTERS_COUNT) {
        return MAX_SUBSCRIPTION_FILTERS;
      }
      return [filter];
    });
  }

  function handleSubscriptionChangeAll() {
    setFilterOptionSubscription(prevState => {
      if (prevState.includes(FILTER_BY_ALL)) {
        return [];
      }
      return MAX_SUBSCRIPTION_FILTERS;
    });
  }

  function handleSubmitFilters() {
    setIsFilterOpen(prevState => !prevState);
    const appliedsubscriptionfilter = filterOptionSubscription.length >= MAX_SUBSCRIPTION_FILTERS_COUNT ? FILTER_BY_ALL : filterOptionSubscription[0];

    const appliedSeparateFilters = {};

    for (let filter in separateStatusFilters) {
      if (separateStatusFilters[filter]) {
        appliedSeparateFilters[filter] = true;
      }
    }
    for (let filter in separateLocationFilters) {
      if (separateLocationFilters[filter]) {
        appliedSeparateFilters[filter] = true;
      }
    }
    history.push({
      search: qs.stringify({
        SubscriptionFilter: appliedsubscriptionfilter,
        ...appliedSeparateFilters,
      }),
    });
  }

  function selectAllStatusFilters() {
    setSeparateStatusFilters(prevState => {
      return prevState[FILTER_BY_ALL]
        ? {
          All: false,
          Active: false,
          Block: false,
          Waiting: false,
          Rejected: false,
        }
        : ALL_APLIED_STATUS_FILTERS;
    });
  }
  function handleChangeSeparateStatusFilter(filter, isAll = false) {
    if (isAll) {
      return selectAllStatusFilters();
    }

    setSeparateStatusFilters(prevState => {
      const oldValue = prevState[filter];
      const newFilters = {
        ...prevState,
        [filter]: !oldValue,
      };
      const trueFilterValuesCounter = Object.values(newFilters).filter(item => item).length;

      if (trueFilterValuesCounter >= MAX_STATUS_FILTERS_COUNT && !oldValue) {
        return {
          ...ALL_APLIED_STATUS_FILTERS,
        };
      }
      return {
        ...prevState,
        [filter]: !oldValue,
        [FILTER_BY_ALL]: false,
      };
    });
  }

  function selectAllLocationFilters() {
    setSeparateLocationFilters(prevState => {
      return prevState[FILTER_BY_ALL_LOCATION]
        ? {
          AllLocations: false,
          UkLocation: false,
          AustraliaLocation: false,
          OtherLocations: false,
        }
        : ALL_APLIED_COUNTRY_FILTERS;
    });
  }

  function handleChangeSeparateLocationFilter(filter, isAll = false) {
    if (isAll) {
      return selectAllLocationFilters();
    }

    setSeparateLocationFilters(prevState => {
      const oldValue = prevState[filter];
      const newFilters = {
        ...prevState,
        [filter]: !oldValue,
      };
      const trueFilterValuesCounter = Object.values(newFilters).filter(item => item).length;

      if (trueFilterValuesCounter >= MAX_COUNTRY_FILTERS_COUNT && !oldValue) {
        return {
          ...ALL_APLIED_COUNTRY_FILTERS,
        };
      }
      return {
        ...prevState,
        [filter]: !oldValue,
        [FILTER_BY_ALL_LOCATION]: false,
      };
    });
  }

  const [startDate, setStartDate] = useState(new Date);
  const [prepareDate, setPrepareDate] = useState("");
  const [showConfirmTrial, setShowConfirmTrial] = useState(false);

  const getTrainersTrial = useCallback(async () => {
    const { data } = await TrainerManagementService.getTrainerTrial();

    setStartDate(new Date(data?.date));
  },[]);

  const patchTrainersTrial = useCallback(async (date) => {
      const { data } = await TrainerManagementService.patchTrainerTrial(date);

      setStartDate( new Date(data?.date));
  },[]);

  useEffect(() => {
    getTrainersTrial();
  }, [getTrainersTrial]);

  const handleToggleModal = (date) => {
    setPrepareDate(date);
    setShowConfirmTrial(true);
  }

  const handleChangeDate = () => {
    patchTrainersTrial(prepareDate);
    setShowConfirmTrial(false);
  }

  const goToCreateTrainer = () => {
    history.push(`/trainers-management/create`);
  }

  return (
    <section className="d-flex flex-row justify-content-end position-relative trainers-list__mb28">
      <span className="trainers-list__header position-absolute start-0">The list of Trainers
        <Button
            className="align-self-center trainers-list--dropdown__btn mx-3"
            color="primary"
            onClick={goToCreateTrainer}
        >Create trainer +</Button></span>
      <SearchBox toolkitProps={toolkitProps} />
      <div className="d-flex justify-content-end text-end me-3">
        <span className="align-self-center me-2">Trial end date:</span>
        <div>
          <DatePicker selected={startDate} onChange={(date)=>handleToggleModal(date)} />
        </div>
      </div>
      <Dropdown isOpen={isFilterOpen} toggle={() => setIsFilterOpen(prevState => !prevState)}>
        <DropdownToggle tag="button" className="btn trainers-list--dropdown__toggle">
          Filter by <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right trainers-list--dropdown">
          <span className="trainers-list--dropdown__title ms-2 mb-2">Status</span>
          {
            statusFilterTypes.map(({ type }) => (
              <Checkbox
                value={type}
                onChange={() => handleChangeSeparateStatusFilter(type, type === FILTER_BY_ALL)}
                checked={separateStatusFilters[type]}
                label={type}
              />
            ))
          }
          <section className="trainers-list--dropdown__next-section">
            <span className="trainers-list--dropdown__title ms-2">Subscription</span>
            <Checkbox
              value={"all-subscription"}
              onChange={handleSubscriptionChangeAll}
              checked={filterOptionSubscription.includes(FILTER_BY_ALL)}
              label={"All"}
            />
            <Checkbox
              value={"paid"}
              onChange={() => {
                handleChangeSubscriptionFilters(FILTER_BY_PAID);
              }}
              checked={filterOptionSubscription.includes(FILTER_BY_PAID)}
              label={"Paid"}
            />
            <Checkbox
              value={"unpaid"}
              onChange={() => {
                handleChangeSubscriptionFilters(FILTER_BY_UNPAID);
              }}
              checked={filterOptionSubscription.includes(FILTER_BY_UNPAID)}
              label={"Unpaid"}
            />
          </section>
          <section className="trainers-list--dropdown__next-section">
            <span className="trainers-list--dropdown__title ms-2">Country code</span>
            {
              countyFilterTypes.map(({ type, text }) => (
                <Checkbox
                  value={type}
                  onChange={() => handleChangeSeparateLocationFilter(type, type === FILTER_BY_ALL_LOCATION)}
                  checked={separateLocationFilters[type]}
                  label={text}
                />
              ))
            }
          </section>
          <Button color="success" className="w-100 mt-4" onClick={handleSubmitFilters}>
            Apply
          </Button>
        </DropdownMenu>
      </Dropdown>

      <Modal isOpen={showConfirmTrial} centered size={MD} toggle={() => setShowConfirmTrial(false)}>
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader text="Change trial end date" handleClose={() => setShowConfirmTrial(false)} />
          <div className="my-4">Are you sure you want to set the new PT trial end date?</div>
          <TwoModalButtons
              firstText="Confirm"
              secondText="Cancel"
              firstAction={handleChangeDate}
              secondAction={() => { setShowConfirmTrial(false) }}
              secondColor={DARK_COLOR}
          />
        </section>
      </Modal>
    </section>
  );
}

import React from 'react';
import Icon from '../../../../../components/Common/Icon';
import queryParser from '../../../../../helpers/queryParser';
import {
  ORDER_DESC_v2,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  ORDER_BY_NAME,
  ORDER_BY_TIMESTAMP,
  ORDER_BY_RATING,
  ASC_SORT_ICON,
  DESC_SORT_ICON,
} from '../../../../../constants/paginationConstants';
import formatDate from '../../../../../helpers/formatDate';
import getNameValue from '../../../../../helpers/getNameValue';
import { GB_FULL_FORMAT } from '../../../../../constants/dateFormats';
import StarRating from '../../../../../components/Common/StarRating';
import maxCellWidth from '../../../../../constants/maxCellWidth';
import { Link } from 'react-router-dom';
import getFirstLetter from '../../../../../helpers/getFirstLetter';

export default function ReviewsStructure(location) {
  const query = queryParser(location.search);

  const field = query[ORDER_FIELD_KEY];
  const order = query[ORDER_TYPE_KEY];

  const getSortIcon = cellName => (field === cellName && order === ORDER_DESC_v2 ? DESC_SORT_ICON : ASC_SORT_ICON);

  return [
    {
      dataField: 'creator',
      text: '',
      headerFormatter: () => (
        <>
          Tutee name <Icon icon={getSortIcon(ORDER_BY_NAME)} />
        </>
      ),
      sort: true,
      formatter: cellContent => (
        <section className="d-flex flex-row align-items-center">
          {!cellContent?.avatar ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">{getFirstLetter(cellContent?.firstName)}</span>
            </div>
          ) : (
            <div>
              <img className="rounded-circle avatar-xs" src={cellContent.avatar.compactPath} alt="" />
            </div>
          )}
          <span className="tutee-list--list__name">{getNameValue(cellContent.firstName, cellContent.lastName)}</span>
        </section>
      ),
    },
    {
      dataField: 'rating',
      sort: true,
      text: '',
      headerFormatter: () => (
        <>
          Rating <Icon icon={getSortIcon(ORDER_BY_RATING)} />
        </>
      ),
      formatter: cellContent => <StarRating rating={cellContent} />,
    },
    {
      headerFormatter: () => (
        <>
          Review timestamp <Icon icon={getSortIcon(ORDER_BY_TIMESTAMP)} />
        </>
      ),
      text: '',
      dataField: 'reportTimestamp',
      sort: true,
      formatter: cellContent => <>{formatDate(cellContent, GB_FULL_FORMAT)}</>,
    },
    {
      dataField: 'text',
      text: 'Review text',
      style: maxCellWidth,
      formatter: cellContent => <span className="trainer-reviews-list__review-text text-truncate">{cellContent}</span>,
    },
    {
      dataField: 'Offer',
      text: 'Session',
      formatter: (cellContent, fullValue) => {
        const { sessionId, timeSlotId } = fullValue;
        const isSession = !!sessionId;

        const computedPath = `${isSession ? 'session' : 'booking'}/${isSession ? sessionId : timeSlotId}`;

        return (
          <Link
            to={`/tutee-management/${fullValue.creator.id}/${computedPath}`}
            className="btn-rounded btn-primary btn"
          >
            Session details
          </Link>
        );
      },
    },
  ];
}

import React from 'react';
import { Input, Label } from 'reactstrap';

const Checkbox = ({ value, onChange, checked, label, id = '' }) => {
  const computedId = id || value;

  return (
    <div className="form-check mt-3 ms-2">
      <Input
        type="checkbox"
        value={value}
        className="form-check-input"
        id={computedId}
        onChange={onChange}
        checked={checked}
      />
      <Label className="form-check-label ms-2" htmlFor={computedId}>
        {label}
      </Label>
    </div>
  );
};

export default Checkbox;

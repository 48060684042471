import React, { useState, useRef } from 'react';
import { CardBody, Card, Container, Modal, Button } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ModalHeader from '../../components/Modal/modalHeader';
import CustomSwitch from '../../components/Common/Switch';
import SettingsService from '../../services/settings';
import Icon from '../../components/Common/Icon';
import {
  oldPasswordValidationRules,
  passwordValidationRules,
  passwordConfirmValidationRules,
} from '../../constants/validationRules';
import {
  ADMIN_STORAGE_NAME,
  IS_RECEIVE_EMAILS
} from '../../constants/commonConstants';
import StorageService from '../../services/StorageService';

const Settings = () => {
  const user = StorageService.getValueByKey(ADMIN_STORAGE_NAME) || {};

  const [emailNotifications, setEmailNotifications] = useState({
    checked: user[IS_RECEIVE_EMAILS],
    disabled: false,
  });

  const formRef = useRef();

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleValidSubmit = async values => {
    try {
      setIsButtonDisabled(true);
      await SettingsService.changePassword(values);
      setShowModal(true);
    } finally {
      formRef.current.reset();
      setIsButtonDisabled(false);
    }
  };

  async function handleChangeEmailSwitch() {
    try {
      setEmailNotifications(prevState => ({ ...prevState, disabled: true }));
      const {
        data: { isReceiveEmails },
      } = await SettingsService.changeEmailNotification();

      setEmailNotifications(() => ({ checked: isReceiveEmails, disabled: false }));
      user[IS_RECEIVE_EMAILS] = isReceiveEmails;
      localStorage.setItem(ADMIN_STORAGE_NAME, JSON.stringify(user));
    } finally {
      setEmailNotifications(prevState => ({ ...prevState, disabled: false }));
    }
  }

  return (
    <>
      <MetaTags>
        <title>Settings | SweatSearch</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Card className="overflow-hidden settings-card--main-card">
            <CardBody className="pt-0">
              <div className="p-2">
                <AvForm
                  ref={formRef}
                  className="form-horizontal"
                  onValidSubmit={(event, values) => {
                    handleValidSubmit(values);
                  }}
                >
                  <div className="settings-card--main-card__form-element mt-3">
                    <AvField
                      name="oldPassword"
                      label="Old password"
                      type="password"
                      placeholder="Enter password"
                      validate={oldPasswordValidationRules}
                    />
                  </div>

                  <div className="settings-card--main-card__form-element">
                    <AvField
                      name="password"
                      label="New password"
                      type="password"
                      placeholder="Enter password"
                      validate={passwordValidationRules}
                    />
                  </div>

                  <div className="settings-card--main-card__form-element">
                    <AvField
                      name="confirmPassword"
                      label="Repeat new password"
                      type="password"
                      placeholder="Enter password"
                      validate={passwordConfirmValidationRules}
                    />
                  </div>

                  <div className="d-grid settings-card--main-card__login-button">
                    <button
                      className="btn btn-primary btn-block waves-effect waves-light"
                      type="submit"
                      disabled={isButtonDisabled}
                    >
                      Change password
                    </button>
                  </div>
                </AvForm>

                <section className="d-flex flex-column settings-card--main-card__switch-section">
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <span className="settings-card--main-card__switch-title">Email notifications</span>
                    <CustomSwitch
                      onChange={handleChangeEmailSwitch}
                      checked={emailNotifications.checked}
                      disabled={emailNotifications.disabled}
                    />
                  </div>
                </section>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal isOpen={showModal} centered={true} size="md" toggle={() => setShowModal(false)}>
        <section className="settings-card--modal d-flex flex-column align-items-center">
          <ModalHeader text="" handleClose={() => setShowModal(false)} />

          <Icon icon="ok" className="settings-card--modal__modal-icon" />

          <span className="settings-card--modal__text">Your password is successfully changed</span>

          <Button color="primary" onClick={() => setShowModal(false)} className="w-100 align-self-end">
            Okay, thanks
          </Button>
        </section>
      </Modal>
    </>
  );
};
export default Settings;

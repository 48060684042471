import React from 'react';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import PaymentsTabs from './paymentList/tabs';

const PaymentsManagement = () => {
  return (
    <>
      <MetaTags>
        <title>Payments management | SweatSearch</title>
      </MetaTags>
      <div className="page-content payment-management">
        <Container fluid>
          <PaymentsTabs />
        </Container>
      </div>
    </>
  );
};
export default PaymentsManagement;

import React from 'react';
import Switch from 'react-switch';
import { SWITCH_ON_COLOR, SWITCH_OFF_COLOR } from '../../constants/defaultConstants';

const OffSymbol = () => <div className="switch-component">Off</div>;

const OnSymbol = () => <div className="switch-component">On</div>;

const CustomSwitch = ({ onChange, checked, disabled }) => (
  <Switch
    uncheckedIcon={<OffSymbol />}
    checkedIcon={<OnSymbol />}
    onColor={SWITCH_ON_COLOR}
    offColor={SWITCH_OFF_COLOR}
    onChange={onChange}
    checked={checked}
    disabled={disabled}
  />
);

export default CustomSwitch;

import React, { useMemo, useState } from 'react';
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { Search } from 'react-bootstrap-table2-toolkit';
import {
  DATE_FILTERS,
  DATE_VALUES,
  DATE_VALUES_LABELS,
  DATE_VALUES_LIST,
  FILTER_BY_ALL,
  FILTER_BY_FREE_TRIAL,
  FILTER_BY_SUBSCRIPTION,
  FILTER_BY_CREDITS,
  PURCHASE_TYPE_LABELS
} from '../../../constants/paginationConstants';
import queryParser from '../../../helpers/queryParser';
import Checkbox from '../../../components/Common/checkbox';
import { SEARCH_DELAY } from '../../../constants/tableConstants';

const { SearchBar } = Search;

const MAX_FILTERS_COUNT = 3;
const MAX_STATUS_FILTERS_COUNT = 3;
const ALL_APLIED_FILTERS = {
  All: true,
  Trial: true,
  InAppSubscription: true,
  CreditsSubscription: true,
};

const ALL_DATE_FILTERS = Object.values(DATE_VALUES);

const purchaseTypes = [
  { value: FILTER_BY_ALL },
  { value: FILTER_BY_FREE_TRIAL },
  { value: FILTER_BY_SUBSCRIPTION },
  { value: FILTER_BY_CREDITS },
]

const SubscriptionFilters = ({ toolkitProps }) => {
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const [separateFilters, setSeparatiFilters] = useState({
    All: query.All,
    Trial: query.Trial,
    InAppSubscription: query.InAppSubscription,
    CreditsSubscription: query.CreditsSubscription,
  });

  const savedFilter = Object.values(DATE_FILTERS).indexOf(query.Period) + 1;

  const [paymentDateFilters, setPaymentDateFilters] = useState(
    savedFilter === DATE_VALUES.all ? ALL_DATE_FILTERS : [savedFilter]
  );

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterOption, setFilterOption] = useState(query.PurchaseType ? [query.PurchaseType] : []);
  
  const callbackSetDateFilters = prevState => (prevState.length === ALL_DATE_FILTERS.length ? [] : ALL_DATE_FILTERS);

  const selectPaymentAllDates = () => setPaymentDateFilters(callbackSetDateFilters);

  function selectPaymentDateFilter(filter, isAll = false) {
    if (isAll) {
      return selectPaymentAllDates();
    }

    setPaymentDateFilters(prevState => {
      if (prevState.includes(filter)) {
        return prevState.filter(item => item !== filter && item !== DATE_VALUES.all);
      }

      const newFilters = [...prevState, filter];

      if (newFilters.length >= ALL_DATE_FILTERS.length - 1) {
        return ALL_DATE_FILTERS;
      }

      return newFilters;
    });
  }

  function selectAllFilters() {
    setSeparatiFilters(prevState => {
      return prevState[FILTER_BY_ALL]
        ? {
            All: false,
            Trial: false,
            InAppSubscription: false,
            CreditsSubscription: false,
          }
        : ALL_APLIED_FILTERS;
    });
  }

  function handleSubmitFilters() {
    setIsFilterOpen(prevState => !prevState);
    const appliedFilter = filterOption.length >= MAX_FILTERS_COUNT ? FILTER_BY_ALL : filterOption[0];

    let paymentDateFilter;
    
    const maxPaymentFilter = Math.max.apply(null, paymentDateFilters);
    
    if (maxPaymentFilter > 0) {
      paymentDateFilter = DATE_FILTERS[maxPaymentFilter];
    }

    const appliedSeparateFilters = {};

    for (let filter in separateFilters) {
      if (separateFilters[filter]) {
        appliedSeparateFilters[filter] = true;
      }
    }

    history.push({
      search: qs.stringify({
        PurchaseType: appliedFilter,
        PaymentDate: paymentDateFilter,
        ...appliedSeparateFilters,
      }),
    });
  }
  function handleChangeSeparateFilter(filter, isAll = false) {
    if(isAll) {
      return selectAllFilters();
    }

    setSeparatiFilters(prevState => {
      const oldValue = prevState[filter];
      const newFilters = {
        ...prevState,
        [filter]: !oldValue,
      };
      const trueFilterValuesCounter = Object.values(newFilters).filter(item => item).length;

      if (trueFilterValuesCounter >= MAX_STATUS_FILTERS_COUNT && !oldValue) {
        return {
          ...ALL_APLIED_FILTERS,
        };
      }
      return {
        ...prevState,
        [filter]: !oldValue,
        [FILTER_BY_ALL]: false,
      };
    });
  }


  return (
    <section className="d-flex flex-row justify-content-between">
      <span className="trainers-list__header">Subscriptions payments</span>
      <section className="d-flex flex-row">
        <div className="search-box ms-2 mb-2 d-inline-block">
          <div className="position-relative">
            <SearchBar
              {...toolkitProps.searchProps}
              className="reports-management--filters__input"
              delay={SEARCH_DELAY}
              placeholder="Search..."
            />
            <i className="bx bx-search-alt search-icon" />
          </div>
        </div>
        <Dropdown isOpen={isFilterOpen} toggle={() => setIsFilterOpen(prevState => !prevState)}>
          <DropdownToggle tag="button" className="btn trainers-list--dropdown__toggle">
            Filter by <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-right trainers-list--dropdown">
            <span className="trainers-list--dropdown__title ms-2">Purchase type:</span>
            {
              purchaseTypes.map(({value})=>(
                <Checkbox
                  value={value}
                  id={`purchase_${value}`}
                  onChange={() => handleChangeSeparateFilter(value, value === FILTER_BY_ALL)}
                  checked={separateFilters[value]}
                  label={PURCHASE_TYPE_LABELS[value]}
                />
              ))
            }
            <div className="trainers-list--dropdown__next-section">
              <span className="trainers-list--dropdown__title ms-2 mb-2">Payment date:</span>
              {DATE_VALUES_LIST.map((value, index) => (
                <Checkbox
                  key={`payment-date-${index}`}
                  id={`payment${value}`}
                  value={value}
                  label={DATE_VALUES_LABELS[value]}
                  checked={paymentDateFilters.includes(value)}
                  onChange={() => selectPaymentDateFilter(value, value === DATE_VALUES.all)}
                />
              ))}
            </div>
            <Button color="success" className="w-100 mt-4" onClick={handleSubmitFilters}>
              Apply
            </Button>
          </DropdownMenu>
        </Dropdown>
      </section>
    </section>
  );
};

export default SubscriptionFilters;

import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Container, Card, Spinner } from "reactstrap";
import SessionManagementService from "../../../../../services/sessionService";
import UsersCard from "./usersCard";
import SessionTabs from "../../../../TutteeManagement/tuteeDetails/sessionTab/sessionTabs";
import { TRAINING_SESSION } from "../../../../../constants/tabsConstants";
import { useLocation } from 'react-router-dom';
import getBookingDuration from '../../../../../helpers/getBookingDuration';

export default function SessionDetailsCard({ match, trainer }) {

  const location = useLocation();
  const isSession = location.pathname.includes(TRAINING_SESSION);

  const [session, setSession] = useState({
    details: null,
    reports: [],
    reviews: [],
  });
  const [isDataLoading, setIsDataLoading] = useState(false);

  const { params: { sessionId }, } = match;

  const id = trainer?.id;

  const sessionDetails = useMemo(
    () => [
      { label: "Session type", value: session.details?.sessionType },
      {
        label: "Duration ",
        value: getBookingDuration({ hours: session.details?.sessionDurationHours, minutes: session.details?.sessionDurationMinutes }),
      },
      { label: "Location ", value: session.details?.location?.address },
    ],
    [session]
  );

  const getSessionInfo = useCallback(async () => {
    setIsDataLoading(true);
    try {
      const { data } = isSession ? await SessionManagementService.getTrainerSessionDetails(id, sessionId) : await SessionManagementService.getTrainerOfferDetails(id, sessionId);

      setSession({
        details: data,
        reports: data.reports,
        reviews: data.reviews,
      });
    } finally {
      setIsDataLoading(false);
    }
  }, [id, sessionId]);

  useEffect(() => {
    getSessionInfo();
  }, [getSessionInfo]);

  return (
    <Container fluid>
      <Card className="report-card">
        {isDataLoading && (
          <section className="report-card__spinner">
            <Spinner color="info" />
          </section>
        )}
        {session.details && !isDataLoading && (
          <>
            <UsersCard session={session.details} />
            <section className="report-card--session">
              {sessionDetails.map((item, index) => (
                <section className="report-card--session--row row mx-0" key={index}>
                  <span className="report-card--session--row__label col-sm-2 my-auto">{item.label}</span>

                  <span className="report-card--session--row__delimiter col" />

                  <span className="report-card--session--row__value col-sm-9 my-auto">{item.value}</span>
                </section>
              ))}
            </section>

            <SessionTabs session={session} />
          </>
        )}
      </Card>
    </Container>
  );
}

import React from 'react';

// Authentication related pages
import Login from '../pages/Authentication/Login';
import Logout from '../pages/Authentication/Logout';
import ForgetPwd from '../pages/Authentication/ForgetPassword';
import ResetPwd from '../pages/Authentication/ResetPassword';
import { UK, AUSTRALIA } from '../constants/commonConstants';

// Dashboard
import TuteeManagement from '../pages/TutteeManagement/tuteeList';
import TuteeDetails from '../pages/TutteeManagement/tuteeDetails';
import TuteeSessionDetails from '../pages/TutteeManagement/tuteeDetails/sessionTab/sessionDetails';
import TuteeBookingDetails from '../pages/TutteeManagement/tuteeDetails/bookingTab/bookingDetails';
import TrainersManagement from '../pages/TrainersManagement/trainersList';
import TrainerDetails from '../pages/TrainersManagement/trainerDetails';
import ReportsManagement from '../pages/ReportsManagement/reportsList';
import ReportDetails from '../pages/ReportsManagement/reportDetails';
import PaymentsManagement from '../pages/PaymentsManagement';
import PaymentDetails from '../pages/PaymentsManagement/paymentDetails';
import Settings from '../pages/Settings';
import Notifications from '../pages/Notifications';
import Boards from '../pages/Boards';
import TrainerAuth from "../pages/TrainersManagement/trainerAuth";
import CreateTrainer from "../pages/TrainersManagement/createTrainer";

const userRoutes = [
  { path: '/tutee-management', component: TuteeManagement, exact: true },
  { path: '/tutee-management/:id', component: TuteeDetails, exact: true },
  { path: '/tutee-management/:id/session/:sessionId', component: TuteeSessionDetails, exact: true },
  { path: '/tutee-management/:id/booking/:bookingId', component: TuteeBookingDetails, exact: true },
  { path: '/trainers-management', component: TrainersManagement, exact: true },
  { path: '/trainers-management/auth', component: TrainerAuth, exact: true },
  { path: '/trainers-management/create', component: CreateTrainer },
  { path: '/trainers-management/:id', component: TrainerDetails },
  { path: '/reports-management', component: ReportsManagement, exact: true },
  { path: '/reports-management/:id', component: ReportDetails, exact: true },
  { path: '/payments-management', component: PaymentsManagement, exact: true },
  { path: '/payments-management/:id', component: PaymentDetails, exact: true },
  { path: '/settings', component: Settings, exact: true },
  { path: '/notifications', component: Notifications, exact: true },
  { path: '/boards', component: (props) => <Boards boardLocation={UK} {...props} />, exact: true },
  { path: '/boards/UK', component: (props) => <Boards boardLocation={UK} {...props} />, exact: true },
  { path: '/boards/Australia', component: (props) => <Boards boardLocation={AUSTRALIA} {...props} />, exact: true },
];

const authRoutes = [
  { path: '/logout', component: Logout, exact: true },
  { path: '/login', component: Login },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/reset-password', component: ResetPwd },
];

export { userRoutes, authRoutes };

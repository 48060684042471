import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD } from "./actionTypes";

import AuthentificationService from "../../../services/authentification";

function* forgetUser({ payload: { user, setShowModal } }) {
  try {
    yield call([AuthentificationService, AuthentificationService.adminRestorePassword], user);
    setShowModal(true);
  } catch {
    //empty
  }
}

function* restorePassword({ payload: { user, setShowModal } }) {
  try {
    yield call([AuthentificationService, AuthentificationService.adminResetPassword], user);
    setShowModal(true);
  } catch {
    //empty
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, restorePassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget), fork(watchResetPassword)]);
}

export default forgetPasswordSaga;

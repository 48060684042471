import Http from "./axios-config";

class ReportsManagementService {
  constructor() {
    this.http = new Http();
  }

  async getReports(params) {
    return await this.http.get("/admin-report-management", {
      params,
    });
  }

  async getReport(id) {
    return await this.http.get(`/admin-report-management/${id}`);
  }
}

export default new ReportsManagementService();

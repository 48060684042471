import React, { useState, useEffect, useMemo, useCallback } from "react";
import Table from "../../../../components/Table";
import BoardsTableStructure from "../components/boardsComponents/boardsTableStructure";
import TrainerManagementService from "../../../../services/trainerManagement";
import { useHistory, useLocation } from "react-router-dom";
import qs from "querystring";
import { SESSIONS_LIMIT } from "../../../../constants/paginationConstants";
import queryParser from "../../../../helpers/queryParser";
import getFirstLetter from "../../../../helpers/getFirstLetter";
import getNameValue from "../../../../helpers/getNameValue";
import { BOARDS_REDIRECT, TRAINER_BOARDS_COLOR_STYLE } from "../../../../constants/commonConstants";

export default function Boards({ trainer }) {
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [boardRows, setBoardRows] = useState([]);
    const [boardRowsPagination, setBoardRowsPagination] = useState({});

    const history = useHistory();
    const location = useLocation();
    const query = useMemo(() => queryParser(location.search), [location]);

    const getBoardRows = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const newQuery = {
                ...query,
                Limit: SESSIONS_LIMIT,
            };
            const { data, pagination } = await TrainerManagementService.getTrainerBoards(trainer?.id, newQuery);
            setBoardRows(data);
            setBoardRowsPagination(pagination);
        } finally {
            setIsDataLoading(false);
        }
    }, [query, trainer]);

    const handleTableChange = async (type, { page }) => {
        const params = {
            ...query,
            offset: SESSIONS_LIMIT * (page - 1),
        };
        history.push({
            search: qs.stringify(params),
        });
    };

    const TableHeader = useCallback(async () => {
        <section className="session-info-header d-flex flex-row justify-content-between">
            <div className="d-flex flex-row align-items-center tutee-details">
                {trainer.avatar?.compactPath ? (
                    <div className="avatar-sm">
                        <img src={trainer.avatar?.compactPath} alt="" className="img-thumbnail rounded-circle" />
                    </div>
                ) : (
                    <div className="avatar-sm">
                        <span className="avatar-title rounded-circle font-size-20">{getFirstLetter(trainer.firstName)}</span>
                    </div>
                )}
                <span className="font-size-15 trainer-name">
                    {getNameValue(trainer.firstName, trainer.lastName)}
                </span>
            </div>
        </section>
    }, [trainer]);

    useEffect(() => {
        getBoardRows();
    }, [getBoardRows]);

    return (
        <Table
            dataStructure={BoardsTableStructure}
            data={boardRows}
            FiltersComponent={() => TableHeader}
            handleTableChange={handleTableChange}
            limit={SESSIONS_LIMIT}
            totalCount={boardRowsPagination.totalCount}
            offset={query.offset || 0}
            loading={isDataLoading}
            boardRedirectType={BOARDS_REDIRECT}
            boardStyleType={TRAINER_BOARDS_COLOR_STYLE}
        />
    );
}

import React from "react";
import { Card } from "reactstrap";
import ValueList from "../../../../../components/Common/valueList";
import formatDate from "../../../../../helpers/formatDate";
import { FULL_DAY_FORMAT } from "../../../../../constants/dateFormats";

export default function History({ history }) {
  return (
    <Card className="working-history-card">
      <span className="specializations-card__header">Work history</span>

      {!!history.length ? (
        history.map((item, index) => {
          const tableData = [
            { label: "Job title", value: item.title },
            { label: "Employer", value: item.employer },
            { label: "Start date", value: formatDate(item.startDate, FULL_DAY_FORMAT) },
            {
              label: "End date",
              value: item.isCurrent ? "Current" : formatDate(item.endDate, FULL_DAY_FORMAT),
            },
            { label: "City", value: item.city },
          ];
          return (
            <section className="working-history-card--history-work" key={index}>
              <span className="working-history-card--history-work__title">Work {index + 1}</span>
              <p className="working-history-card--history-work__summary">{item.description}</p>
              <ValueList data={tableData} />
            </section>
          );
        })
      ) : (
        <section className="mt-2">-</section>
      )}
    </Card>
  );
}

import React, { useMemo, useEffect, useState, useCallback } from 'react';
import TuteeManagementService from '../../../services/tuteeManagement';
import { Spinner, Container } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import MetaTags from 'react-meta-tags';
import DetailsTabs from './detailsTabs';

export default function TuteeDetails({ match }) {
  const [tutee, setTutee] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useMemo(() => match.params, [match]);

  const getTuteeProfile = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await TuteeManagementService.getTuteeProfile(id);
      setTutee(data);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getTuteeProfile();
  }, [getTuteeProfile]);

  return (
    <section className="page-content">
      <MetaTags>
        <title>Tutee management | SweatSearch</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="Tutee management" breadcrumbItem="Tutee Details" titleLink="/tutee-management" />
        {isLoading && (
          <section className="tutee-details__spinner">
            <Spinner color="info" />
          </section>
        )}
        {tutee && <DetailsTabs tutee={tutee} updateTutee={getTuteeProfile} />}
      </Container>
    </section>
  );
}

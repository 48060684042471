import { useHistory } from 'react-router-dom';
import { TRAINER, TUTEE } from '../../constants/roles';

export default function useGoToUsersDetails(user) {
  const history = useHistory();

  function goToUsersDetails() {
    if (user.role === TRAINER) {
      history.push(`/trainers-management/${user.id}`);
      return;
    }

    if (user.role === TUTEE) {
      history.push(`/tutee-management/${user.id}`);
    }
  }

  return goToUsersDetails;
}

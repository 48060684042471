import React, { useMemo, useEffect, useState, useCallback } from "react";
import ReportsManagementService from "../../../services/reportsManagement";
import { Spinner, Container } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ReportCard from "./reportCard";
import MetaTags from "react-meta-tags";

export default function TuteeDetails({ match }) {
  const [report, setReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = useMemo(() => match.params, [match]);

  const getReportDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await ReportsManagementService.getReport(id);
      setReport(data);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getReportDetails();
  }, [getReportDetails]);

  return (
    <section className="page-content">
      <MetaTags>
        <title>Reports management | SweatSearch</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs title="Report management" breadcrumbItem="Report details" titleLink="/reports-management" />
        {isLoading && (
          <section className="tutee-details__spinner">
            <Spinner color="info" />
          </section>
        )}
        {report && <ReportCard report={report} />}
      </Container>
    </section>
  );
}

import Http from "./axios-config";

class NotificationsService {
  constructor() {
    this.http = new Http();
  }

  async getNotifications(params) {
    return await this.http.get("/admin-notification", {
      params,
    });
  }

  async getUnreadCount() {
    return await this.http.get(`/admin-notification/unread`);
  }

  async markAllAsRead() {
    return await this.http.patch(`/admin-notification/unread`);
  }
}

export default new NotificationsService();

import Http from "./axios-config";
import Axios from "axios";
import StorageService from "./StorageService";
import { SESSION } from "../constants/storageConstants";

export const sendRefreshToken = async refreshToken => {
  const response = await Axios.put(`${Http.api.common}/${Http.versions.v1}/admin-sessions`, refreshToken);
  StorageService.addValueByKey(SESSION, response.data.data);
  return response;
};

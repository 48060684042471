import React from "react";
import { Card } from "reactstrap";
import ReportInvolved from "./components/reportInvolved";
import SessionDetails from "./components/sessionDetails";
import ReportCase from "./components/reportCase";

export default function ReportCard({ report }) {
  return (
    <Card className="report-card">
      <ReportInvolved report={report} />
      {report.session && <SessionDetails session={report.session} />}
      <ReportCase report={report} />
    </Card>
  );
}

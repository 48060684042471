export const MD = 'md';

// rating stars
export const STAR_RATED_COLOR = '#F1B44C';

export const OFFER = 'Offer';
export const REQUEST = 'Request';

export const ONE = 1;
export const NEGATIVE_ONE = -1;
export const MIN_VALIDATION_PAYOUT = 1;
export const MAX_VALIDATION_PAYOUT = 5000;
export const TEN = 10;

export const STATISTICS = "Statistics"

export const INTERNAL = "Internal";
export const CREDITS = "Credits";

export const UK = 'UK';
export const AUSTRALIA = 'Australia';

export const ADMIN_STORAGE_NAME = 'SweatSearch::user';
export const IS_RECEIVE_EMAILS = 'isReceiveEmails';

export const DEFAULT_REDIRECT = 'Default'
export const BOARDS_REDIRECT = 'TrainerBoards'

export const MINUTE = 'm'

export const FIRST_PLACE_PLACEHOLDER = 150;
export const SECOND_PLACE_PLACEHOLDER = 100;
export const THIRD_PLACE_PLACEHOLDER = 50;

export const DARK_COLOR = "dark";

export const DEFAULT_COLOR_STYLE = 'Default';
export const BOARDS_COLOR_STYLE = 'Boards';
export const TRAINER_BOARDS_COLOR_STYLE = 'Trainer-Boards';
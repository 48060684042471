import Http from './axios-config';

class TuteeManagementService {
  constructor() {
    this.http = new Http();
  }

  getTuteeList(params) {
    return this.http.get('/admin-tutee-management', {
      params,
    });
  }

  getTuteeProfile(id) {
    return this.http.get(`/admin-tutee-management/${id}`);
  }

  blockUnblockTutee(id) {
    return this.http.patch(`/admin-tutee-management/${id}`);
  }

  getTuteeSessions(id, params) {
    return this.http.get(`/admin-tutee-management/${id}/sessions`, {
      params,
    });
  }

  getTuteeBooking(id, params) {
    return this.http.get(`/admin-tutee-management/${id}/bookings`, {
      params,
    });
  }

  getTuteeBookingCount(id) {
    return this.http.get(`/admin-tutee-management/${id}/bookings/count`);
  }

  getTuteeBookingDetails(id, bookingId) {
    return this.http.get(`/admin-tutee-management/${id}/bookings/${bookingId}`);
  }

  deleteTutee(id) {
    return this.http.delete(`/admin-tutee-management/${id}`);
  }
}

export default new TuteeManagementService();

import Http from './axios-config';

class SettingsService {
  constructor() {
    this.http = new Http();
  }

  changePassword(values) {
    return this.http.put('/admin-settings/password', values);
  }

  changeEmailNotification(values) {
    return this.http.patch('/admin-settings/email', values);
  }
}

export default new SettingsService();

import React from "react";

export default function NotificationItem({ title, action, icon, body, date }) {
  return (
    <section className="notification-list-dropdown" onClick={action}>
      <section className="notification-list-dropdown__icon">{icon}</section>
      <section className="notification-list-dropdown--body">
        <span className="notification-list-dropdown--body__title">{title}</span>
        <span className="notification-list-dropdown--body__text">{body}</span>
        <section className="notification-list-dropdown__date">
          <i className="bx bx-time me-2" />
          {date}
        </section>
      </section>
    </section>
  );
}

import React from "react";
import { Card } from "reactstrap";
import getOutputValue from "../../../helpers/getOutputValue";

export default function HealthIssues({ tutee }) {
  return (
    <Card className="tutee-details--issues">
      <span className="tutee-details--issues__title">Underlying health issues</span>
      <p className="tutee-details--issues__description">{getOutputValue(tutee.healthDetails?.healthIssues)}</p>
    </Card>
  );
}

import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER } from "./actionTypes";

import StorageService from "../../../services/StorageService";
import { USER } from "../../../constants/storageConstants";

const initialState = {
  error: "",
  loading: false,
  user: StorageService.getValueByKey(USER),
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        user: action.payload.user,
        session: action.payload.token,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;

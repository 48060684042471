const LINK_TO_TUTEE = `/tutee-management`;
const LINK_TO_TRAINER = `/trainers-management`;
const LINK_TO_REPORTS = `/reports-management`;
const LINK_TO_PAYMENTS = `/payments-management`;
const LINK_TO_SETTINGS = `/settings`;
const LINK_TO_NOTIFICATIONS = `/notifications`;
const LINK_TO_BOARDS = `/boards`;

export const LINKS = {
	BASE: LINK_TO_TUTEE,
    LINK_TO_TUTEE,
	LINK_TO_TRAINER,
	LINK_TO_REPORTS,
	LINK_TO_PAYMENTS,
    LINK_TO_SETTINGS,
    LINK_TO_NOTIFICATIONS,
    LINK_TO_BOARDS
};
export const CARDIO = "Cardio";
export const STRENGTH = "Strength";
export const HIIT = "HIIT";
export const CROSSFIT = "CrossFit";
export const ATHLETESDEVELOPMENT = "Athletes Development";
export const YOGA = "Yoga";
export const RECOVERYREHAB = "Recovery - Rehab";
export const RECOVERYPHYSIO = "Recovery - Physio";
export const PRENATAL = "Prenatal";
export const POSTNATAL = "Postnatal";
export const VIDEOCALLTRAINING = "VideoCall Training";
export const BOXING = "Boxing";

export const TRAINING_COLORS = {
    [CARDIO]: '#34C38F',
    [STRENGTH]: '#F1B44C',
    [HIIT]: '#50A5F1',
    [CROSSFIT]: '#F46A6A',
    [ATHLETESDEVELOPMENT]: '#556EE6',
    [YOGA]: '#55E6E1',
    [RECOVERYREHAB]: '#E655C1',
    [RECOVERYPHYSIO]: '#D9DE0B',
    [PRENATAL]: '#237517',
    [POSTNATAL]: '#7F7F7F',
    [VIDEOCALLTRAINING]: '#926901',
    [BOXING]: '#000080',
};

export const COLORS = [
    '#34C38F', '#F1B44C', '#50A5F1', '#F46A6A', '#556EE6', '#55E6E1', '#E655C1', '#D9DE0B', '#237517', '#7F7F7F', '#926901', '#000080'
];

export const TRAINING_TYPES = [
    [CARDIO],
    [STRENGTH],
    [HIIT],
    [CROSSFIT],
    [ATHLETESDEVELOPMENT],
    [YOGA],
    [RECOVERYREHAB],
    [RECOVERYPHYSIO],
    [PRENATAL],
    [POSTNATAL],
    [VIDEOCALLTRAINING],
    [BOXING],
];
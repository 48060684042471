import React, { useState, useCallback, useEffect } from 'react';
import { Container, Card, CardBody, Col, CardTitle, Row, Spinner } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import BankCard from '../../../components/Common/BankCard';
import ValueList from '../../../components/Common/valueList';
import paymentsManagement from '../../../services/paymentsManagement';
import getNameValue from '../../../helpers/getNameValue';
import getOutputValue from '../../../helpers/getOutputValue';
import formatDate from '../../../helpers/formatDate';
import MetaTags from 'react-meta-tags';
import { POUND } from '../../../constants/defaultConstants';

function transformServerResponse(response) {
  return [
    { label: 'Tutee name : ', value: getNameValue(response.tutee?.firstName, response.tutee?.lastName) },
    { label: 'Payment ID : ', value: response.paymentId },
    { label: 'Purchase date : ', value: formatDate(response.createdAt) },
    { label: 'Purchase type : ', value: getOutputValue(response?.purchaseType) },
    { label: 'Total amount : ', value: `${response?.currencyModel?.symbol ?? POUND}${getOutputValue(response.amount)}` },
    { label: 'Payment method : ', value: getOutputValue(response.card?.paymentMethod) },
    { label: 'Status : ', value: getOutputValue(response.status) },
  ];
}

export default function PaymentDetails({ match }) {
  const { id } = match.params;

  const [details, setDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getPaymentDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data } = await paymentsManagement.getPaymentDetails(id);
      setDetails(data);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    getPaymentDetails();
  }, [getPaymentDetails]);

  return (
    <div className="page-content">
      <MetaTags>
        <title>Payments management | SweatSearch</title>
      </MetaTags>
      <Container fluid>
        <Breadcrumbs
          title="Payments management"
          breadcrumbItem="Transaction details"
          titleLink="/payments-management"
        />
        {isLoading ? (
          <section className="payment-management__spinner">
            <Spinner color="info" />
          </section>
        ) : (
          details && (
            <Row>
              <Col lg={7}>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Transaction details</CardTitle>
                    <ValueList data={transformServerResponse(details)} valueClassname="ps-5" />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5}>
                <BankCard card={details.card} />
              </Col>
            </Row>
          )
        )}
      </Container>
    </div>
  );
}

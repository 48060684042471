import Http from "./axios-config";

class AuthentificationService {
  constructor() {
    this.http = new Http();
  }

  async adminLogin(values) {
    return await this.http.post("/admin-sessions", values);
  }

  async adminLogout() {
    return await this.http.delete("/admin-sessions");
  }

  async adminRestorePassword(values) {
    return await this.http.post(`/admin-verifications/password`, values);
  }

  async adminResetPassword(values) {
    return await this.http.put(`/admin-verifications/password`, values);
  }

  async checkLinkRelevance(values) {
    return await this.http.post(`/admin-verifications/token/password`, values);
  }
}

export default new AuthentificationService();

import React from 'react';
import getFirstLetter from '../../../../helpers/getFirstLetter';
import getNameValue from '../../../../helpers/getNameValue';
import formatDate from '../../../../helpers/formatDate';
import { FULL_DAY_FORMAT, GB_TIME_FORMAT } from '../../../../constants/dateFormats';
import useGoToUsersDetails from '../../../../helpers/hooks/useGoToUsersDetails';
import { POUND } from '../../../../constants/defaultConstants';

export default function Reporter({ report }) {
  const goToDetails = useGoToUsersDetails(report.reporter);
  return (
    <section className="report-card--report-involved--reporter d-flex flex-row me-5">
      {report.reporter.avatar?.compactPath ? (
        <img
          alt="avatar"
          className="report-card--report-involved--reporter__avatar"
          src={report.reporter.avatar.compactPath}
          onClick={goToDetails}
        />
      ) : (
        <span
          className="avatar-title rounded-circle report-card--report-involved--reporter__avatar"
          onClick={goToDetails}
        >
          {getFirstLetter(report.reporter.firstName)}
        </span>
      )}
      <section className="d-flex flex-column">
        <section className="d-flex flex-row justify-content-between align-items-center">
          <span className="report-card--report-involved--reporter__name" onClick={goToDetails}>
            {getNameValue(report.reporter.firstName, report.reporter.lastName)}
          </span>
          <span className="report-card--report-involved--reporter__reporter">Reporter</span>
          {report.session && (
            <section className="report-card--report-involved--reporter__badge">{report.session?.sessionLabel}</section>
          )}
        </section>

        <section className="mt-2">
          <span className="report-card--report-involved--reporter__role">{report.reporter.role}</span>
          <span className="report-card--report-involved--reporter__type">
            {report.session?.sessionSpecializationType?.type}
          </span>
        </section>
        {report.session && (
          <section className="mt-3">
            <span className="report-card--report-involved--reporter__price">
              <i className="bx bx-money report-card--report-involved--reporter__price__icon" />
              {`${report?.currency?.symbol || POUND}${report.session?.price}`}
            </span>
            <span className="report-card--report-involved--reporter__price">
              <i className="bx bx-calendar report-card--report-involved--reporter__price__icon" />
              {formatDate(report.session?.sessionTime?.startDate, FULL_DAY_FORMAT)}
            </span>
            <span className="report-card--report-involved--reporter__price">
              <i className="bx bx-time report-card--report-involved--reporter__price__icon" />
              {formatDate(report.session?.sessionTime?.startDate, GB_TIME_FORMAT)}
            </span>
          </section>
        )}
      </section>
    </section>
  );
}

import React from "react";
import { Badge } from "reactstrap";
import Icon from "../../../components/Common/Icon";
import queryParser from "../../../helpers/queryParser";
import { ORDER_DESC, ORDER_TYPE_KEY } from "../../../constants/paginationConstants";
import getFirstLetter from "../../../helpers/getFirstLetter";
import formatDate from "../../../helpers/formatDate";
import getNameValue from "../../../helpers/getNameValue";
import { GB_FULL_FORMAT } from "../../../constants/dateFormats";

const STATUSES = ["All", "Actioned", "UnActioned"];

const STATUS_BADGES = [
  { label: "All", color: "success" },
  { label: "Actioned", color: "success" },
  { label: "Un-Actioned", color: "danger" },
];

export default function ReportsStructure(location) {
  const query = queryParser(location.search);

  return [
    {
      dataField: "avatar",
      text: "Reporter’s name",
      formatter: (cellContent, user) => {
        return (
          <section className="d-flex flex-row align-items-center">
            {!user.reporter?.avatar ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">{getFirstLetter(user.reporter?.firstName)}</span>
              </div>
            ) : (
              <div>
                <img className="rounded-circle avatar-xs" src={user.reporter.avatar.compactPath} alt="" />
              </div>
            )}
            <span className="tutee-list--list__name">
              {getNameValue(user.reporter?.firstName, user.reporter?.lastName)}
            </span>
          </section>
        );
      },
    },
    {
      dataField: "reporter.role",
      text: "Reporter’s role",
    },
    {
      text: "Reported name",
      dataField: "reported",
      formatter: (cellContent, user) => (
        <span className="tutee-list--list__trainer-name">
          {getNameValue(user.reported?.firstName, user.reported?.lastName)}
        </span>
      ),
    },
    {
      dataField: "reported.role",
      text: "Reported role",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: cellContent => {
        const indexOfStatus = STATUSES.indexOf(cellContent);
        return (
          <Badge className={`badge-soft-${STATUS_BADGES[indexOfStatus]?.color}  tutee-list--list__badge`}>
            {STATUS_BADGES[indexOfStatus]?.label}
          </Badge>
        );
      },
    },
    {
      dataField: "reportTimestamp",
      sort: true,
      text: "",
      headerFormatter: () => (
        <>
          Report timestamp <Icon icon={query[ORDER_TYPE_KEY] === ORDER_DESC ? "sortDESC" : "sortASC"} />
        </>
      ),
      formatter: cellContent => <>{formatDate(cellContent, GB_FULL_FORMAT)}</>,
    },
  ];
}

import React, {useCallback, useState} from "react";
import {Button, Card, Input, Spinner} from "reactstrap";
import classNames from 'classnames';

import DocumentLink from "./documentLink";
import TrainerManagementService from "../../../../../services/trainerManagement";
import toastr from "../../../../../components/toastr";

export default function Quelifications({ qualifications, id }) {
    const [initQualifications, setQualifications] = useState(qualifications || "");
    const [isDataLoadings, setIsDataLoading] = useState(false);
    const [notes, setNote] = useState('');
    const [titles, setTitle] = useState('');

    const handleChange = useCallback(async (event) => {
        try {
            setIsDataLoading(true);

            const formData = new FormData();
            formData.append('file', event.target.files[0]);
            formData.append('fileName', event.target.files[0].name);

            const config = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            };

            const { data } = await TrainerManagementService.addTrainerQualification(id, formData, {
                note: notes,
                title: titles
            }, config);

            setQualifications(oldArray => [...oldArray, data]);
            setIsDataLoading(false);
            setTitle('');
            setNote('');
        } catch (e) {
            setIsDataLoading(false);
        }
    }, [notes, titles])

    const handleDelete = async ({ qid }) => {
        const {data} = await TrainerManagementService.deleteTrainerQualification(id, qid);
        setQualifications(initQualifications.filter(item => item.id !== qid));

        toastr.success(data?.message);
    }

    const onChangeHandlerNote = event => {
        setNote(event.target.value);
    };


    const onChangeHandlerTitle = event => {
        setTitle(event.target.value);
    };


    const disabledBtn = (initQualifications.length === 10) || (notes.length === 0 || titles.length === 0)

    return (
        <Card className="government-id-card">
            <span className="government-id-card__title">Qualification</span>

            {!!initQualifications.length ? (
                initQualifications.map((item, index) => (
                    <DocumentLink link={item?.originalPath} title={item?.title} key={index} description={item?.note} icon="pdf" >
                        <Button color="danger" className="p-1 btn-delete" onClick={() => handleDelete({qid: item.id})}>
                            <i className="bx bx-trash font-size-13"/>
                        </Button>
                    </DocumentLink>
                ))) : <>-</>
            }

            {isDataLoadings &&
                <div className="government-id-card__spinner">
                    <Spinner color="info"/>
                </div>
            }

            <div className={classNames(!disabledBtn ? "custom-uploader" : "custom-uploader--disable")}>
                <input type="file" id="qualifications-btn" hidden onChange={handleChange} disabled={disabledBtn}/>
                <label htmlFor="qualifications-btn" className="">+</label>
            </div>

            <label htmlFor="note">Note</label>
            <Input type="text"  id="note" value={notes} onChange={onChangeHandlerNote} />
            <label htmlFor="title" className="mt-2">Title</label>
            <Input  type="text" id="title" value={titles} onChange={onChangeHandlerTitle} />
        </Card>
    );
}

import React from "react";
import Icon from "../components/Common/Icon";

const VISA = "Visa";
const AMERICAN_EXPRESS = "American Express";
const MASTER_CARD = "MasterCard";

export default {
  [VISA]: <i className="bx bxl-visa visa-logo display-3" />,
  [AMERICAN_EXPRESS]: <Icon icon="americanAxpress" />,
  [MASTER_CARD]: <Icon icon="masterCard" />,
};

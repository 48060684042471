import React, { useState } from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ProfileCard from './profileCard';
import PersonalInfo from './personalInformation';
import HealthIssues from './healthIssueses';
import SessionInfo from './sessionTab';
import TuteeBio from './tuteeBio';
import BookingRequest from './bookingTab';

const FIRST_TAB = 'details';
const SECOND_TAB = 'session';
const THIRD_TAB = 'booking';

export default function DetailsTabs({ tutee, updateTutee }) {
  const [activeTab, setActiveTab] = useState(FIRST_TAB);

  function toggleCustom(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  return (
    <>
      <Card className="tutee-details--tabs">
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem className="tutee-details--tabs__tab">
            <NavLink
              className={classnames({
                active: activeTab === FIRST_TAB,
              })}
              onClick={() => {
                toggleCustom(FIRST_TAB);
              }}
            >
              <span className="d-none d-sm-block tutee-details--tabs__tab__span">Personal info</span>
            </NavLink>
          </NavItem>
          <NavItem className="tutee-details--tabs__tab">
            <NavLink
              className={classnames({
                active: activeTab === SECOND_TAB,
              })}
              onClick={() => {
                toggleCustom(SECOND_TAB);
              }}
            >
              <span className="d-none d-sm-block tutee-details--tabs__tab__span">Sessions info</span>
            </NavLink>
          </NavItem>
          <NavItem className="tutee-details--tabs__tab">
            <NavLink
              className={classnames({
                active: activeTab === THIRD_TAB,
              })}
              onClick={() => {
                toggleCustom(THIRD_TAB);
              }}
            >
              <span className="d-none d-sm-block tutee-details--tabs__tab__span">Booking requests</span>
            </NavLink>
          </NavItem>
        </Nav>
      </Card>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={FIRST_TAB}>
          <Row>
            <Col lg={6}>
              <ProfileCard tutee={tutee} updateTutee={updateTutee} />
              <PersonalInfo tutee={tutee} />
            </Col>
            <Col lg={6}>
              <HealthIssues tutee={tutee} />
              <TuteeBio tutee={tutee} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={SECOND_TAB}>
          <Row>
            <Col sm="12">
              <SessionInfo tutee={tutee} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={THIRD_TAB}>
          <Row>
            <Col sm="12">
              <BookingRequest tutee={tutee} />
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </>
  );
}

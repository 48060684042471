import React, { useState } from 'react';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import PaymentsList from './paymentList';
import SubscriptionsList from '../subscriptionsList/subscriptionList';
import { useHistory } from "react-router-dom";

const FIRST_TAB = 'Payments';
const SECOND_TAB = 'Subscriptions';

export default function PaymentsTabs() {
  const [activeTab, setActiveTab] = useState(FIRST_TAB);
  const history = useHistory();

  function toggleCustom(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
      history.push({ search: "" })
    }
  }

  return (
    <>
      <Card className="tutee-details--tabs">
        <Nav tabs className="nav-tabs-custom nav-justified">
          <NavItem className="tutee-details--tabs__tab">
            <NavLink
              className={classnames({ active: activeTab === FIRST_TAB })}
              onClick={() => toggleCustom(FIRST_TAB)}
            >
              <span className="d-none d-sm-block tutee-details--tabs__tab__span">Payments</span>
            </NavLink>
          </NavItem>
          <NavItem className="tutee-details--tabs__tab">
            <NavLink
              className={classnames({ active: activeTab === SECOND_TAB })}
              onClick={() => toggleCustom(SECOND_TAB)}
            >
              <span className="d-none d-sm-block tutee-details--tabs__tab__span">Subscriptions</span>
            </NavLink>
          </NavItem>
        </Nav>
      </Card>
      <TabContent activeTab={activeTab}>
        {activeTab === FIRST_TAB ?
          (<TabPane tabId={FIRST_TAB}>
            <PaymentsList />
          </TabPane>) :
          (<TabPane tabId={SECOND_TAB}>
            <SubscriptionsList />
          </TabPane>)
        }
      </TabContent>
    </>
  );
}

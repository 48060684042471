import React from 'react';
import { Container } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import BoardsTabs from './tabs';

import { UK } from '../../constants/commonConstants';

const BoardsManagement = (props) => {
  return (
    <>
      <MetaTags>
        <title>Boards management | SweatSearch</title>
      </MetaTags>
      <div className="page-content boards-management">
        <Container fluid>
          <BoardsTabs
            boardLocation={props?.boardLocation || UK} />
        </Container>
      </div>
    </>
  );
};
export default BoardsManagement;

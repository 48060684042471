import React, { useMemo } from 'react';
import getSessionDuration from '../../../../helpers/getSessionDuration';

export default function SessionDetails({ session }) {
  const sessionDetails = useMemo(
    () => [
      { label: 'Session type  ', value: session.sessionType },
      { label: 'Location ', value: session.location?.address },
      {
        label: 'Duration ',
        value: getSessionDuration(session.sessionTime?.startDate, session.sessionTime?.endDate),
      },
      { label: 'Session status', value: session.status },
    ],
    [session]
  );

  return (
    <section className="report-card--session">
      {sessionDetails.map((item, index) => (
        <section className="report-card--session--row row mx-0" key={index}>
          <span className="report-card--session--row__label col-sm-2 my-auto">{item.label}</span>

          <span className="report-card--session--row__delimiter col" />

          <span className="report-card--session--row__value col-sm-9 my-auto">{item.value}</span>
        </section>
      ))}
    </section>
  );
}

export default function getHighlightSearch(string, search) {
  const startIndex = string.toLowerCase().indexOf(search?.toLowerCase() || "");

  if (startIndex < 0) {
    return <span className="payment-management__cell-name ms-0">{string}</span>;
  }

  const queryLength = search?.length;
  return (
    <span className="payment-management__cell-name ms-0">
      {string.split("").map((word, index) => (
        <span className={index >= startIndex && index < startIndex + queryLength ? "highlight-word" : ""} key={index}>
          {word}
        </span>
      ))}
    </span>
  );
}

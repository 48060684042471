import React from 'react';
import formatDate from '../../../../helpers/formatDate';
import { FULL_DATE_FORMAT } from '../../../../constants/dateFormats';
import capitalizeFirstLetter from '../../../../helpers/capitalizeFirstLetter';
import { POUND } from '../../../../constants/defaultConstants';

const SESSION_STATUSES = [
  'Pending',
  'Accepted',
  'Rejected',
  'Done',
  'Expired',
  'Upcoming',
  'Cancelled',
  'PaymentRejected',
];
const SESSION_VALUES = [
  { label: 'pending', color: 'yellow' },
  { label: 'accepted', color: 'yellow' },
  { label: 'rejected', color: 'red' },
  { label: 'done', color: 'green' },
  { label: 'expired', color: 'red' },
  { label: 'upcoming', color: 'yellow' },
  { label: 'cancelled', color: 'red' },
  { label: 'payment rejected', color: 'red' },
];

export default function sessionsListStructure() {
  return [
    {
      dataField: 'trainer',
      text: 'Trainer name',
      formatter: cellContent => {
        return (
          <span className="tutee-list--list__trainer-name">
            {cellContent?.firstName} {cellContent?.lastName}
          </span>
        );
      },
    },
    {
      dataField: 'sessionLabel',
      text: 'Session label',
    },
    {
      text: 'Session price',
      dataField: 'price',
      formatter: (cellContent, params) => {
        return <>{`${params?.currencyModel?.symbol || POUND}${cellContent}`}</>
      },
    },
    {
      dataField: 'sessionSpecializationType',
      text: 'Training type',
      formatter: cellContent => <>{cellContent?.type}</>,
    },
    {
      dataField: 'sessionTime',
      text: 'Date and time',
      formatter: cellContent => <>{formatDate(cellContent?.startDate, FULL_DATE_FORMAT)}</>,
    },
    {
      dataField: 'status',
      text: 'Session statuses',
      formatter: cellContent => {
        const currentStatusIndex = SESSION_STATUSES.indexOf(cellContent);
        return (
          <span className={'tutee-list__' + SESSION_VALUES[currentStatusIndex]?.color}>
            {capitalizeFirstLetter(SESSION_VALUES[currentStatusIndex]?.label)}
          </span>
        );
      },
    },
  ];
}

import React from "react";
import { Card } from "reactstrap";
import getOutputValue from "../../../helpers/getOutputValue";

export default function TuteeBio({ tutee }) {
  return (
    <Card className="tutee-details--issues">
      <span className="tutee-details--issues__title">Biography</span>
      <p className="tutee-details--issues__description">{getOutputValue(tutee.biography)}</p>
    </Card>
  );
}

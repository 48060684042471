import React from "react";
import { Card } from "reactstrap";
import getNotificationDate from "../../helpers/getDateForNotification";
import { ICONS_GENERATOR } from "../../constants/notificationConstants";

export default function Notification({ type, title, text, date, action }) {
  return (
    <Card className="notifications--header notifications--header-hover mb-3" onClick={action}>
      <section className="row align-items-center">
        <section className="col-1 font-size-16 ps-4">{ICONS_GENERATOR[type]()}</section>
        <section className="col-9 d-flex flex-column justify-content-between">
          <span className="notifications__title">{title}</span>
          <span className="notifications__description">{text}</span>
        </section>
        <section className="col-2 notifications__date">{getNotificationDate(date)}</section>
      </section>
    </Card>
  );
}

import React from 'react';
import Icon from '../../../components/Common/Icon';
import queryParser from '../../../helpers/queryParser';
import {
  ORDER_DESC_v2,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  SORT_BY_NEXT_BILLING_DATE,
  SORT_BY_PAYMENT_DATE,
  SORT_BY_NAME,
  ASC_SORT_ICON,
  DESC_SORT_ICON,
} from '../../../constants/paginationConstants';
import formatDate from '../../../helpers/formatDate';
import getNameValue from '../../../helpers/getNameValue';
import { GB_FULL_FORMAT } from '../../../constants/dateFormats';
import getHighlightSearch from '../../../helpers/getHighlightSearch';
import { DASH } from '../../../constants/defaultConstants';
import { CREDITS, INTERNAL } from '../../../constants/commonConstants';

export default function SubscriptionStructure(location) {
  const query = queryParser(location.search);

  const field = query[ORDER_FIELD_KEY];
  const order = query[ORDER_TYPE_KEY];

  const getSortIcon = cellName => (field === cellName && order === ORDER_DESC_v2 ? DESC_SORT_ICON : ASC_SORT_ICON);

  return [
    {
      dataField: 'id',
      text: 'Payment ID',
    },
    {
      dataField: 'startDate',
      sort: true,
      text: '',
      headerFormatter: () => (
        <>
          Payment date <Icon icon={getSortIcon(SORT_BY_PAYMENT_DATE)} />
        </>
      ),
      formatter: cellContent => <>{formatDate(cellContent, GB_FULL_FORMAT)}</>,
    },
    {
      dataField: 'user',
      text: '',
      headerFormatter: () => (
        <>
          Trainer name <Icon icon={getSortIcon(SORT_BY_NAME)} />
        </>
      ),
      sort: true,
      formatter: cellContent => {
        const nameString = getNameValue(cellContent?.firstName, cellContent?.lastName);
        return <>{getHighlightSearch(nameString, query.TrainerName) || DASH}</>;
      },
    },
    {
      text: 'Subscription type',
      dataField: 'subscriptionType',
      formatter: cellContent => {
        return <span>{cellContent === INTERNAL ? CREDITS : cellContent}</span>
      }
    },
    {
      dataField: 'endDate',
      sort: true,
      text: '',
      headerFormatter: () => (
        <>
          Next billing date <Icon icon={getSortIcon(SORT_BY_NEXT_BILLING_DATE)} />
        </>
      ),
      formatter: cellContent => <>{formatDate(cellContent, GB_FULL_FORMAT)}</>,
    },
  ];
}

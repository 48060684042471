import React from "react";

export default function ModalHeader({ text, handleClose }) {
  return (
    <section className="modal--header">
      <span className="modal--header__text">{text}</span>
      <i className="bx bx-x modal--header__icon" onClick={handleClose} />
    </section>
  );
}

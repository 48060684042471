import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'querystring';
import PaymentsManagementService from '../../../services/paymentsManagement';

import Table from '../../../components/Table';
import SubscriptionStructure from './tableStructure';
import SubscriptionFilters from './subscriptionFilters';
import {
  ORDER_FIELD_KEY,
  ORDER_TYPE_KEY,
  REPORTS_LIMIT,
  SUBSCRIPTION_SORT_TYPES,
} from '../../../constants/paginationConstants';
import queryParser from '../../../helpers/queryParser';
import { SORT_ACTION_TYPE } from '../../../constants/tableConstants';

const SubscriptionsList = () => {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsPagination, setSubscriptionsPagination] = useState({});
  const [isFirstRequest, setIsFirstRequest] = useState(true);

  const history = useHistory();

  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getSubscriptions = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...query,
        Limit: REPORTS_LIMIT,
      };
      const { data, pagination } = await PaymentsManagementService.getSubscriptions(newQuery);

      setSubscriptions(data);
      setSubscriptionsPagination(pagination);
    } finally {
      setIsDataLoading(false);
    }
  }, [query]);

  const handleTableChange = async (type, { page, searchText, sortField, sortOrder }) => {
    const search = searchText.trim();
    const params = {
      ...query,
      offset: REPORTS_LIMIT * (page - 1),
      TrainerName: search,
    };
    if (isFirstRequest && !!search) {
      params.offset = 0;
      setIsFirstRequest(false);
    }
    if (!search) {
      setIsFirstRequest(true);
    }
    if (type === SORT_ACTION_TYPE) {
      params[ORDER_TYPE_KEY] = sortOrder;
      params[ORDER_FIELD_KEY] = SUBSCRIPTION_SORT_TYPES[sortField];
    }
    history.push({
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  return (
    <Table
      dataStructure={SubscriptionStructure}
      data={subscriptions}
      FiltersComponent={SubscriptionFilters}
      handleTableChange={handleTableChange}
      limit={REPORTS_LIMIT}
      totalCount={subscriptionsPagination?.totalCount}
      offset={query.offset || 0}
      loading={isDataLoading}
      searchField="TrainerName"
      isRowClick={false}
    />
  );
};

export default SubscriptionsList;

import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row
} from 'reactstrap';
import classnames from 'classnames';
import {
  SESSIONS_LIMIT
} from "../../constants/paginationConstants";
import {
  NOVICEBOARD,
  LEADERBOARD,
  SPEEDBOARD,
  MIN_DATE,
} from "../../constants/boardsConstants"
import React, { useState, useEffect, useMemo, useCallback } from "react";
import Table from "../../components/Table";
import NotFinishNotPercentTableStructure from "./tableStructure/notFinishNotPercentTableStructure";
import FinishNotPercentTableStructure from "./tableStructure/finishNotPercentTableStructure";
import FinishTableStructure from "./tableStructure/finishTableStructure";
import NotFinishTableStructure from "./tableStructure/notFinishTableStructure";
import BoardsManagementService from "../../services/boardsManagement";
import { useHistory, useLocation } from "react-router-dom";
import qs from "querystring";
import queryParser from "../../helpers/queryParser";
import BoardsHeader from "./header/boardsHeader";
import formatDate from "../../helpers/formatDate";
import {
  BOARDS_DATE_FORMAT,
  STATISTICS_YEAR_FORMAT
} from "../../constants/dateFormats";
import {
  STATISTICS,
  BOARDS_COLOR_STYLE
} from "../../constants/commonConstants";
import StatisticsTableStructure from "./statistics/tableStructure";
import Placeholder from '../../components/Table/placeholder';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { spinnerStyles } from '../../components/Table/spinnerStyles';
import { TRAINING_COLORS } from '../../constants/statisticColorsConstants';
import PieChart from '../../components/Charts/pieChart';
import StatisticsHeader from './header/statisticsHeader';
import statisticTypes from '../../constants/statisticTypes';

const dataModels = {
  [NOVICEBOARD]: { [true]: NotFinishNotPercentTableStructure, [false]: FinishNotPercentTableStructure },
  [LEADERBOARD]: { [true]: NotFinishNotPercentTableStructure, [false]: FinishNotPercentTableStructure },
  [SPEEDBOARD]: { [true]: NotFinishTableStructure, [false]: FinishTableStructure },
}

export default function BoardsTabs({ boardLocation }) {
  const history = useHistory();
  const location = useLocation();
  const { BoardId = null, ...params } = useMemo(() => queryParser(location.search), [location]);

  const [activeTab, setActiveTab] = useState(params?.BoardType || NOVICEBOARD);
  const [isNoviceBoardActive, setIsNoviceBoardActive] = useState(true);
  const [isLeaderBoardActive, setIsLeaderBoardActive] = useState(true);
  const [isSpeedBoardActive, setIsSpeedBoardActive] = useState(true);
  const [isFirstRequest, setIsFirstRequest] = useState(true);

  const [boards, setBoards] = useState([]);
  const [boardsPagination, setBoardsPagination] = useState({});
  const [iterator, setIterator] = useState(0);
  const [isRightDisabled, setIsRightDisabled] = useState(true);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [date, setDate] = useState('');

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [boardRows, setBoardRows] = useState([]);
  const [boardRowsPagination, setBoardRowsPagination] = useState({});

  const [today, setToday] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [statisticsActiveTab, setStatisticsActiveTab] = useState(statisticTypes.month);
  const [statistics, setStatistics] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartColors, setChartColors] = useState([]);

  const links = [
    { tab: NOVICEBOARD, disabled: !isNoviceBoardActive },
    { tab: LEADERBOARD, disabled: !isLeaderBoardActive },
    { tab: SPEEDBOARD, disabled: !isSpeedBoardActive },
    { tab: STATISTICS, disabled: false },
  ]

  function toggleCustom(tab) {
    if (activeTab !== tab) {
      setIsDataLoading(false);
      setIterator(0);
      setIsRightDisabled(true);
      setIsLeftDisabled(true);
      setActiveTab(tab);
      setBoardRows([]);
      setBoards([]);
      setBoardRowsPagination({});
      setBoardsPagination({});
      setIsFirstRequest(true);
      setDate('');
      setStatistics([]);
      setToday(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
      setStatisticsActiveTab(statisticTypes.month);
      if (tab === STATISTICS) {
        history.push({
          search: qs.stringify({
            Period: statisticsActiveTab,
            Date: formatDate(today),
          })
        });
      } else {
        history.push({
          search: qs.stringify({
            ...params,
            BoardType: tab,
            offset: 0,
            boardLocation: boardLocation,
          })
        });
      }
    }
  }

  const getBoardsInfo = useCallback(async (boardsOffset = 0) => {
    if (BoardId || activeTab === STATISTICS) {
      return;
    }
    setIsDataLoading(true);
    try {
      const newQuery = {
        Limit: 1,
        BoardType: activeTab,
        boardLocation: boardLocation,
        Offset: boardsOffset,
      }
      const { data, pagination } = await BoardsManagementService.getBoards(newQuery);
      setBoards(data);
      setBoardsPagination(pagination);
      if (pagination.totalCount > 0) {
        setDate(formatDate(data[iterator]?.startAt, BOARDS_DATE_FORMAT));
        if (pagination?.totalCount !== pagination?.nextOffset) {
          setIsLeftDisabled(false);
        }
        if (boardRowsPagination?.totalCount !== 0) {
          handleSubmitBoardId(data[iterator]?.id);
          await getBoardRowsInfo(data[iterator]?.id);
        }
        else {
          await getBoardRowsInfo();
        }
      } else {
        setBoardRows([]);
        setBoardRowsPagination({});
        setIsDataLoading(false);
      }
    } finally {
    }
  }, [activeTab, boardLocation]);

  useEffect(() => {
    getBoardsInfo(iterator);
  }, [getBoardsInfo, iterator]);

  const getBoardRowsInfo = useCallback(async (boardId = null) => {
    if (!BoardId && !boardId) {
      setIsDataLoading(false);
      return;
    }
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...params,
        Limit: SESSIONS_LIMIT,
        BoardType: activeTab,
        BoardId: BoardId || boardId,
        boardLocation: boardLocation,
      };
      const { data, pagination } = await BoardsManagementService.getBoardRows(newQuery);
      setBoardRows(data.boardRows);
      setBoardRowsPagination(pagination);
    } catch {
      setBoardRows([]);
      setBoardRowsPagination({});
    } finally {
      setIsDataLoading(false);
    }
  }, [params.offset, activeTab, BoardId, boardLocation]);

  const handleTableChange = async (type, { page, search }) => {
    const changeParams = {
      ...params,
      BoardId: BoardId,
      boardLocation: boardLocation,
      offset: SESSIONS_LIMIT * (page - 1),
    };
    if (isFirstRequest && !!search) {
      changeParams.offset = 0;
      setIsFirstRequest(false);
    }
    history.push({
      search: qs.stringify(changeParams),
    });
  };

  useEffect(() => {
    getBoardRowsInfo();
  }, [getBoardRowsInfo]);

  useEffect(() => {
    handleSubmitBoardId(boards[iterator]?.id);

    if (iterator > 0) {
      setIsRightDisabled(false);
      if (iterator >= boardsPagination?.totalCount - 1) {
        setIsLeftDisabled(true);
      }
    } else if (iterator === 0) {
      setIsRightDisabled(true);
    }
  }, [iterator]);

  function changeBoardId(selectedIterator) {
    setIterator(prevState => prevState + selectedIterator);
  }

  function handleSubmitBoardId(id) {
    if (id) {
      history.push({
        search: qs.stringify({
          ...params,
          BoardId: id,
        }),
      });
    }
  }

  const getStatistics = useCallback(async () => {
    if (activeTab !== STATISTICS) {
      return;
    }
    setIsDataLoading(true);
    try {
      const newQuery = {
        Period: statisticsActiveTab,
        Date: formatDate(today),
      }
      const { data } = await BoardsManagementService.getStatistics(newQuery);
      setStatistics(data.statistics);
      setChartData(data.statistics.map(function (item) { return item.percent }));
      setChartLabels(data.statistics.map(function (item) { return item.specialization.type.trim() }));
      setChartColors(data.statistics.map(function (item) { return TRAINING_COLORS[item.specialization.type.trim()] }));
    } finally {
      setIsDataLoading(false);
    }
  }, [activeTab, today, statisticsActiveTab]);

  function changeDateFilter(selectedIterator, activeFilter) {
    if (activeFilter === statisticTypes.month) {
      setToday(prevState => new Date(prevState.setMonth(prevState.getMonth() + selectedIterator)));
    } else if (activeFilter === statisticTypes.year) {
      setToday(prevState => new Date(prevState.setFullYear(prevState.getFullYear() + selectedIterator)));
    }
  }

  function changeStatisticsFilter(activeFilter) {
    setStatisticsActiveTab(activeFilter);
  }

  useEffect(() => {
    getStatistics();
  }, [getStatistics, activeTab]);

  useEffect(() => {
    if (activeTab === STATISTICS) {
      if (today.getFullYear() >= new Date().getFullYear() && (statisticsActiveTab !== statisticTypes.month || today.getMonth() >= new Date().getMonth())) {
        setIsRightDisabled(true);
      } else {
        setIsRightDisabled(false);
      }

      if (today.getFullYear() <= MIN_DATE.getFullYear() && (statisticsActiveTab !== statisticTypes.month || today.getMonth() <= MIN_DATE.getMonth())) {
        setIsLeftDisabled(true);
      } else {
        setIsLeftDisabled(false);
      }
    }
  }, [today, statisticsActiveTab]);

  const handleStatisticsTableChange = useCallback(() => {
    const oldParams = useMemo(() => queryParser(location.search), [location]);

    const changeParams = {
      ...oldParams,
      Date: today,
      Period: statisticsActiveTab,
    };
    history.push({
      search: qs.stringify(changeParams),
    });
  }, [today, statisticsActiveTab]);

  return (
    <>
      <Card className="tutee-details--tabs">
        <Nav tabs className="nav-tabs-custom nav-justified">
          {
            links.map(({ tab, disabled }) => (
              <NavItem className="tutee-details--tabs__tab">
                <NavLink
                  className={classnames({ active: activeTab === tab })}
                  onClick={() => toggleCustom(tab)}
                  disabled={disabled}
                >
                  <span className="d-none d-sm-block tutee-details--tabs__tab__span">{tab}</span>
                </NavLink>
              </NavItem>
            ))
          }
        </Nav>
      </Card>
      {activeTab === STATISTICS ?
        (
          <Row className="bg-white">
            <StatisticsHeader
              date={formatDate(today, (statisticsActiveTab === statisticTypes.month ? BOARDS_DATE_FORMAT : STATISTICS_YEAR_FORMAT))}
              isLeftDisabled={isLeftDisabled}
              isRightDisabled={isRightDisabled}
              changeDateFilter={changeDateFilter}
              changeFilter={changeStatisticsFilter}
              activeFilter={statisticsActiveTab}
            />
            <Col>
              <ToolkitProvider
                keyField="id"
                data={statistics || []}
                columns={StatisticsTableStructure(location)}
                bootstrap4
              >
                {toolkitProps => (
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          responsive
                          remote
                          bordered={false}
                          striped={false}
                          loading={isDataLoading}
                          overlay={overlayFactory({
                            spinner: true,
                            styles: spinnerStyles,
                          })}
                          noDataIndication={() => <Placeholder />}
                          classes={'table align-middle table-nowrap table-hover no-row-click-table'}
                          headerWrapperClasses={'table-light'}
                          {...toolkitProps.baseProps}
                          onTableChange={null}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </ToolkitProvider>
            </Col>
            <Col>
              <PieChart
                labels={chartLabels}
                data={chartData}
                backgroundColors={chartColors}
              />
            </Col>
          </Row>
        )
        :
        (<Table
          dataStructure={dataModels[activeTab][!boards[0]?.isFinished ?? true]}
          data={boardRows}
          FiltersComponent={() => <BoardsHeader
            tabName={activeTab}
            date={date}
            isRightDisabled={isRightDisabled}
            isLeftDisabled={isLeftDisabled}
            changeFilter={changeBoardId}
            board={boards[0]}
            updateBoard={() => getBoardsInfo(iterator)}
            itemCount={boardRowsPagination.totalCount || 0}
          />}
          handleTableChange={handleTableChange}
          limit={SESSIONS_LIMIT}
          totalCount={boardRowsPagination.totalCount || 0}
          offset={params.offset || 0}
          loading={isDataLoading}
          withLocation={false}
          customRowElement={'user'}
          rowClickPath={'/trainers-management'}
          boardStyleType={BOARDS_COLOR_STYLE}
        />)
      }
    </>
  );
}
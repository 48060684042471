import React from "react";
import Icon from "../components/Common/Icon";

export const RECIEVE_REPORT = "ReceiveReport";
export const CANCEL_SESSION = "ThreeCancel";
export const MANY_REPORTS = "FiveReports";
export const UPDATE_PROFILE = "UpdateProfile";
export const BOARDS_RESULTS = "BoardResults";
export const BOARDS_END_RESULTS = "BoardEndResults";

export const UPDATED_COUNTER = "notifications_count";

export const MAX_NOTIFICATIONS = 10;
export const MAX_NOTIFICATION_DROPDOWN = 3;

export const NEW_BOARD_ROLL_POPAPP = "The board has been successfully finished.";

export const ICONS_GENERATOR = {
  [RECIEVE_REPORT]: () => (
    <div className="avatar-sm">
      <span className="avatar-title  rounded-circle">
        <Icon icon="recieveReport" />
      </span>
    </div>
  ),
  [CANCEL_SESSION]: () => (
    <div className="avatar-sm">
      <span className="avatar-title rounded-circle bg-danger">
        <Icon icon="cancelledIcon" />
      </span>
    </div>
  ),
  [MANY_REPORTS]: () => (
    <div className="avatar-sm">
      <span className="avatar-title rounded-circle bg-warning">
        <Icon icon="manyReports" />
      </span>
    </div>
  ),
  [UPDATE_PROFILE]: () => (
    <div className="avatar-sm">
      <span className="avatar-title rounded-circle bg-soft-primary">
        <Icon icon="updateProfile" />
      </span>
    </div>
  ),
  [BOARDS_RESULTS]: () => (
    <div className="avatar-sm">
      <span className="avatar-title rounded-circle bg-success">
        <Icon icon="board" />
      </span>
    </div>
  ),
  [BOARDS_END_RESULTS]: () => (
    <div className="avatar-sm">
      <span className="avatar-title rounded-circle bg-danger">
        <Icon icon="board" />
      </span>
    </div>
  ),
};

export function setActionToNotification(notification, history) {
  const newNotification = {
    ...notification,
  };

  if (notification.type === RECIEVE_REPORT) {
    newNotification.action = () => history.push(`/reports-management/${notification.actionId}`);
    return newNotification;
  }

  if (notification.type === CANCEL_SESSION) {
    newNotification.action = () => history.push(`/trainers-management/${notification.actionId}`);
    return newNotification;
  }

  if (notification.type === MANY_REPORTS) {
    newNotification.action = () => history.push(`/reports-management`);
    return newNotification;
  }

  if (notification.type === UPDATE_PROFILE) {
    newNotification.action = () => history.push(`/trainers-management/${notification.actionId}`);
    return newNotification;
  }

  if (notification.type === BOARDS_RESULTS || notification.type === BOARDS_END_RESULTS) {
    newNotification.action = () => history.push(`/boards?BoardId=${notification.actionId}&BoardType=${notification?.boardType}`);
    return newNotification;
  }

  return newNotification;
}

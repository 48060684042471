import {useCallback, useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";

import queryParser from "../../../helpers/queryParser";
import StorageService from "../../../services/StorageService";
import { USER_ID, USER_STRIPE} from "../../../constants/storageConstants";
import TrainerManagementService from "../../../services/trainerManagement";

const TrainerAuth = () => {
    const id = StorageService.getValueByKey(USER_ID) || {};
    const location = useLocation();
    const query = useMemo(() => queryParser(location.search), [location]);

    const getAccessToken = useCallback(async () => {
        try {
            const { data } = await TrainerManagementService.addStripeAccountToTrainer(id, query.code);

            StorageService.addValueByKey(USER_STRIPE, data);

            window.close()
        } finally {
           //empty
        }
    }, []);

    useEffect(() => {
        getAccessToken();
    }, [getAccessToken]);

    return null;
};

export default TrainerAuth;

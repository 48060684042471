import React, { useRef, useState } from "react";
import leftArrow from "../../../assets/images/icons/left.svg";
import rightArrow from "../../../assets/images/icons/right.svg";
import leftArrowGrey from "../../../assets/images/icons/left-grey.svg";
import rightArrowGrey from "../../../assets/images/icons/right-grey.svg";
import {
  ONE,
  NEGATIVE_ONE,
  TEN,
  MAX_VALIDATION_PAYOUT,
  MIN_VALIDATION_PAYOUT,
  MINUTE,
  THIRD_PLACE_PLACEHOLDER,
  SECOND_PLACE_PLACEHOLDER,
  FIRST_PLACE_PLACEHOLDER,
  DARK_COLOR,
} from "../../../constants/commonConstants";
import moment from "moment";
import { Button } from "reactstrap";
import { Modal } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { MD } from '../../../constants/commonConstants'
import ModalHeader from "../../../components/Modal/modalHeader";
import TwoModalButtons from '../../../components/Modal/twoModalButtons';
import BoardsManagementService from '../../../services/boardsManagement'
import toastr from "../../../components/toastr";
import { NEW_BOARD_ROLL_POPAPP } from "../../../constants/notificationConstants";
import { LEADERBOARD } from "../../../constants/boardsConstants"

const defaultValues = {
  firstPlace: 150,
  secondPlace: 100,
  thirdPlace: 50,
};

const leaderBoardDefaultValues = {
  firstPlace: 1500,
  secondPlace: 1000,
  thirdPlace: 500,
};

export default function BoardsHeader({ tabName, date, isRightDisabled, isLeftDisabled, changeFilter, updateBoard, board, itemCount }) {
  const isDisabled = (new Date() >= moment(Date.parse(board?.endAt)).add(TEN, MINUTE).toDate() && (!board?.isFinished) && (itemCount > 0));
  const [showConfirmPayout, setShowConfirmPayout] = useState(false);
  const formRef = useRef();

  async function handleConfirmPayOut(values) {
    try {
      const value = {
        firstPlace: Number(values.firstPlace) || 0,
        secondPlace: Number(values.secondPlace) || 0,
        thirdPlace: Number(values.thirdPlace) || 0,
      };

      await BoardsManagementService.ConfirmPayOut(board?.id, value);
      toastr.success(NEW_BOARD_ROLL_POPAPP);
      await updateBoard();
    } finally {
      setShowConfirmPayout(false);
    }
  }

  return (
    <section className="d-flex flex-row justify-content-between">
      <span className="trainers-list__header">Trainer {tabName}</span>

      <section className="align-items-center">
        {(isLeftDisabled && isRightDisabled) ? (
          <span className="date">
            {date}
          </span>
        ) : (<>
          {!isLeftDisabled ? (
            <img className="arrow" src={leftArrow} alt="left-arrow" onClick={() => changeFilter(ONE)} />
          ) : (
            <img className="arrow disable" src={leftArrowGrey} alt="left-arrow" />
          )}
          <span className="date">
            {date}
          </span>
          {!isRightDisabled ? (
            <img className="arrow" src={rightArrow} alt="right-arrow" onClick={() => changeFilter(NEGATIVE_ONE)} />
          ) : (
            <img className="arrow disable" src={rightArrowGrey} alt="right-arrow" />
          )}
        </>)}
      </section>

      <section>
        <Button
          onClick={() => setShowConfirmPayout(true)}
          color="primary"
          className={`btn-rounded btn-primary btn ${!isDisabled ? 'disabled' : ''}`}
        >
          Finish the roll
        </Button>
      </section>

      <Modal isOpen={showConfirmPayout} centered size={MD} toggle={() => setShowConfirmPayout(false)}>
        <section className="confirm-modal d-flex flex-column">
          <ModalHeader text="Set prizes for the winners" handleClose={() => setShowConfirmPayout(false)} />

          <AvForm
            ref={formRef}
            className="form-horizontal"
            onValidSubmit={(event, values) => { handleConfirmPayOut(values) }}
            errorMessage="Prize must be from 1 to 5000"
            model={board?.boardType === LEADERBOARD ? leaderBoardDefaultValues : defaultValues}
          >
            <AvField
              name="firstPlace"
              label="1st place"
              type="number"
              required
              placeholder={FIRST_PLACE_PLACEHOLDER}
              className="reject-input-margin"
              min={MIN_VALIDATION_PAYOUT}
              max={MAX_VALIDATION_PAYOUT}
            />
            <AvField
              name="secondPlace"
              label="2nd place"
              type="number"
              required
              placeholder={SECOND_PLACE_PLACEHOLDER}
              className="reject-input-margin"
              min={MIN_VALIDATION_PAYOUT}
              max={MAX_VALIDATION_PAYOUT}
            />
            <AvField
              name="thirdPlace"
              label="3rd place"
              type="number"
              required
              placeholder={THIRD_PLACE_PLACEHOLDER}
              className="reject-input-margin"
              min={MIN_VALIDATION_PAYOUT}
              max={MAX_VALIDATION_PAYOUT}
            />

            <TwoModalButtons
              firstText="Pay out"
              secondText="Cancel"
              firstAction={() => { }}
              secondAction={() => { setShowConfirmPayout(false) }}
              secondColor={DARK_COLOR}
            />
          </AvForm>

        </section>
      </Modal>
    </section>
  );
}
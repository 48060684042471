import PropTypes from 'prop-types';
import MetaTags from 'react-meta-tags';
import React, { useMemo, useState } from 'react';

import { Row, Col, CardBody, Card, Container } from 'reactstrap';
import { EMAIL_MAX_LENGTH } from '../../constants/lengthValidity';
// Redux
import { connect } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser } from '../../store/actions';
import ERRORS from '../../constants/errorMessages';
// import images
import profile from '../../assets/images/profile-img.png';
import logo from '../../assets/images/icons/logo_swearsearch.svg';

const Login = ({ loginUser }) => {
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const emailValidationRules = useMemo(
    () => ({
      required: { value: true, errorMessage: ERRORS.emailRequired },
      email: { value: true, errorMessage: ERRORS.emailInvalid },
      maxLength: { value: EMAIL_MAX_LENGTH, errorMessage: ERRORS.emailInvalid },
    }),
    []
  );

  const passwordValidationRules = useMemo(
    () => ({
      required: { value: true, errorMessage: ERRORS.passwordRequired },
    }),
    []
  );

  const handleValidSubmit = values => {
    try {
      setIsLoading(true);
      loginUser(values, history, setIsLoading);
    } catch {
      //empty
    }
  };

  return (
    <>
      <MetaTags>
        <title>Login | SweatSearch</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages login-page">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden login-page--main-card">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4 pe-0">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to SweatSearch</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4">
                      <span className="avatar-title rounded-circle bg-light">
                        <img src={logo} alt="" height="34" />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(event, values) => {
                        handleValidSubmit(values);
                      }}
                    >
                      <div className="login-page--main-card__email-input">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          validate={emailValidationRules}
                        />
                      </div>

                      <div className="login-page--main-card__form-element">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter password"
                          validate={passwordValidationRules}
                        />
                      </div>

                      <div className="d-grid login-page--main-card__login-button">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                          disabled={isLoading}
                        >
                          Log in
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link
                          to="/forgot-password"
                          className="text-muted d-flex flex-row align-items-center justify-content-center"
                        >
                          <i className="mdi mdi-lock me-1 login-page--main-card__lock-icon" />
                          Restore password
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { error } = state.Login;
  return { error };
};

export default connect(mapStateToProps, { loginUser })(Login);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

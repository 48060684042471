import moment from "moment";
import { NOTIFICATION_DATE_FORMAT } from "../constants/dateFormats";

const ONE_DAY = 1;

export default function getNotificationDate(date) {
  if (moment(date).add(ONE_DAY, "day") > moment()) {
    return moment(date).fromNow();
  }
  return moment(date).format(NOTIFICATION_DATE_FORMAT);
}

import React, { useState } from "react";
import { Card, Nav, NavItem, NavLink } from "reactstrap";
import { Redirect, Route, Switch, useHistory, useLocation, withRouter } from "react-router-dom";
import classnames from "classnames";
import { TRAINER_TABS, TABS_CONTENT, TRAINER_INFO_TAB } from "../../../constants/tabsConstants";

function Tabs({ trainer, updateTrainer, match }) {

  const [activeTab, setActiveTab] = useState(TRAINER_INFO_TAB);

  const history = useHistory();
  const location = useLocation();

  function toggleCustom(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab.id);
      history.push(match.url + tab.link);
    }
  }

  return (
    <>
      <Card className="trainer-details--tabs">
        <Nav tabs className="nav-tabs-custom nav-justified">
          {TRAINER_TABS.map(item => (
            <NavItem className="trainer-details--tabs__tab" key={item.id}>
              <NavLink
                className={classnames({
                  active: location.pathname === match.url + item.link,
                })}
                onClick={() => {
                  toggleCustom(item);
                }}
              >
                <span className="d-none d-sm-block trainer-details--tabs__tab__span">{item.label}</span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      </Card>

      <Switch>
        {TABS_CONTENT.map(({ id, Component, link }) => (
          <Route
            key={id}
            path={match.url + link}
            render={(props) => <Component trainer={trainer} updateTrainer={updateTrainer} {...props} {...match}/>}
            exact
          />
        ))}
        <Redirect from="*" to={match.url} />
      </Switch>
    </>
  );
}

export default withRouter(Tabs);

import Http from "./axios-config";

class BoardsManagementService {
  constructor() {
    this.http = new Http();
  }

  async getBoards(params) {
    return await this.http.get("/admin-boards-management/all", {
      params,
    });
  }

  async getBoardRows(params) {
    return await this.http.get("/boards", {
      params,
      disableToast: true
    });
  }

  async getStatistics(params) {
    return await this.http.get("admin-statistics-management", {
      params,
    });
  }

  async ConfirmPayOut(boardId, params) {
    return await this.http.post(`/admin-boards-management/${boardId}`, params);
  }
}

export default new BoardsManagementService();

import { TRAINING_COLORS } from '../../../constants/statisticColorsConstants';

export default function StatisticsTableStructure() {

    return [
        {
            dataField: 'specialization.type',
            text: 'Color',
            formatter: cellContent => {
                return (
                    <div className="avatar-xxs">
                        <span className="rounded-circle avatar-title-without-color" style={{ backgroundColor: `${TRAINING_COLORS[cellContent.trim()]}` }}></span>
                    </div>
                );
            },
        },
        {
            dataField: "specialization.type",
            text: "Specialization title",
        },
        {
            dataField: 'percent',
            text: 'Percentage',
            formatter: cellContent => {
                return (
                    <span>{cellContent}%</span>
                );
            },
        },
    ];
}

import DetailsInfoTab from "../pages/TrainersManagement/trainerDetails/tabContent/info";
import TrainerDocs from "../pages/TrainersManagement/trainerDetails/tabContent/docs";
import Reviews from "../pages/TrainersManagement/trainerDetails/tabContent/reviews";
import Earning from "../pages/TrainersManagement/trainerDetails/tabContent/earning";
import Offers from "../pages/TrainersManagement/trainerDetails/tabContent/offers";
import Reports from "../pages/TrainersManagement/trainerDetails/tabContent/reports";
import SessionInfo from "../pages/TrainersManagement/trainerDetails/tabContent/sessionInfo";
import TrainerSessionDetails from '../pages/TrainersManagement/trainerDetails/tabContent/SessionDetails/sessionDetails'
import Boards from '../pages/TrainersManagement/trainerDetails/tabContent/boards'

export const TRAINING_SESSION = "training-session";
export const TRAINING_TIMESLOT = "training-timeslot";

export const TRAINER_INFO_TAB = "info";
export const TRAINER_DOCUMENTS_TAB = "docs";
export const TRAINER_OFFERS_TAB = "offers";
export const TRAINER_REVIEW_TAB = "reviews";
export const TRAINER_REPORTS_TAB = "reports";
export const SIXTH_TAB = "earningStatistics";
export const TRAINER_SESSIONS_TAB = "sessionInfo";
export const TRAINER_BOARDS_TAB = "boards";

export const TRAINER_TABS = [
  { label: "Personal info", id: TRAINER_INFO_TAB, link: "" },
  { label: "Trainer docs", id: TRAINER_DOCUMENTS_TAB, link: "/documents" },
  { label: "Offers", id: TRAINER_OFFERS_TAB, link: "/offers" },
  { label: "Reviews", id: TRAINER_REVIEW_TAB, link: "/reviews" },
  { label: "Reports", id: TRAINER_REPORTS_TAB, link: "/reports" },
  { label: "Earning statistics", id: SIXTH_TAB, link: "/earning-statistics" },
  { label: "Session info", id: TRAINER_SESSIONS_TAB, link: "/training" },
  { label: "Boards", id: TRAINER_BOARDS_TAB, link: "/trainer-boards" },
];

export const TABS_CONTENT = [
  { Component: DetailsInfoTab, id: TRAINER_INFO_TAB, link: "/" },
  { Component: TrainerDocs, id: TRAINER_DOCUMENTS_TAB, link: "/documents" },
  { Component: Offers, id: TRAINER_OFFERS_TAB, link: "/offers" },
  { Component: Reviews, id: TRAINER_REVIEW_TAB, link: "/reviews" },
  { Component: Reports, id: TRAINER_REPORTS_TAB, link: "/reports" },
  { Component: Earning, id: SIXTH_TAB, link: "/earning-statistics" },
  { Component: SessionInfo, id: TRAINER_SESSIONS_TAB, link: "/training" },
  { Component: TrainerSessionDetails, id: TRAINER_SESSIONS_TAB, link: `/${TRAINING_SESSION}/:sessionId` },
  { Component: TrainerSessionDetails, id: TRAINER_SESSIONS_TAB, link: `/${TRAINING_TIMESLOT}/:sessionId` },
  { Component: Boards, id: TRAINER_BOARDS_TAB, link: "/trainer-boards" },
];
import formatDate from '../../../../helpers/formatDate';
import { DEFAULT_DATE_FORMAT } from '../../../../constants/dateFormats';
import { DASH } from '../../../../constants/defaultConstants';

const SESSION_STATUSES = [
  'Pending',
  'Accepted',
  'Rejected',
  'Done',
  'Expired',
  'Upcoming',
  'Cancelled',
  'PaymentRejected',
];
const SESSION_VALUES = [
  { label: 'pending', color: 'yellow' },
  { label: 'accepted', color: 'yellow' },
  { label: 'rejected', color: 'red' },
  { label: 'done', color: 'green' },
  { label: 'expired', color: 'red' },
  { label: 'upcoming', color: 'yellow' },
  { label: 'cancelled', color: 'red' },
  { label: 'payment rejected', color: 'red' },
];

export default function bookingListStructure() {
  return [
    {
      dataField: 'trainer',
      text: 'Trainer name',
      formatter: cellContent => {
        return (
          <span className="tutee-list--list__trainer-name">
            {cellContent?.firstName} {cellContent?.lastName}
          </span>
        );
      },
    },
    {
      dataField: 'sessionType',
      text: 'Session label',
    },
    {
      text: 'Session type',
      dataField: 'trainingType',
      formatter: cellContent => <>{cellContent}</>,
    },
    {
      dataField: 'offerSpecialization',
      text: 'Training type',
      formatter: cellContent => <>{cellContent?.type || DASH}</>,
    },
    {
      dataField: 'dateOfTraining',
      text: 'Date of training',
      formatter: cellContent => <>{formatDate(cellContent, DEFAULT_DATE_FORMAT)}</>,
    },
  ];
}

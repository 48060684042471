import React, { useState, useCallback, useEffect } from 'react';
import TrainerManagementService from '../../../../services/trainerManagement';
import statisticTypes from '../../../../constants/statisticTypes';
import ReactApexChart from 'react-apexcharts';
import { Card, Spinner } from 'reactstrap';
import Placeholder from '../../../../components/Table/placeholder';
import { earningStatisticOptions, APEX_TYPE, APEX_HEIGHT } from '../../../../constants/apexOptions';
import { POUND } from '../../../../constants/defaultConstants';

function getActiveBtnClass(current, expected) {
  return `btn ${current === expected
    ? 'btn-primary earning-card__filter-button'
    : 'btn-outline-primary earning-card__filter-button-secondary'
    } w-sm`;
}

const FILTER_BUTTONS = [
  { label: 'Month', filter: statisticTypes.month },
  { label: 'Year', filter: statisticTypes.year },
  { label: 'Week', filter: statisticTypes.week },
];

export default function Earning({ trainer }) {
  const [graphicData, setGraphicData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [graphicOptions, setGraphicOptions] = useState(earningStatisticOptions({ currency: POUND }));
  const [activeFilter, setActiveFilter] = useState(statisticTypes.month);
  const [totalAmout, setTotalAmout] = useState(null);
  const [currency, setCurrency] = useState(null);

  const getEarningStatistic = useCallback(async () => {
    try {
      setIsLoading(true);

      const serverResponse = await TrainerManagementService.getTotalAmout(trainer.id, {
        statisticType: activeFilter,
      });

      setTotalAmout(serverResponse.data?.statistic);
      setCurrency(serverResponse.data?.currency);

      const { data } = await TrainerManagementService.getEarningStatistic(trainer.id, {
        statisticType: activeFilter,
      });

      if (!data.length) {
        throw new Error();
      }

      const tuteeData = [];
      const platformFee = [];
      const dateAndTime = [];

      data.forEach(({ trainerEarnings, platformReceived, date }) => {
        tuteeData.push(trainerEarnings);
        platformFee.push(platformReceived);
        dateAndTime.push(date);
      });

      const series = [
        {
          name: 'Students paid',
          data: tuteeData,
        },
        {
          name: 'Platform received',
          data: platformFee,
        },
      ];

      setGraphicOptions(earningStatisticOptions({ currency: currency?.symbol || POUND }));

      setGraphicOptions(prevState => ({
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          categories: dateAndTime,
        },
      }));

      setGraphicData(series);
    } catch {
      setGraphicData([]);
    } finally {
      setIsLoading(false);
    }
  }, [trainer.id, activeFilter]);

  useEffect(() => {
    getEarningStatistic();
  }, [getEarningStatistic]);

  return (
    <Card className="earning-card">
      <section className="d-flex flex-row align-items-center justify-content-between">
        <section className="d-flex flex-column">
          <span className="earning-card__total d-flex flex-row align-items-center">
            <i className="bx bx-money text-primary me-1" />
            Amount of money earned: {`${currency?.symbol || POUND}${totalAmout}`}
          </span>
        </section>
        <div className="btn-group mb-3" role="group">
          {FILTER_BUTTONS.map((btn, index) => (
            <button
              type="button"
              key={index}
              className={getActiveBtnClass(activeFilter, btn.filter)}
              onClick={() => setActiveFilter(btn.filter)}
            >
              {btn.label}
            </button>
          ))}
        </div>
      </section>
      {graphicData.length ? (
        isLoading ? (
          <section className="earning-card__spinner">
            <Spinner color="info" />
          </section>
        ) : (
          <ReactApexChart options={graphicOptions} series={graphicData} type={APEX_TYPE} height={APEX_HEIGHT} />
        )
      ) : (
        <Placeholder text="NO DATA" />
      )}
    </Card>
  );
}

import React, { useState, useCallback, useEffect } from 'react';
import { Container, Card } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import Notification from './notificationItem';
import NotificationsService from '../../services/notifications';
import { useLocation, useHistory } from 'react-router-dom';
import { NOTIFICATIONS_LIMIT } from '../../constants/paginationConstants';
import { setActionToNotification } from '../../constants/notificationConstants';
import {
  useUpdateNotificationsContext,
  useNotificationsContext,
} from '../../components/CommonForBoth/TopbarDropdown/notificationsContext';
import InfiniteScroll from 'react-infinite-scroll-component';

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [pagination, setPagination] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);

  const history = useHistory();
  const location = useLocation();

  const unreadCounter = useNotificationsContext();
  const updateNotificationsCounter = useUpdateNotificationsContext();

  const getNotifications = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data, pagination } = await NotificationsService.getNotifications({
        Limit: NOTIFICATIONS_LIMIT,
        Offset: offset,
      });
      setNotifications(prevState => [...prevState, ...data.map(item => setActionToNotification(item, history))]);
      setPagination(pagination);
    } finally {
      setIsLoading(false);
    }
  }, [offset]);

  function handleLoadMore() {
    setOffset(prevState => prevState + NOTIFICATIONS_LIMIT);
  }

  const clearNotificationsCounter = useCallback(async () => {
    if (!unreadCounter) {
      return;
    }
    try {
      await NotificationsService.markAllAsRead();
      updateNotificationsCounter(0);
    } finally {
      //empty
    }
  }, [location]);

  useEffect(() => {
    getNotifications();
  }, [getNotifications]);

  useEffect(() => {
    clearNotificationsCounter();
  }, [clearNotificationsCounter]);

  return (
    <>
      <MetaTags>
        <title>Notifications | SweatSearch</title>
      </MetaTags>
      <div className="page-content">
        <Container fluid>
          <Card className="notifications--header mb-3">
            <section className="row align-items-center">
              <span className="col-1 notifications--header__hash"></span>
              <span className="col-9 notifications--header__title">Notification</span>
              <span className="col-2 notifications--header__title">Timestamp</span>
            </section>
          </Card>
          <InfiniteScroll
            dataLength={notifications.length}
            next={handleLoadMore}
            hasMore={pagination.totalCount > notifications.length}
            className="notifications__scroll"
          >
            {notifications.map((item, index) => (
              <Notification
                title={item.title}
                text={item.body}
                date={item.timestamp}
                key={index}
                type={item.type}
                action={item.action}
              />
            ))}
          </InfiniteScroll>
          {pagination.totalCount > notifications.length && (
            <section className="d-flex flex-row justify-content-center w-100 my-5">
              <button
                type="button"
                className="btn btn-outline-success border-0  waves-effect waves-light"
                disabled={true}
              >
                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2" />
                Load more
              </button>
            </section>
          )}
        </Container>
      </div>
    </>
  );
};

export default Notifications;

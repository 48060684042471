import React, { useState, useEffect, useMemo, useCallback } from "react";
import Table from "../../../../components/Table";
import SessionInfoStructure from "../components/sessionsInfoComponents/sessionsInfoTableStructure";
import TrainerManagementService from "../../../../services/trainerManagement";
import { useHistory, useLocation } from "react-router-dom";
import qs from "querystring";
import {
    REPORTS_LIMIT,
    ORDER_TYPE_KEY,
    ORDER_FIELD_KEY,
    REVIEW_SORT_TYPES,
} from "../../../../constants/paginationConstants";
import queryParser from "../../../../helpers/queryParser";
import { SORT_ACTION_TYPE } from "../../../../constants/tableConstants";
import getFirstLetter from "../../../../helpers/getFirstLetter";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import getNameValue from "../../../../helpers/getNameValue";
import { REQUEST, OFFER } from '../../../../constants/commonConstants';

const sessionTypes = [
    {typeName: REQUEST},
    {typeName: OFFER}
]

export default function SessionInfo({ trainer }) {
    const [isDataLoading, setIsDataLoading] = useState(false);
    const [sessions, setSessions] = useState([]);
    const [sessionsPagination, setSessionsPagination] = useState({});

    const history = useHistory();

    const location = useLocation();

    const query = useMemo(() => queryParser(location.search), [location]);

    const [activeTab, setActiveTab] = useState(query.ReportsViewType || REQUEST);

    const getSessions = useCallback(async () => {
        try {
            setIsDataLoading(true);
            const newQuery = {
                ...query,
                Limit: REPORTS_LIMIT,
                TrainingType: activeTab,
            };
            const { data, pagination } = await TrainerManagementService.getTrainerTrainings(trainer?.id, newQuery);
            setSessions(data);

            setSessionsPagination(pagination);
        } finally {
            setIsDataLoading(false);
        }
    }, [query, trainer]);

    const handleTableChange = async (type, { page, sortField, sortOrder }) => {
        const params = {
            ...query,
            offset: REPORTS_LIMIT * (page - 1),
        };
        if (type === SORT_ACTION_TYPE) {
            params[ORDER_TYPE_KEY] = sortOrder;
            params[ORDER_FIELD_KEY] = REVIEW_SORT_TYPES[sortField];
        }
        history.push({
            search: qs.stringify(params),
        });
    };

    function handleChangeType(type) {
        setActiveTab(type);
        history.push({
            search: qs.stringify({
                TrainingType: type,
            }),
        });
    }

    function TableHeader() {
        return (
            <section className="session-info-header d-flex flex-row justify-content-between">
                <div className="d-flex flex-row align-items-center tutee-details">
                    {trainer.avatar?.compactPath ? (
                        <div className="avatar-sm">
                            <img src={trainer.avatar?.compactPath} alt="" className="img-thumbnail rounded-circle" />
                        </div>
                    ) : (
                        <div className="avatar-sm">
                            <span className="avatar-title rounded-circle font-size-20">{getFirstLetter(trainer.firstName)}</span>
                        </div>
                    )}
                    <span className="font-size-15 trainer-name">
                        {getNameValue(trainer.firstName, trainer.lastName)}
                    </span>
                </div>
                <div className="d-flex flex-row">
                    <Nav tabs className="nav-tabs-custom nav-justified trainer-reports-tab--tabs no-border-bottom">
                        {
                            sessionTypes.map(({ typeName }) => (
                                <NavItem className="trainer-reports-tab--tabs__tab">
                                    <NavLink className={classnames({
                                        active: activeTab === typeName,
                                    })}
                                        onClick={() => {
                                            handleChangeType(typeName);
                                        }}
                                    >
                                        <span className="d-none d-sm-block trainer-reports-tab--tabs__tab__span">{typeName}</span>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </Nav>
                </div>
                <div className="d-flex align-items-center conducted-sessions">
                    <span>Conducted sessions: {sessionsPagination?.totalCount || 0}</span>
                </div>
            </section>
        );
    }

    useEffect(() => {
        getSessions();
    }, [getSessions]);

    return (
        <Table
            dataStructure={SessionInfoStructure}
            data={sessions}
            FiltersComponent={TableHeader}
            handleTableChange={handleTableChange}
            limit={REPORTS_LIMIT}
            totalCount={sessionsPagination.totalCount}
            offset={query.offset || 0}
            loading={isDataLoading}
            rowClickPath={activeTab === 'Request' ? "-session" : "-timeslot"}
        />
    );
}

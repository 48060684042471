import React, { useState, useMemo } from 'react';
import { FILTER_BY_ALL, FILTER_BY_ACTIVE, FILTER_BY_BLOCKED } from '../../../constants/paginationConstants';
import { Dropdown, DropdownMenu, DropdownToggle, Button } from 'reactstrap';
import { useLocation, useHistory } from 'react-router-dom';
import queryParser from '../../../helpers/queryParser';
import qs from 'query-string';
import SearchBox from '../../../components/Common/Search';
import Checkbox from '../../../components/Common/checkbox';

const MAX_FILTERS = [FILTER_BY_ALL, FILTER_BY_BLOCKED, FILTER_BY_ACTIVE];
const MAX_FILTERS_COUNT = 2;
const FILTER_QUERY = 'Filter';

export default function TuteeFilters({ toolkitProps }) {
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterOption, setFilterOption] = useState(query[FILTER_QUERY] ? [query[FILTER_QUERY]] : []);

  function handleChangeFilters(filter) {
    setFilterOption(prevState => {
      if (prevState.includes(filter)) {
        return prevState.filter(option => option !== filter && option !== FILTER_BY_ALL);
      }
      const newFilters = [...prevState, filter];

      if (newFilters.length === MAX_FILTERS_COUNT) {
        return MAX_FILTERS;
      }
      return [filter];
    });
  }

  function handleChangeAll() {
    setFilterOption(prevState => {
      if (prevState.includes(FILTER_BY_ALL)) {
        return [];
      }
      return MAX_FILTERS;
    });
  }

  function handleSubmitFilters() {
    setIsFilterOpen(prevState => !prevState);
    const appliedfilter = filterOption.length >= MAX_FILTERS_COUNT ? FILTER_BY_ALL : filterOption[0];
    history.push({
      search: qs.stringify({
        Filter: appliedfilter,
      }),
    });
  }

  return (
    <section className="d-flex flex-row justify-content-between">
      <span className="tutee-list__header">The list of Tutees</span>
      <section className="d-flex flex-row">
        <SearchBox toolkitProps={toolkitProps} />
        <Dropdown isOpen={isFilterOpen} toggle={() => setIsFilterOpen(prevState => !prevState)}>
          <DropdownToggle tag="button" className="btn tutee-list--dropdown__toggle">
            Filter by <i className="mdi mdi-chevron-down" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-right tutee-list--dropdown">
            <span className="tutee-list--dropdown__title ms-2 mb-2">Status</span>

            <Checkbox
              value={'All'}
              onChange={handleChangeAll}
              checked={filterOption.includes(FILTER_BY_ALL)}
              label={'All'}
            />

            <Checkbox
              value={'active'}
              onChange={() => {
                handleChangeFilters(FILTER_BY_ACTIVE);
              }}
              checked={filterOption.includes(FILTER_BY_ACTIVE)}
              label={'Active'}
            />

            <Checkbox
              value={'blocked'}
              onChange={() => {
                handleChangeFilters(FILTER_BY_BLOCKED);
              }}
              checked={filterOption.includes(FILTER_BY_BLOCKED)}
              label={'Blocked'}
            />

            <Button color="success" className="w-100 mt-4" onClick={handleSubmitFilters}>
              Apply
            </Button>
          </DropdownMenu>
        </Dropdown>
      </section>
    </section>
  );
}

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import NotificationsService from '../../../services/notifications';
import useSocket from '../../../helpers/hooks/useSocket';
import {
  MAX_NOTIFICATIONS,
  MAX_NOTIFICATION_DROPDOWN,
  ICONS_GENERATOR,
  UPDATED_COUNTER,
  setActionToNotification,
} from '../../../constants/notificationConstants';
import { withTranslation } from 'react-i18next';
import getNotificationDate from '../../../helpers/getDateForNotification';
import NotificationItem from './notificationItem';
import { useNotificationsContext, useUpdateNotificationsContext } from './notificationsContext';
import StorageService from '../../../services/StorageService';
import { SESSION } from '../../../constants/storageConstants';

const NotificationDropdown = props => {
  const [menu, setMenu] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const token = StorageService.getValueByKey(SESSION)?.accessToken;

  if (!token) {
    return <Redirect to="/logout" />;
  }

  const history = useHistory();

  const notificationsCounter = useNotificationsContext();
  const updateNotificationsCounter = useUpdateNotificationsContext();

  const handleNewNotification = useCallback(message => {
    if (message.eventType === UPDATED_COUNTER) {
      updateNotificationsCounter(message.data.count);
      return;
    }
    setNotifications(prevState => {
      prevState.unshift(setActionToNotification(message.data, history));
      prevState.pop();
      return [...prevState];
    });
  }, []);

  useSocket(handleNewNotification);

  const getUnredCounter = useCallback(async () => {
    try {
      const {
        data: { count },
      } = await NotificationsService.getUnreadCount();
      const { data } = await NotificationsService.getNotifications({
        Limit: MAX_NOTIFICATION_DROPDOWN,
        Offset: 0,
      });
      setNotifications(data.map(item => setActionToNotification(item, history)));
      updateNotificationsCounter(count);
    } finally {
      //empty
    }
  }, [history]);

  async function marsAllAsRead() {
    history.push('/notifications');
  }

  useEffect(() => {
    getUnredCounter();
  }, [getUnredCounter]);

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="dropdown d-inline-block" tag="li">
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell mt-1" />
          {!!notificationsCounter && (
            <span
              className={'badge bg-danger rounded-pill ' + (notificationsCounter > MAX_NOTIFICATIONS ? 'ps-0' : '')}
            >
              {notificationsCounter > MAX_NOTIFICATIONS ? '+10' : notificationsCounter}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-right">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t('Notifications')} </h6>
              </Col>
              <div
                className="col-auto"
                onClick={() => {
                  setMenu(!menu);
                }}
              >
                <button className="small btn-link btn c-pointer" onClick={marsAllAsRead}>
                  View All
                </button>
              </div>
            </Row>
          </div>

          <SimpleBar className="notification-list-dropdown__bar">
            {notifications.map(item => (
              <NotificationItem
                key={item.id}
                action={() => {
                  setMenu(!menu);
                  item.action();
                }}
                icon={ICONS_GENERATOR[item.type]()}
                title={item.title}
                body={item.body}
                date={getNotificationDate(item.timestamp)}
              />
            ))}
          </SimpleBar>

          <div
            className="p-2 border-top d-grid"
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <button className="btn btn-sm btn-link font-size-14 btn-block text-center" onClick={marsAllAsRead}>
              {props.t('View all')}
            </button>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};

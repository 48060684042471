import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import StorageService from "../../services/StorageService";
import { ADMIN } from "../../constants/roles";
import { USER, SESSION } from "../../constants/storageConstants";

const Authmiddleware = ({ component: Component, isAuthProtected, ...rest }) => {
  const isTokenActual = StorageService.getValueByKey(SESSION);
  const isUserAdmin = StorageService.getValueByKey(USER)?.role === ADMIN;

  return (
    <Route
      {...rest}
      render={props => {
        if (!isTokenActual && !isUserAdmin && isAuthProtected) {
          return <Redirect to="/logout" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;

import React, { useState, useEffect, useMemo, useCallback } from "react";
import Table from "../../../../components/Table";
import BothTableStructure from "../components/reportComponents/bothTableStructure";
import TrainerManagementService from "../../../../services/trainerManagement";
import { useHistory, useLocation } from "react-router-dom";
import qs from "querystring";
import {
  REPORTS_LIMIT,
  ORDER_TYPE_KEY,
  ORDER_FIELD_KEY,
  REPORT_SORT_TYPES,
  ORDER_DESC_v2,
  ORDER_ASC_v2,
} from "../../../../constants/paginationConstants";
import queryParser from "../../../../helpers/queryParser";
import { SORT_ACTION_TYPE } from "../../../../constants/tableConstants";
import FilterComponent from "../components/reportComponents/filters";

export default function Reports({ trainer }) {
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [reportsPagination, setReportsPagination] = useState({});

  const history = useHistory();

  const location = useLocation();

  const query = useMemo(() => queryParser(location.search), [location]);

  const getReports = useCallback(async () => {
    try {
      setIsDataLoading(true);
      const newQuery = {
        ...query,
        Limit: REPORTS_LIMIT,
        ReportsViewType: query.ReportsViewType || "Trainer",
      };
      const { data, pagination } = await TrainerManagementService.getTrainerReports(trainer.id, newQuery);
      setReports(data);
      setReportsPagination(pagination);
    } finally {
      setIsDataLoading(false);
    }
  }, [query]);

  const handleTableChange = async (type, { page, sortField, sortOrder }) => {
    const params = {
      ...query,
      offset: REPORTS_LIMIT * (page - 1),
    };
    if (type === SORT_ACTION_TYPE) {
      params[ORDER_TYPE_KEY] = query[ORDER_TYPE_KEY] === ORDER_ASC_v2 ? ORDER_DESC_v2 : ORDER_ASC_v2;
      params[ORDER_FIELD_KEY] = REPORT_SORT_TYPES[sortField];
    }
    history.push({
      search: qs.stringify(params),
    });
  };

  useEffect(() => {
    getReports();
  }, [getReports]);

  return (
    <Table
      dataStructure={BothTableStructure}
      data={reports}
      FiltersComponent={FilterComponent}
      handleTableChange={handleTableChange}
      limit={REPORTS_LIMIT}
      totalCount={reportsPagination.totalCount}
      offset={query.offset || 0}
      loading={isDataLoading}
      rowClickPath={"/reports-management"}
      withLocation={false}
    />
  );
}

import React from 'react';
import getFirstLetter from '../../../../helpers/getFirstLetter';
import getNameValue from '../../../../helpers/getNameValue';
import formatDate from '../../../../helpers/formatDate';
import { FULL_DAY_FORMAT, GB_TIME_FORMAT } from '../../../../constants/dateFormats';
import useGoToUsersDetails from '../../../../helpers/hooks/useGoToUsersDetails';
import { TRAINER, TUTEE } from '../../../../constants/roles';
import { POUND } from '../../../../constants/defaultConstants';

export default function UsersCard({ session }) {
  const goToTrainerDetails = useGoToUsersDetails({ role: TRAINER, id: session.trainer.id });
  const goToTuteeDetails = useGoToUsersDetails({ role: TUTEE, id: session.tutee.id });

  return (
    <section className="d-flex flex-row justify-content-between">
      <section className="report-card--report-involved--reporter d-flex flex-row me-5">
        {session.tutee.avatar?.compactPath ? (
          <img
            alt="avatar"
            className="report-card--report-involved--reporter__avatar"
            src={session.tutee.avatar.compactPath}
            onClick={goToTuteeDetails}
          />
        ) : (
          <span
            className="avatar-title rounded-circle report-card--report-involved--reporter__avatar"
            onClick={goToTuteeDetails}
          >
            {getFirstLetter(session.tutee.firstName)}
          </span>
        )}
        <section className="d-flex flex-column">
          <section className="d-flex flex-row justify-content-between align-items-center ">
            <span className="report-card--report-involved--reporter__name" onClick={goToTuteeDetails}>
              {getNameValue(session.tutee.firstName, session.tutee.lastName)}
            </span>
            <section className="report-card--report-involved--reporter__badge">{session.sessionLabel || session.sessionType}</section>
          </section>

          <section className="mt-2">
            <span className="report-card--report-involved--reporter__type">
              {session.sessionSpecializationType?.type || session.specialisation?.type}
            </span>
          </section>

          <section className="mt-3">
            <span className="report-card--report-involved--reporter__price">
              <i className="bx bx-money report-card--report-involved--reporter__price__icon" />
              {`${session?.currencyModel?.symbol || POUND}${session.price}`}
            </span>
            <span className="report-card--report-involved--reporter__price">
              <i className="bx bx-calendar report-card--report-involved--reporter__price__icon" />
              {formatDate(session.sessionTime.startDate, FULL_DAY_FORMAT)}
            </span>
            <span className="report-card--report-involved--reporter__price">
              <i className="bx bx-time report-card--report-involved--reporter__price__icon" />
              {formatDate(session.sessionTime.startDate, GB_TIME_FORMAT)}
            </span>
          </section>
        </section>
      </section>
      <section className="report-card--report-involved--reported d-flex flex-row">
        {session.trainer.avatar?.compactPath ? (
          <img
            alt="avatar"
            className="report-card--report-involved--reported__avatar"
            src={session.trainer.avatar?.compactPath}
            onClick={goToTrainerDetails}
          />
        ) : (
          <span
            className="avatar-title rounded-circle report-card--report-involved--reported__avatar"
            onClick={goToTrainerDetails}
          >
            {getFirstLetter(session.trainer.firstName)}
          </span>
        )}

        <section className="d-flex flex-column">
          <section className="d-flex flex-row align-items-center mt-1 justify-content-between">
            <span className="report-card--report-involved--reporter__name me-0" onClick={goToTrainerDetails}>
              {getNameValue(session.trainer.firstName, session.trainer.lastName)}
            </span>
          </section>

          <span className="report-card--report-involved--reporter__role">Trainer</span>
        </section>
      </section>
    </section>
  );
}
